import { useEffect, useState } from "react";
import Layout from "../../../assets/components/layout";
import ModalManagementProyek from "./modal";
import { iManagementProyekProps, useFetchManagementProyek } from "./interface";
import { useNavigate } from "react-router-dom";
import { toThousand } from "../../../const";
import NoData from "../../../assets/components/noData";

const ManagementProyek = () => {
  const { data } = useFetchManagementProyek();
  const navigation = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [dataProyek, setDataProyek] = useState<iManagementProyekProps[]>([]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  useEffect(() => {
    if (data) {
      const result = data.data;
      setDataProyek(result);
    }
  }, [data]);

  const handleDetails = (uuid: string) => {
    navigation("/proyek-management-details/" + uuid);
  };

  return (
    <Layout>
      <div className="card card-style">
        <div className="content">
          <div className="d-flex">
            <div>
              <h6 className="font-700 mb-n1 color-highlight">Management</h6>
              <h2 className="pb-2">Data Proyek</h2>
            </div>
            <div className="align-self-center ms-auto">
              <button
                className="btn btn-xs gradient-blue shadow-bg shadow-bg-xs"
                onClick={() => {
                  handleOpen();
                  //   setIsDataEdit(null),
                }}
              >
                <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
              </button>
            </div>
          </div>
          <div
            className="list-group list-custom list-group-m rounded-m mt-3"
            style={{ overflow: "auto", maxHeight: "calc(100vh - 16.3em)" }}
          >
            {dataProyek.length < 1 ? (
              <NoData />
            ) : (
              dataProyek.map((proyek, proyekKey) => {
                return (
                  <div
                    className="list-group-item"
                    key={proyekKey}
                    onClick={() => handleDetails(proyek.uuid)}
                  >
                    <div className="ps-1">
                      <strong className="font-600 mb-2">{proyek.name}</strong>
                      <span className="font-500 font-12 mb-2">
                        Nilai Proyek : Rp.
                        {toThousand(proyek.nilai)}
                      </span>
                      <span className="font-500 font-12">
                        Penanggung Jawab :{" "}
                        {proyek.penanggun_jawab
                          .map((penanggung) => penanggung.detail.name)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {isEnableModal && (
        <ModalManagementProyek
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          // isDataEdit={isDataEdit}
        />
      )}
    </Layout>
  );
};

export default ManagementProyek;
