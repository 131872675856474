/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../const";
import { toast } from "../../assets/components/toast";
import { QueryClient, useQuery } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { Dispatch, SetStateAction } from "react";

export interface iInitialValuesSubmission {
  status: string;
  start_date: string;
  end_date: string;
  note: string;
}

export const initialValuesSubmission: iInitialValuesSubmission = {
  status: "",
  start_date: "",
  end_date: "",
  note: "",
};

export const submissionSchema = Yup.object().shape({
  status: Yup.string().required("Please insert a value."),
  start_date: Yup.string().required("Please insert a value."),
  end_date: Yup.string().required("Please insert a value."),
  note: Yup.string().required("Please insert a value."),
});

export const submitStoreSubmission = async (
  values: iInitialValuesSubmission,
  selectedFile: File | null,
  handleCloseModal: () => void
) => {
  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("note", values.note);
  dataToSend.append("start_date", values.start_date);
  dataToSend.append("end_date", values.end_date);
  dataToSend.append("status", values.status);

  API_CLIENT.post("/submission", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
      }
    })
    .catch((err) => console.log(err));
};

const fetchSubmission = async () => {
  const response = await API_CLIENT.get<any>("/submission");
  return response.data;
};

export const useFetchSubmission = () => {
  return useQuery({
    queryKey: ["submission"],
    queryFn: fetchSubmission,
  });
};

const fetchSubmissionAdmin = async (filter: string, range?: any) => {
  const response = await API_CLIENT.get<any>(`/submission/admin?filter=${filter}&range=${range}`);
  return response.data;
};

export const useFetchSubmissionAdmin = (filter: string, range?: any) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["submission"],
    queryFn: () => fetchSubmissionAdmin(filter, dateRange),
  });
};

export const submitUpdateSubmission = async (
  uuid: string,
  values: iInitialValuesSubmission,
  selectedFile: File | null,
  handleCloseModal: () => void
) => {
  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("note", values.note);
  dataToSend.append("start_date", values.start_date);
  dataToSend.append("end_date", values.end_date);
  dataToSend.append("status", values.status);

  API_CLIENT.post(`/submission/${uuid}`, dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
      }
    })
    .catch((err) => console.log(err));
};

export const handleDeleteSubmission = (
  uuid: string,
  handleCloseModal: Dispatch<SetStateAction<boolean>>,
  queryClient: QueryClient,
) => {
  Swal.fire({
    title: "Anda Yakin?",
    text: "Anda tidak dapat mengembalikan data yang sudah di hapus!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "Menghapus data",
        html: "Silahkan tunggu...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      API_CLIENT.delete(`/submission/${uuid}`)
        .then((response) => {
          const result = response.data;
          if (result.error) {
            Swal.fire("Error", result.message, "error");
          } else {
            handleCloseModal(false);
            Swal.fire("Success", result.message, "success");
            queryClient.invalidateQueries({ queryKey: ["submission"] });
          }
        })
        .catch((err) => console.log(err));
    }
  });
};
