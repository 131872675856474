import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { submitPresentSubmission } from "./interface";
import { useQueryClient } from "@tanstack/react-query";

interface SubmissionModalProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  location: number[];
}

const SubmissionModal: FC<SubmissionModalProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  location,
  ...props
}) => {
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      setSelectedFile(null);
      queryClient.invalidateQueries({ queryKey: ["daily-present"] });
    }, 150);
  };

  return (
    <Offcanvas
      show={isOpen}
      style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
      onHide={() => handleCloseModal()}
      {...props}
      placement="bottom"
    >
      <Offcanvas.Body>
        <h6 className="font-700 mb-n1 color-highlight">Pengajuan</h6>
        <h4 className="pb-2">Form pengajuan</h4>
        <Formik
          initialValues={{ keterangan: "" }}
          onSubmit={(values: { keterangan: string }) => {
            submitPresentSubmission(
              values.keterangan,
              location[0],
              location[1],
              selectedFile,
              handleCloseModal
            );
          }}
        >
          {() => (
            <Form>
              <div className="form-field form-text mt-3">
                <label
                  htmlFor="c1"
                  className="contactMessageTextarea color-theme"
                >
                  Keterangan:
                  <span>(required)</span>
                </label>
                <Field
                  type="text"
                  name="keterangan"
                  id="c1"
                  as="textarea"
                  row={2}
                  className="round-small mb-0"
                  placeholder="Keterangan"
                  required
                />
              </div>
              <p className="mb-0 font-12">
                Silahkan masukan keterangan kamu kenapa pulang lebih awal.
              </p>

              <div className="form-field form-name mt-3">
                <label
                  htmlFor="c2"
                  className="contactMessageTextarea color-theme"
                >
                  FILE
                  <span>(optional)</span>
                </label>
                <input
                  type="file"
                  name="file"
                  id="c2"
                  className="form-control mb-0"
                  onChange={handleFileChange}
                />
              </div>
              <p className="mb-0 font-12">
                Silahkan gambar atau file untuk bukti lainya.
              </p>

              <div className="d-flex" style={{ gap: 10 }}>
                <button
                  className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "30%" }}
                  type="button"
                  onClick={handleCloseModal}
                >
                  Batal
                </button>
                <button
                  className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "70%" }}
                  type="submit"
                >
                  Simpan Data
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SubmissionModal;
