import { Field, Form, Formik } from "formik";
import {
  ChangeEvent,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import {
  cashFlowOutSchema,
  iInitialValuesOut,
  initialValuesOut,
  satuanQtyBangunan,
  submitStoreCashFlowOut,
  typePembayaran,
} from "./interface";
import { formatRibuan, toThousand } from "../../../../../../const";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

interface modalCashFlowOutProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

const ModalCashFlowOut: FC<modalCashFlowOutProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  ...props
}) => {
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  const [totalPengeluaran, setTotalPengeluaran] = useState<string>("0");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["cashflow-out"] });
    }, 150);
  };

  const handleTotalPengeluaran = (values: iInitialValuesOut) => {
    const { harga, ppn, qty } = values;

    const totalAll =
      (harga ? parseInt(harga.replaceAll(".", ""), 10) : 0) * (qty ? qty : 0) +
      (ppn ? parseInt(ppn.replaceAll(".", ""), 10) : 0);

    return setTotalPengeluaran(totalAll.toString());
  };

  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "90dvh",
        }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Pengeluaran</h4>
          <Formik
            initialValues={initialValuesOut}
            onSubmit={(values: iInitialValuesOut) => {
              submitStoreCashFlowOut(
                values,
                selectedFile,
                uuid,
                handleCloseModal
              );
            }}
            validationSchema={cashFlowOutSchema}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="name"
                    id="c1"
                    className="form-control rounded-xs"
                    placeholder="Nama Barang"
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Barang
                  </label>
                  <span>(required)</span>
                  {errors.name && touched.name && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.name}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-7">
                    <div className="form-custom mb-3 form-floating">
                      <Field
                        type="number"
                        name="qty"
                        id="c2"
                        className="form-control rounded-xs"
                        placeholder="Jumlah Barang"
                        onKeyUp={() => handleTotalPengeluaran(values)}
                      />
                      <label htmlFor="c2" className="color-theme">
                        Jumlah Barang
                      </label>
                      <span>(required)</span>
                      {errors.qty && touched.qty && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.qty}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="form-custom mb-3 form-floating">
                      <Field
                        as="select"
                        name="satuan"
                        id="c4"
                        className="form-select rounded-xs"
                        aria-label="Floating label select example"
                      >
                        <option value="" disabled>
                          Silahkan Pilih
                        </option>
                        {satuanQtyBangunan.map((satuan, satuanKey) => {
                          return (
                            <option key={satuanKey} value={satuan.value}>
                              {satuan.label}
                            </option>
                          );
                        })}
                      </Field>
                      <label htmlFor="c4" className="color-theme">
                        Satuan
                      </label>
                      <span>(required)</span>
                      {errors.satuan && touched.satuan && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.satuan}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="text"
                    name="supplier"
                    id="c5"
                    className="form-control rounded-xs"
                    placeholder="Supplier"
                  />
                  <label htmlFor="c5" className="color-theme">
                    Suppier
                  </label>
                  {errors.supplier && touched.supplier && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.supplier}
                    </div>
                  )}
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="text"
                    inputMode="numeric"
                    name="harga"
                    id="c5"
                    className="form-control rounded-xs"
                    placeholder="Harga Satuan"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formatRibuan(event),
                        setFieldValue("harga", event.target.value);
                    }}
                    onKeyUp={() => handleTotalPengeluaran(values)}
                  />
                  <label htmlFor="c5" className="color-theme">
                    Harga Satuan
                  </label>
                  {errors.harga && touched.harga && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.harga}
                    </div>
                  )}
                </div>
                <div className="form-custom form-floating">
                  <Field
                    type="text"
                    name="ppn"
                    id="c6"
                    className="form-control rounded-xs"
                    placeholder="ppn"
                    inputMode="numeric"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formatRibuan(event),
                        setFieldValue("ppn", event.target.value);
                    }}
                    onKeyUp={() => handleTotalPengeluaran(values)}
                  />
                  <label htmlFor="c6" className="color-theme">
                    PPN
                  </label>
                </div>
                <div className="alert font-13 bg-fade2-red alert-dismissible color-red-dark rounded-s fade show pe-2 mb-3 mt-2">
                  <strong>Catatan:</strong>
                  <br />
                  <ul className="mb-0 p-0 font-12">
                    <li>
                      Jika ada <strong>PPN</strong> silahkan masukan, Jika tidak
                      ada maka biarkan kosong saja
                    </li>
                  </ul>
                </div>
                <div className="form-custom form-floating mb-3">
                  <input
                    type="text"
                    id="c10"
                    className="form-control rounded-xs font-800 font-26"
                    placeholder="Total Pengeluaran"
                    value={values.harga ? toThousand(totalPengeluaran) : 0}
                    readOnly
                  />
                  <label htmlFor="c10" className="color-theme">
                    Total Pengeluaran
                  </label>
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    as="select"
                    name="tipe_pembayaran"
                    id="c7"
                    className="form-select rounded-xs"
                    aria-label="Floating label select example"
                  >
                    <option value="" disabled>
                      Silahkan Pilih
                    </option>
                    {typePembayaran.map((payment, paymentKey) => {
                      return (
                        <option key={paymentKey} value={payment.value}>
                          {payment.label}
                        </option>
                      );
                    })}
                  </Field>
                  <label htmlFor="c7" className="color-theme">
                    Tipe Pembayaran
                  </label>
                  <span>(required)</span>
                  {errors.tipe_pembayaran && touched.tipe_pembayaran && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.tipe_pembayaran}
                    </div>
                  )}
                </div>

                {values.tipe_pembayaran !== "cash" &&
                  values.tipe_pembayaran !== "" && (
                    <div className="form-custom form-floating mb-3">
                      <Field
                        type="text"
                        name="informasi"
                        id="c9"
                        className="form-control rounded-xs"
                        placeholder="Informasi"
                      />
                      <label htmlFor="c9" className="color-theme">
                        Informasi
                      </label>
                      {errors.informasi && touched.informasi ? (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.informasi}
                        </div>
                      ) : (
                        <p className="mb-3 font-11">
                          harap masukkan informasi yang diperlukan seperti{" "}
                          <strong className="text-danger">
                            nomor rekening, kartu kredit, atau data pembayaran
                          </strong>{" "}
                          yang sesuai.
                        </p>
                      )}
                    </div>
                  )}

                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="text"
                    name="penerima"
                    id="c8"
                    className="form-control rounded-xs"
                    placeholder="Penerima"
                  />
                  <label htmlFor="c8" className="color-theme">
                    Penerima
                  </label>
                  {errors.penerima && touched.penerima && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.penerima}
                    </div>
                  )}
                </div>

                <div className="form-field form-name mt-3">
                  <label
                    htmlFor="f2"
                    className="contactMessageTextarea color-theme"
                  >
                    FILE
                    <span>(optional)</span>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="f2"
                    className="form-control mb-0"
                    onChange={handleFileChange}
                  />
                </div>
                <p className="mb-0 font-12">
                  Silahkan masukan gambar atau file untuk bukti lainya.
                </p>
                <div className="d-flex" style={{ gap: 10 }}>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "70%" }}
                    type="submit"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalCashFlowOut;
