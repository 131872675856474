import { Field, Form, Formik } from "formik";
import {
  ChangeEvent,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
} from "react";
import { Offcanvas } from "react-bootstrap";
import FormSelect from "../../../../../../assets/components/form/formSelect";
import { formatRibuan, toThousand } from "../../../../../../const";
import {
  KaryawanProyekSchema,
  iInitialValuesKaryawanProyek,
  iKaryawanProyekProps,
  initialValuesKaryawanProyek,
  submitStoreKaryawanProyek,
} from "./interface";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface modalManagementKaryawanProyekProps
  extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  isDataEdit?: iKaryawanProyekProps | null;
}

const ModalManagementKaryawanProyek: FC<modalManagementKaryawanProyekProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  isDataEdit,
  ...props
}) => {
  const { uuid: idProyek } = useParams();
  const queryClient = useQueryClient();

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["proyek-karyawan"] });
    }, 150);
  };
  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "90dvh",
        }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Karyawan</h4>
          <Formik
            initialValues={
              (isDataEdit as iInitialValuesKaryawanProyek) ||
              initialValuesKaryawanProyek
            }
            onSubmit={(values) => {
              submitStoreKaryawanProyek(
                values,
                idProyek,
                handleCloseModal,
                isDataEdit || null
              );
            }}
            validationSchema={KaryawanProyekSchema}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="name"
                    id="c1"
                    className="form-control rounded-xs"
                    placeholder="Nama Lengkap"
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Lengkap
                  </label>
                  {errors.name && touched.name && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.name}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="no_hp"
                    id="c2"
                    inputMode="numeric"
                    className="form-control rounded-xs"
                    placeholder="No. Hp"
                  />
                  <label htmlFor="c2" className="color-theme">
                    No. Hp
                  </label>
                  {errors.no_hp && touched.no_hp && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.no_hp}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div className="form-field form-name mb-3 mt-3">
                  <label className="contactNameField color-theme">
                    Jabatan
                    <span>(required)</span>
                  </label>
                  <FormSelect
                    urlSelect="proyek/karyawan/jabatan"
                    name="jabatan_id"
                    error={errors}
                    touched={touched}
                    className="round-small z-9"
                  />
                </div>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="gapok"
                    id="c2"
                    inputMode="numeric"
                    className="form-control rounded-xs"
                    placeholder="Gaji Pokok"
                    value={toThousand(values.gapok.toString())}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formatRibuan(event),
                        setFieldValue("gapok", event.target.value);
                    }}
                  />
                  <label htmlFor="c2" className="color-theme">
                    Gaji Pokok
                  </label>
                  {errors.gapok && touched.gapok && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.gapok}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="uang_makan"
                    id="c2"
                    inputMode="numeric"
                    className="form-control rounded-xs"
                    placeholder="Uang Makan"
                    value={toThousand(values.uang_makan.toString())}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formatRibuan(event),
                        setFieldValue("uang_makan", event.target.value);
                    }}
                  />
                  <label htmlFor="c2" className="color-theme">
                    Uang Makan
                  </label>
                  {errors.uang_makan && touched.uang_makan && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.uang_makan}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div
                  className="d-flex"
                  style={{ gap: 10, zIndex: 1, position: "relative" }}
                >
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "70%" }}
                    type="submit"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalManagementKaryawanProyek;
