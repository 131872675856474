/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_CLIENT } from "../../../../const";
import * as Yup from "yup";

export interface iInitalValuesSubmissionKaryawanProyek {
  status: string;
  note: string;
}

export const initalValuesSubmissionKaryawanProyek: iInitalValuesSubmissionKaryawanProyek =
  {
    status: "",
    note: "",
  };

export const submissionKaryawanProyekSchema = Yup.object().shape({
  status: Yup.string().required("Please insert a value."),
  note: Yup.string().required("Please insert a value."),
});

const calcGlobalManagementData = async (url: string) => {
  const response = await API_CLIENT.get<any>(url);
  return response.data;
};

export const useFetchDataManagementGlobal = (
  queryKey: string,
  urlFn: string
) => {
  return useQuery({
    queryKey: [queryKey],
    queryFn: () => calcGlobalManagementData(urlFn),
  });
};

export const useStoreAbsensiKaryawanProyek = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData) => {
      return API_CLIENT.post("/proyek/management-absensi-daily", newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["management-absensi-daily"] });
    },
  });
};

