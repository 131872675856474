/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../../../const";
import { toast } from "../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export interface iInitialValueIzinPulangKaryawan {
  karyawan: string;
  keterangan: string;
}

export const initialIzinPulangKaryawan: iInitialValueIzinPulangKaryawan = {
  karyawan: "",
  keterangan: "",
};

export const izinPulangKaryawanSchema = Yup.object().shape({
  keterangan: Yup.string().required("Please insert a value."),
});

export const submitPresentSubmissionProyek = async (
  value: iInitialValueIzinPulangKaryawan,
  selectedFile: File | null,
  idProyek: string | undefined,
  handleCloseModal: () => void,
  idSubmission: string | undefined,
) => {
  const dataToSend = new FormData();
  dataToSend.append("keterangan", value.keterangan);
  dataToSend.append("karyawan", value.karyawan);
  dataToSend.append("idProyek", idProyek as string);
  dataToSend.append("file", selectedFile as Blob);
  if(idSubmission){
    dataToSend.append("idSubmission", idSubmission as string);
  }

  API_CLIENT.post("/proyek/submission-present", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      handleCloseModal();
    })
    .catch((err) => console.log(err));
};

const fetchSubmissionKaryawanProyek = async (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/submission-present?filter=${filter}&proyek_id=${proyek_id}&dateRange=${range}`
  );
  return response.data;
};

export const useFetchSubmissionKaryawanProyek = (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["submission-present-proyek"],
    queryFn: () => fetchSubmissionKaryawanProyek(filter, proyek_id, dateRange),
  });
};