/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient, useQuery } from "@tanstack/react-query";
import { API_CLIENT } from "../../../const";
import { toast } from "../toast";

const fetchNotifications = async (user_id: string | undefined) => {
  const response = await API_CLIENT.get<any>(
    `/notifications?user_id=${user_id}`
  );
  return response.data;
};

export const useFetchNotifications = (user_id: string | undefined) => {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: () => fetchNotifications(user_id),
  });
};

export const handleNotifStatus = (
  uuid: string,
  status: string,
  queryClient: QueryClient
) => {
  const dataToSend = {
    uuid: uuid,
    status: status,
  };
  API_CLIENT.post("/notifications", dataToSend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        queryClient.invalidateQueries({ queryKey: ["notifications"] });
      }
    })
    .catch((err) => console.log(err));
};
