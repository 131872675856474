import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_CLIENT } from "../../const";

export interface settingProyekProps {
  jam_masuk: string;
  jam_keluar: string;
  potongan_set_hari: string;
  toleransi_masuk: number;
  uuid: string;
}

interface settingProyekRequest {
  data: settingProyekProps;
  error: boolean;
  message: string;
}

export interface iInitialValuesSettingProyek {
  jam_masuk: string;
  jam_keluar: string;
  potongan_set_hari: string;
  toleransi_masuk: number;
}

export const initialValuesSettingProyek: iInitialValuesSettingProyek = {
  jam_masuk: "",
  jam_keluar: "",
  potongan_set_hari: "",
  toleransi_masuk: 0,
};

const fetchSettingProyek = async () => {
  const response = await API_CLIENT.get<settingProyekRequest>(
    "/setting-proyek"
  );
  return response.data;
};

export const useFetchSettingProyek = () => {
  return useQuery({
    queryKey: ["setting-proyek"],
    queryFn: fetchSettingProyek,
  });
};

export const useStoreUpdateSettingProyek = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async (variables: {
        newData: iInitialValuesSettingProyek;
        id: string;
      }) => {
        const { newData, id } = variables;
        return API_CLIENT.patch(`/setting-proyek/${id}`, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["setting-proyek"] });
      },
    });
  };