/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { cashFlowFilter } from "../cashFlow/interface";
import { useParams } from "react-router-dom";
import { useFetchLemburKaryawanProyek } from "./interface";
import { BiEdit } from "react-icons/bi";
import ButtonDelete from "../../../../../../assets/components/ButtonDelete";
import ButtonFilterRange from "../../../../../../assets/components/filterRange";

interface ListLemburProps {
  handleOpen: () => void;
  setIsLembur: Dispatch<SetStateAction<boolean>>;
  setIsEditLemburKaryawan: Dispatch<SetStateAction<any | null>>;
}

const ListLembur: FC<ListLemburProps> = ({
  handleOpen,
  setIsLembur,
  setIsEditLemburKaryawan,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>("today");

  const [dataHistory, setDataHistory] = useState([]);
  const [keyGlobal, setKeyGlobal] = useState<number>(1);

  const { uuid } = useParams();
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data, refetch } = useFetchLemburKaryawanProyek(
    selectedFilter,
    uuid ? uuid : "",
    dateRange,
  );

  useEffect(() => {
    if (data) {
      const result = data.data;
      setDataHistory(result.data);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataHistory(result.data);
        setKeyGlobal((prev) => prev + 1);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <>
      <div
        className="d-flex py-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div className="d-flex py-2 slide-top" style={{ alignItems: "center" }}>
        <div>
          <h3 className="mb-0">Riwayat Lembur</h3>
        </div>
      </div>
      {dataHistory.length < 1 ? (
        <div
          className="d-flex justify-content-center py-5 mt-3"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <img
            src="/images/no-data.svg"
            alt="no transaction"
            style={{ width: "50%" }}
            className="slide-top"
          />
          <span
            className="ps-1 mb-0 font-400 mt-4 font-18 slide-top"
            style={{ animationDelay: "200ms" }}
          >
            Belum ada transaksi.
          </span>
        </div>
      ) : (
        dataHistory.map((history: any, historyKey) => {
          return (
            <div
              className={`bg-theme slide-right shadow-m py-2 mt-3 ${
                dataHistory.length === historyKey + 1 ? "" : "mb-3"
              } `}
              key={keyGlobal + historyKey}
              style={{
                borderRadius: 10,
                animationDelay: Math.min(historyKey * 200, 1000) + "ms",
                marginBottom:
                  dataHistory.length === historyKey + 1 ? "4.2rem" : "",
              }}
            >
              <div
                className="d-flex px-2 pb-2 pt-2 pe-3"
                style={{ alignItems: "center", height: "100%" }}
              >
                <div
                  className="ps-2 align-self-center pe-1"
                  style={{ width: "78%" }}
                >
                  <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15">
                    {history.karyawan.name}{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      ({history.karyawan.jabatan.name})
                    </span>
                  </h5>
                  <div className="d-flex flex-column">
                    <span className="ps-1 mb-0 font-400 opacity-70 font-12 mt-2">
                      Waktu : <strong>{history.jumlah_jam} Jam</strong>
                    </span>
                    <span className="ps-1 mb-0 font-400 opacity-70 font-12 mt-n1">
                      Tanggal : <strong>{history.formatted_created_at}</strong>
                    </span>
                  </div>
                </div>
                {history.isToday && (
                  <>
                    <button
                      className="btn gradient-blue btn-s text-start me-1"
                      onClick={() => {
                        handleOpen(),
                          setIsLembur(true),
                          setIsEditLemburKaryawan(history);
                      }}
                    >
                      <BiEdit />
                    </button>
                    <ButtonDelete
                      className="btn gradient-red btn-s text-start ms-2"
                      url="proyek/management-lembur-karyawan"
                      KEY="management-lembur-karyawan"
                      values={history.uuid}
                    />
                  </>
                )}
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default ListLembur;
