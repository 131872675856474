/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { useMapEvents } from "react-leaflet";

interface GetLocationProps {
  setFieldValue: any
}

const GetLocation: FC<GetLocationProps> = ({ setFieldValue }) => {
  const map = useMapEvents({
    click(mapValues: any) {
      map.locate();
      setFieldValue('latitude',mapValues.latlng.lat)
      setFieldValue('longitude',mapValues.latlng.lng)
    },
  });
  return null;
};

export default GetLocation;
