import Layout from "../../../assets/components/layout";
import FormLaporan from "./form";

const LaporanProyek = () => {
  return (
    <>
      <Layout headerTitle="Laporan">
        <div className="card card-style">
          <div className="content">
            <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
            <h2 className="font-700 pb-0">Absensi</h2>
            <FormLaporan isWeekly />
          </div>
        </div>
        <div className="card card-style">
          <div className="content">
            <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
            <h2 className="font-700 pb-0">Pemasukan</h2>
            <FormLaporan type="pemasukan"  />
          </div>
        </div>
        <div className="card card-style">
          <div className="content">
            <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
            <h2 className="font-700 pb-0">Pengeluaran</h2>
            <FormLaporan type="pengeluaran" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LaporanProyek;
