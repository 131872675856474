/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import {
  handleSubmitStockInventarisProyek,
  stockInventarisProyekSchema,
} from "./interface";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface ModalStockInventarisProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  isDataEdit?: any | null;
}

const ModalStockInventaris: FC<ModalStockInventarisProps> = ({
  isOpen,
  setIsOpen,
  isDataEdit = null,
  setIsEnableModal,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { uuid } = useParams();

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["stock-inventaris"] });
      queryClient.invalidateQueries({ queryKey: ["details-inventaris"] });
    }, 150);
  };
  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => handleCloseModal()}
        dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
        {...props}
      >
        <div className="content my-3 mx-4">
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-0">Stock Inventaris</h4>
          <Formik
            initialValues={{
              jumlah: isDataEdit ? isDataEdit.jumlah : 0,
            }}
            onSubmit={(values) => {
              handleSubmitStockInventarisProyek(
                values,
                uuid,
                handleCloseModal,
                isDataEdit ? isDataEdit.uuid : null
              );
            }}
            validationSchema={stockInventarisProyekSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-field form-name mt-2">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Jumlah:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="number"
                    name="jumlah"
                    className="round-small mb-0"
                    placeholder="12"
                  />
                </div>
                {errors.jumlah && touched.jumlah && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.jumlah as string}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    gap: 10,
                    position: "relative",
                    zIndex: 1,
                  }}
                  className="d-flex mt-3"
                >
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default ModalStockInventaris;
