/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  generateUsername,
  iInitialValuesPenanggung,
  initialValuesPenanggung,
  penanggungJawabSchema,
  useStorePenanggungJawabProyek,
  useStoreUpdatePenanggungJawaProyek,
} from "./interface";
import { toast } from "../../../assets/components/toast";
import { useQueryClient } from "@tanstack/react-query";
import { generatePostEncrypt } from "../../../const";
import ModalContact from "./modalContact";

interface ModalPenanggungJawabProyekProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  isDataEdit?: any | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

const statusUser = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "deactive",
    label: "Deactive",
  },
];

const ModalPenanggungJawabProyek: FC<ModalPenanggungJawabProyekProps> = ({
  isOpen,
  setIsOpen,
  isDataEdit,
  setIsEnableModal,
  ...props
}) => {
  const queryClient = useQueryClient();
  const storeData = useStorePenanggungJawabProyek();
  const updateData = useStoreUpdatePenanggungJawaProyek();

  const [modalContact, setModalContact] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["proyek-penanggung-jawab"] });
    }, 150);
  };

  const handleSubmit = (values: any, resetForm: () => void) => {
    if (isDataEdit === null) {
      storeData.mutate(values, {
        onSuccess: (response) => {
          const result = response.data;
          if (result.error) {
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          } else {
            toast.success("Success", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
            resetForm();
            handleCloseModal();
          }
        },
        onError: (response) => {
          const result = response;
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        },
      });
    } else {
      updateData.mutate(
        {
          newData: values,
          id: isDataEdit ? generatePostEncrypt(isDataEdit.uuid) : "",
        },
        {
          onSuccess: (response) => {
            const result = response.data;
            if (result.error) {
              toast.error("Error", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            } else {
              toast.success("Success", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            }
            resetForm();
            handleCloseModal();
          },
          onError: (response) => {
            const result = response;
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          },
        }
      );
    }
  };

  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Penanggung Jawab</h4>
          <Formik
            initialValues={
              isDataEdit
                ? (isDataEdit as iInitialValuesPenanggung)
                : initialValuesPenanggung
            }
            validationSchema={penanggungJawabSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="name"
                    id="c1"
                    className="form-control rounded-xs"
                    placeholder="Nama Lengkap"
                    onKeyUp={() =>
                      !isDataEdit &&
                      setFieldValue("username", generateUsername(values.name))
                    }
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Lengkap
                  </label>
                  {errors.name && touched.name && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.name}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="text"
                    name="username"
                    id="c2"
                    className="form-control rounded-xs"
                    placeholder="Username"
                    readOnly={isDataEdit ? true : false}
                  />
                  <label htmlFor="c2" className="color-theme">
                    Username
                  </label>
                  {errors.username && touched.username && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.username}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                {!isDataEdit && (
                  <div className="form-custom mb-3 form-floating">
                    <Field
                      type="text"
                      name="password"
                      id="p2"
                      className="form-control rounded-xs"
                      placeholder="Password"
                    />
                    <label htmlFor="p2" className="color-theme">
                      Password
                    </label>
                    {errors.password && touched.password && (
                      <div
                        style={{ display: "block" }}
                        className="invalid-feedback"
                      >
                        {errors.password}
                      </div>
                    )}
                    <span>(required)</span>
                  </div>
                )}
                <div className="d-flex">
                  <div className="form-custom mb-3 form-floating w-100">
                    <Field
                      type="text"
                      name="no_tlp"
                      id="p4"
                      className="form-control rounded-xs"
                      placeholder="No. Telephone"
                    />
                    <label htmlFor="p4" className="color-theme">
                      No. Telephone
                    </label>
                    {errors.no_tlp && touched.no_tlp && (
                      <div
                        style={{ display: "block" }}
                        className="invalid-feedback"
                      >
                        {errors.no_tlp}
                      </div>
                    )}
                    <span>(required)</span>
                  </div>
                  <button
                    className="btn gradient-green shadow-bg-m text-start mb-3 ms-2"
                    onClick={() => setModalContact(true)}
                    type="button"
                  >
                    <i
                      className="bi bi-person-circle"
                      style={{ fontSize: 16 }}
                    ></i>
                  </button>
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    as="select"
                    name="status"
                    id="c5"
                    className="form-select rounded-xs"
                    aria-label="Floating label select example"
                  >
                    <option value="" disabled>
                      Silahkan Pilih
                    </option>
                    {statusUser.map(
                      (
                        status: { value: string; label: string },
                        statusKey: number
                      ) => {
                        return (
                          <option key={statusKey} value={status.value}>
                            {status.label}
                          </option>
                        );
                      }
                    )}
                  </Field>
                  <label htmlFor="c5" className="color-theme">
                    Status
                  </label>
                  {errors.status && touched.status && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.status}
                    </div>
                  )}
                </div>
                <div style={{ width: "100%", gap: 10 }} className="d-flex mt-3">
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    data-bs-dismiss="offcanvas"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={() => handleCloseModal()}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
                {modalContact && <ModalContact setModalContact={setModalContact} setFieldValue={setFieldValue} />}
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalPenanggungJawabProyek;
