/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction } from "react";
import { Offcanvas } from "react-bootstrap";
import FormSelect from "../../../../../../../assets/components/form/formSelect";
import { useParams } from "react-router-dom";
import {
  handleSubmitPeminjamanInventarisProyek,
  iPeminjamanInventarisProyek,
  initialPeminjamanInventarisProyek,
  peminjamanInventarisProyekSchema,
} from "./interface";

interface ModalPeminjamanInventarisProyekProps
  extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  isDataEdit?: any | null;
}

const ModalPeminjamanInventarisProyek: FC<
  ModalPeminjamanInventarisProyekProps
> = ({ isOpen, setIsOpen, isDataEdit = null, setIsEnableModal, ...props }) => {
  const { uuid: idProyek } = useParams();
  const queryClient = useQueryClient();

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["peminjaman-inventaris"] });
    }, 150);
  };
  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Peminjaman</h4>
          <Formik
            initialValues={
              isDataEdit
                ? (isDataEdit as iPeminjamanInventarisProyek)
                : initialPeminjamanInventarisProyek
            }
            onSubmit={(values) => {
              handleSubmitPeminjamanInventarisProyek(
                values,
                idProyek,
                handleCloseModal,
                isDataEdit ? isDataEdit.uuid : null
              );
            }}
            validationSchema={peminjamanInventarisProyekSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-field form-name mb-3 mt-3">
                  <label className="contactNameField color-theme">
                    Karyawan
                    <span>(required)</span>
                  </label>
                  <FormSelect
                    urlSelect={`proyek/karyawan-proyek/${idProyek}`}
                    name="karyawan_id"
                    error={errors}
                    touched={touched}
                    className="round-small z-9"
                    menuPlacement="top"
                  />
                </div>
                <div className="form-field form-name mb-3 mt-3">
                  <label className="contactNameField color-theme">
                    Inventaris
                    <span>(required)</span>
                  </label>
                  <FormSelect
                    urlSelect={`proyek/inventaris-proyek/${idProyek}`}
                    name="inventaris_id"
                    error={errors}
                    touched={touched}
                    className="round-small z-9"
                    menuPlacement="top"
                  />
                </div>
                <div className="form-field form-name mt-2">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Jumlah:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="number"
                    name="jumlah"
                    className="round-small mb-0"
                    placeholder="35"
                  />
                </div>
                {errors.jumlah && touched.jumlah && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.jumlah}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    gap: 10,
                    position: "relative",
                    zIndex: 1,
                  }}
                  className="d-flex mt-3"
                >
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalPeminjamanInventarisProyek;
