/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../../../../const";
import { toast } from "../../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export interface iInventarisProyek {
  name: string;
  nilai: string;
  stock: number;
}

export const initialInventarisProyek: iInventarisProyek = {
  name: "",
  nilai: "",
  stock: 0,
};

export const inventarisProyekSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value"),
  nilai: Yup.string().required("Please insert a value"),
  stock: Yup.number().min(1).required("Please insert a value"),
});

export const handleSubmitInventarisProyek = (
  values: iInventarisProyek,
  idProyek: string | undefined,
  handleCloseModal: () => void,
  uuid?: string | null
) => {
  const dataToSend = {
    ...values,
    idProyek: idProyek,
    uuid: uuid,
  };

  API_CLIENT.post("/proyek/inventaris-proyek", dataToSend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
      }
    })
    .catch((err) => console.log(err));
};

const fetchInventarisProyek = async (idProyek: string | undefined) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/inventaris-proyek?idProyek=${idProyek}`
  );
  return response.data;
};

export const useFetchInventarisProyek = (idProyek: string | undefined) => {
  return useQuery({
    queryKey: ["inventaris-proyek"],
    queryFn: () => fetchInventarisProyek(idProyek),
  });
};
