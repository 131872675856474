import { useNavigate } from "react-router-dom";

const HaderNoBackground = () => {
    const navigation = useNavigate();

    return (
        <>
            <div className="header-bar header-fixed header-app no-background" style={{ right:'unset' }}>
                <button className="btn-full btn border-blue-dark color-blue-dark btn-header" onClick={() => navigation(-1)}>
                    <i className="bi bi-arrow-left font-22 font-800"></i>
                </button>
            </div>
        </>
    )
}

export default HaderNoBackground