/* eslint-disable @typescript-eslint/no-explicit-any */
import { BiChevronRight, BiGridAlt, BiSolidHeart } from "react-icons/bi";
import {
  handleLogout,
  menuHeaderAdmin,
  menuProyekHeader,
  menuSettingHeader,
  warehouseProyek,
} from "./interface";
import { useNavigate } from "react-router-dom";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useProfile } from "../../../const";
import Notifications from "../notifications";

interface HeaderProps {
  urlBack?: boolean;
  noShadow?: boolean;
  urlBackTarget?: string;
  headerTitle?: string;
  rightChildren?: ReactNode;
}

const Header: FC<HeaderProps> = ({
  urlBack = false,
  noShadow = false,
  urlBackTarget,
  headerTitle,
  rightChildren,
}) => {
  const activeUrl = location.pathname;
  const navigation = useNavigate();
  const { data } = useProfile();
  const parts = activeUrl.split("/");
  const targetPart = parts.find((part) => part === "proyek-management-details");

  const menuToggle = useRef<HTMLAnchorElement | null>(null);
  const [profile, setProfile] = useState<any | null>({});

  const handleDirectMenu = (url: string) => {
    const menuButton = menuToggle.current;
    menuButton && menuButton.click();
    navigation(url);
  };

  useEffect(() => {
    if (data) {
      setProfile(data.user);
    }
  }, [data]);

  return (
    <>
      <div
        className={`header-bar header-fixed header-app ${
          noShadow && "no-shadow"
        }`}
      >
        {!urlBack && targetPart !== "proyek-management-details" ? (
          <>
            {profile.roles !== "karyawan" &&
              profile.roles !== "penaggung-jawab-proyek" && (
                <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#menu-main"
                  href="#"
                  ref={menuToggle}
                >
                  <BiGridAlt className="color-theme" />
                </a>
              )}
            <span
              style={{
                marginLeft:
                  profile.roles === "karyawan" ||
                  profile.roles === "penaggung-jawab-proyek"
                    ? 30
                    : "",
              }}
              className="header-title color-theme"
            >
              {headerTitle ? headerTitle : "Cv.Bangkit Putra Pratama"}
            </span>
          </>
        ) : targetPart === "proyek-management-details" ? (
          <>
            <a
              data-bs-toggle="offcanvas"
              data-bs-target="#menu-main"
              href="#"
              id="collapseMenu"
              ref={menuToggle}
            >
              <BiGridAlt className="color-theme" />
            </a>
            <span
              style={{ marginLeft: profile.roles === "karyawan" ? 30 : "" }}
              className="header-title color-theme"
            >
              {headerTitle ? headerTitle : "Cv.Bangkit Putra Pratama"}
            </span>
          </>
        ) : (
          <>
            <span
              onClick={() =>
                urlBackTarget ? navigation(urlBackTarget) : navigation(-1)
              }
            >
              <i className="bi bi-caret-left-fill font-11 color-theme ps-2"></i>
            </span>
            <span className="header-title color-theme">
              {headerTitle ? headerTitle : "Cv.Bangkit Putra Pratama"}
            </span>
          </>
        )}
        {rightChildren}
        <div className="me-3 d-flex">
          {profile.roles === "super-admin" &&
            targetPart === "proyek-management-details" && (
              <>
                <button
                  className="btn bg-fade1-blue no-shadow color-blue-dark text-start btn-homepage me-2"
                  onClick={() => navigation('/')}
                >
                  <i className="bi bi-chevron-left font-18 font-800"></i>
                </button>
              </>
            )}
          {profile.roles === "super-admin" && (
            <Notifications profile={profile} />
          )}
          <span className="" onClick={handleLogout}>
            <i className="bi bi-power font-24 font-800"></i>
          </span>
        </div>
      </div>
      {profile.roles !== "karyawan" &&
        profile.roles !== "penaggung-jawab-proyek" &&
        targetPart !== "proyek-management-details" && (
          <div
            id="menu-main"
            style={{ minWidth: 280, maxWidth: 300 }}
            className="offcanvas offcanvas-start offcanvas-detached rounded-m"
          >
            <div className="bg-theme mx-3 rounded-m shadow-m mt-3 mb-3">
              <a href="/profile">
                <div className="d-flex px-2 pb-2 pt-2">
                  <div>
                    <img
                      src={profile?.profile}
                      alt="profile"
                      width={45}
                      className="rounded-s"
                    />
                  </div>
                  <div className="ps-2 align-self-center">
                    <h5 className="ps-1 mb-0 line-height-xs pt-1">
                      {profile?.name}
                    </h5>
                    <h6 className="ps-1 mb-0 font-400 opacity-40">
                      {profile?.jabatan_name}
                    </h6>
                  </div>
                  <div className="ms-auto"></div>
                </div>
              </a>
            </div>
            <div className="menu-divider">NAVIGATION</div>
            <div className="menu-list">
              <div className="card card-style rounded-m p-3 py-2 mb-0">
                {menuHeaderAdmin.map((menu, keyMenu) => {
                  const isActive = activeUrl === menu.url;
                  return (
                    <span
                      key={keyMenu}
                      className={`${isActive ? "active-item" : ""}`}
                      onClick={() => handleDirectMenu(menu.url)}
                    >
                      <div
                        className={`icon ${menu.gradient} shadow-bg shadow-bg-xs`}
                      >
                        <menu.icon size={18} />
                      </div>
                      <span>{menu.title}</span>
                      {isActive ? (
                        <div className="icon-active" />
                      ) : (
                        <BiChevronRight size={18} />
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="menu-divider mt-4">PROYEK</div>
            <div className="menu-list">
              <div className="card card-style rounded-m p-3 py-2 mb-0">
                {menuProyekHeader.map((menu, keyMenu) => {
                  const isActive = activeUrl === menu.url;
                  return (
                    <span
                      key={keyMenu}
                      className={`${isActive ? "active-item" : ""}`}
                      onClick={() => handleDirectMenu(menu.url)}
                    >
                      <div
                        className={`icon ${menu.gradient} shadow-bg shadow-bg-xs`}
                      >
                        <menu.icon size={18} />
                      </div>
                      <span>{menu.title}</span>
                      {isActive ? (
                        <div className="icon-active" />
                      ) : (
                        <BiChevronRight size={18} />
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="menu-divider mt-4">WAREHOUSE PROYEK</div>
            <div className="menu-list">
              <div className="card card-style rounded-m p-3 py-2 mb-0">
                {warehouseProyek.map((menu, keyMenu) => {
                  const isActive = activeUrl === menu.url;
                  return (
                    <span
                      key={keyMenu}
                      className={`${isActive ? "active-item" : ""}`}
                      onClick={() => handleDirectMenu(menu.url)}
                    >
                      <div
                        className={`icon ${menu.gradient} shadow-bg shadow-bg-xs`}
                      >
                        <menu.icon size={18} />
                      </div>
                      <span>{menu.title}</span>
                      {isActive ? (
                        <div className="icon-active" />
                      ) : (
                        <BiChevronRight size={18} />
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="menu-divider mt-4">SETTINGS</div>
            <div className="menu-list">
              <div className="card card-style rounded-m p-3 py-2 mb-0">
                {menuSettingHeader.map((menu, keyMenu) => {
                  const isActive = activeUrl === menu.url;
                  return (
                    <span
                      key={keyMenu}
                      className={`${isActive ? "active-item" : ""}`}
                      onClick={() => handleDirectMenu(menu.url)}
                    >
                      <div
                        className={`icon ${menu.gradient} shadow-bg shadow-bg-xs`}
                      >
                        <menu.icon size={18} />
                      </div>
                      <span>{menu.title}</span>
                      {isActive ? (
                        <div className="icon-active" />
                      ) : (
                        <BiChevronRight size={18} />
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
            <p className="text-center mb-0 mt-3 pb-3 font-9 text-uppercase font-600 color-theme">
              Made with
              <BiSolidHeart className="color-red-dark font-9 m-1" size={15} />
              by{" "}
              <a
                href="https://bocahgabut.dev"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bocah Gabut
              </a>
              <span className="copyright-year"> 2023</span>
            </p>
          </div>
        )}
    </>
  );
};

export default Header;
