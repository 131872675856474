/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction, useState } from "react";
import { Offcanvas } from "react-bootstrap";

import { DateRange } from "react-date-range";
import moment from "moment";
import {
  iInitialValuesSubmission,
  initialValuesSubmission,
  submissionSchema,
  submitStoreSubmission,
  submitUpdateSubmission,
} from "./interface";
import { useQueryClient } from "@tanstack/react-query";

interface SubmissionCRUDProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  isEdit?: any | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

interface iSelectionRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const SubmissionCRUD: FC<SubmissionCRUDProps> = ({
  isOpen,
  isEdit,
  setIsOpen,
  setIsEnableModal,
  ...props
}) => {
  const selectionRange = {
    startDate: isEdit ? new Date(isEdit.start_date) : new Date(),
    endDate: isEdit ? new Date(isEdit.end_date) : new Date(),
    key: "selection",
  };

  const queryClient = useQueryClient();
  const [selectDateRange, setSelectDateRange] =
    useState<iSelectionRange>(selectionRange);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["submission"] });
    }, 150);
  };

  const handleSelect = (ranges: any, setFieldValue: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectDateRange({ startDate, endDate, key: "selection" });

    setFieldValue(
      "start_date",
      new Date(startDate).toLocaleDateString("id-ID", options)
    );
    setFieldValue(
      "end_date",
      new Date(endDate).toLocaleDateString("id-ID", options)
    );
  };

  return (
    <Offcanvas
      show={isOpen}
      className="bg-theme"
      style={{
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        maxHeight: "85dvh",
      }}
      onHide={() => handleCloseModal()}
      {...props}
      placement="bottom"
    >
      <Offcanvas.Body>
        <h6 className="font-700 mb-n1 color-highlight">Pengajuan</h6>
        <h4 className="pb-2">Form pengajuan</h4>
        <Formik
          initialValues={
            isEdit
              ? {
                  status: isEdit.status,
                  note: isEdit.keterangan,
                  start_date: new Date(isEdit.start_date).toLocaleDateString("id-ID", options),
                  end_date: new Date(isEdit.end_date).toLocaleDateString("id-ID", options),
                }
              : initialValuesSubmission
          }
          onSubmit={(values: iInitialValuesSubmission) => {
            if(isEdit){
              submitUpdateSubmission(isEdit.uuid,values, selectedFile, handleCloseModal);
            }else{
              submitStoreSubmission(values, selectedFile, handleCloseModal);
            }
          }}
          validationSchema={submissionSchema}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div
                style={{
                  boxShadow: "0 5px 14px 0 rgba(0,0,0,.1)",
                  borderRadius: 20,
                }}
                className="mb-2"
              >
                <DateRange
                  ranges={[selectDateRange]}
                  preventSnapRefocus={true}
                  showDateDisplay={false}
                  calendarFocus="backwards"
                  onChange={(range) => handleSelect(range, setFieldValue)}
                  minDate={moment().add(1, "day").toDate()}
                />
              </div>
              {errors.start_date && errors.end_date && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.start_date || errors.end_date}
                </div>
              )}
              <span className="text-muted my-3 font-13">
                Silahkan pilih rentang tanggal pengajuan izin kamu.
              </span>
              <div className="form-custom mb-3 form-floating mt-3">
                <Field
                  as="select"
                  name="status"
                  id="c1"
                  className="form-select rounded-xs"
                  aria-label="Floating label select example"
                >
                  <option value="" disabled>
                    Silahkan Pilih
                  </option>
                  <option value="izin">Izin</option>
                  <option value="sakit">Sakit</option>
                </Field>
                <label htmlFor="c1" className="color-theme">
                  Status
                </label>
                {errors.status && touched.status && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.status}
                  </div>
                )}
              </div>
              <div className="form-field form-text mt-3">
                <label
                  htmlFor="c2"
                  className="contactMessageTextarea color-theme"
                >
                  Keterangan:
                  <span>(required)</span>
                </label>
                <Field
                  type="number"
                  name="note"
                  id="c2"
                  as="textarea"
                  row={1}
                  className="round-small mb-0"
                  placeholder="Keterangan"
                  style={{ height: 100 }}
                />
              </div>
              {errors.note && touched.note && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.note}
                </div>
              )}
              <div className="form-field form-name mt-3">
                <label
                  htmlFor="c2"
                  className="contactMessageTextarea color-theme"
                >
                  FILE
                  <span>(optional)</span>
                </label>
                <input
                  type="file"
                  name="file"
                  id="c2"
                  className="form-control mb-0"
                  onChange={handleFileChange}
                />
              </div>
              <p className="mb-0 font-12">
                Silahkan gambar atau file untuk bukti lainya.
              </p>
              <div className="d-flex" style={{ gap: 10 }}>
                <button
                  className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "30%" }}
                  type="button"
                  onClick={handleCloseModal}
                >
                  Batal
                </button>
                <button
                  className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "70%" }}
                  type="submit"
                >
                  Simpan Data
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SubmissionCRUD;
