const NoData = () => {
  return (
    <div
      className="d-flex justify-content-center py-5 mt-3"
      style={{ flexDirection: "column", alignItems: "center" }}
    >
      <img
        src="/images/no-data.svg"
        alt="no transaction"
        style={{ width: "50%" }}
        className="slide-top"
      />
      <span
        className="ps-1 mb-0 font-400 mt-4 font-18 slide-top"
        style={{ animationDelay: "200ms" }}
      >
        Tidak ada data.
      </span>
    </div>
  );
};

export default NoData;
