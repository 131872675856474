/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from "react";
import Layout from "../../assets/components/layout";
import { getLocation, toThousand } from "../../const";
import { handleLogout } from "../../assets/components/header/interface";
import { useFetchDashboardKaryawanProyek } from "./interface";
import ChartProyek from "../proyek/management/details/chart";
import Preloader from "../../assets/components/preloader";
import moment from "moment";

interface DashboardKaryawanProyekProps {
  profile: any;
}

const DashboardKaryawanProyek: FC<DashboardKaryawanProyekProps> = ({
  profile,
}) => {
  const { user } = profile;
  const [address, setAddress] = useState<any | null>({});

  const { data } = useFetchDashboardKaryawanProyek();

  const [dashboardConfig, setDashboardConfig] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCityFromCoordinates = async (
    lat: string | null | undefined,
    lng: string | null | undefined
  ) => {
    const url =
      "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" +
      lat +
      "&lon=" +
      lng;

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setAddress(data.address);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      const { latitude, longitude } = (await getLocation()) as {
        latitude: string;
        longitude: string;
      };

      getCityFromCoordinates(latitude, longitude);
    };
    getData();
  }, [user]);

  useEffect(() => {
    document.body.style.background = "rgb(232 232 232 / 19%)";
    if (data) {
      const result = data.data;
      setDashboardConfig(result);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout noShadow noPaddingTop noHeader noPaddingBottom>
          <div className="background-2 w-100">
            <div className="d-flex px-3 py-3">
              <a href="/profile">
                <img
                  src={user.profile}
                  alt="profile"
                  width={45}
                  height={45}
                  style={{ borderRadius: "50%" }}
                />
              </a>
              <a href="/profile" className="ps-2 align-self-center">
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-18">
                  {user.name}
                </h5>
                <h6 className="ps-1 mb-0 font-400">
                  {address.village}, {address.city} {address.state}
                </h6>
              </a>
              <div className="ms-auto">
                <button
                  className="btn btn-notification"
                  style={{ borderRadius: "50%" }}
                  onClick={() => handleLogout()}
                >
                  <i className="bi bi-power"></i>
                </button>
              </div>
            </div>
          </div>
          {dashboardConfig.labelChartCashFlow.length > 0 && (
            <div className="card card-style margin-background-2">
              <div className="content">
                <ChartProyek
                  xaxis={{
                    categories:
                      dashboardConfig && dashboardConfig.labelChartCashFlow,
                  }}
                  series={
                    dashboardConfig ? dashboardConfig.chartCashflow.series : []
                  }
                />
              </div>
            </div>
          )}
          <div
            className={`d-flex py-2 px-4 slide-top ${
              dashboardConfig.labelChartCashFlow.length < 1 &&
              "margin-background-2"
            }`}
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Data Proyek</h3>
            </div>
          </div>
          <div className="px-2 mb-4">
            {dashboardConfig.proyeks.length < 1 ? (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada Data
                </h5>
              </div>
            ) : (
              dashboardConfig.proyeks.map((proyek: any, proyekKey: number) => {
                return (
                  <div
                    key={proyekKey}
                    className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3`}
                    style={{
                      borderRadius: 10,
                      animationDelay: Math.min(proyekKey * 200, 1000) + "ms",
                    }}
                  >
                    <a
                      href={`/proyek-management-details/${proyek.uuid}`}
                      className="d-flex w-100"
                    >
                      <div className="d-flex">
                        <div className="me-1">
                          <img
                            src="/images/helm-proyek-placeholder.png"
                            width={60}
                            alt="photo items"
                            style={{ borderRadius: 10 }}
                          />
                        </div>
                        <div className="ps-2 align-self-center">
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15">
                            {proyek.name}
                          </h5>
                          <h4 className="ps-1 mb-0 font-400 font-12 mt-1">
                            Dibuat :{" "}
                            {moment(new Date(proyek.created_at)).format("ll")}
                          </h4>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
            )}
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Transaksi</h3>
            </div>
          </div>
          <div className="px-2">
            {dashboardConfig.lastTransaction.length < 1 ? (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada transaksi
                </h5>
              </div>
            ) : (
              dashboardConfig.lastTransaction
                .sort(
                  (a: { created_at: Date }, b: { created_at: Date }) =>
                    new Date(b.created_at).valueOf() -
                    new Date(a.created_at).valueOf()
                )
                .map((lastTransaction: any, lastTransactionKey: number) => {
                  return (
                    <div
                      key={lastTransactionKey}
                      className={`bg-theme slide-right mx-3 shadow-m mt-3 mb-3 py-1`}
                      style={{
                        borderRadius: 10,
                        animationDelay:
                          Math.min(lastTransactionKey * 200, 1000) + "ms",
                      }}
                    >
                      <div
                        className="d-flex px-2 pb-2 pt-2 pe-3"
                        style={{ alignItems: "center", height: "100%" }}
                      >
                        <div
                          className="ps-2 align-self-center"
                          style={{ width: "50%" }}
                        >
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14 text-hidden-1">
                            {lastTransaction.name}
                          </h5>
                          <div className="d-flex flex-column">
                            <span className="ps-1 mb-0 font-400 opacity-70 font-12">
                              {lastTransaction.formatted_created_at}
                            </span>
                            <span className="ps-1 mb-0 font-400 opacity-70 font-12 mt-n1">
                              {lastTransaction.penerima}{" "}
                              <strong>({lastTransaction.proyek})</strong>
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto">
                          <h6
                            className={`ps-1 mb-0 line-height-xs pt-1 font-14 text-hidden-2 ${
                              lastTransaction.type === "pengeluaran" &&
                              "text-danger"
                            }`}
                          >
                            {lastTransaction.type === "pengeluaran" && "-"} Rp.{" "}
                            {toThousand(lastTransaction.jumlah.toString())}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Absensi</h3>
            </div>
          </div>
          <div className="px-4">
            {dashboardConfig.lastAbsensi.length < 1 ? (
              <div
                className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada riwayat absen
                </h5>
              </div>
            ) : (
              dashboardConfig.lastAbsensi.map(
                (karyawan: any, karyawanKey: any) => {
                  return (
                    <div
                      className={`slide-right bg-theme shadow-bg shadow-m py-2 ${
                        karyawanKey !== 0 && "mt-3"
                      } `}
                      key={karyawanKey}
                      style={{
                        animationDelay:
                          Math.min(karyawanKey * 200, 1000) + "ms",
                        borderRadius: 10,
                      }}
                    >
                      <div className="d-flex px-2 pb-2 pt-2">
                        <div className={`ps-2 align-self-center w-100`}>
                          <h5
                            style={{ textTransform: "capitalize" }}
                            className={`ps-1 mb-0 line-height-xs pt-1 d-flex`}
                          >
                            <span
                              className="text-hidden-1 me-2"
                              style={{ maxWidth: 250 }}
                            >
                              {karyawan.karyawan.name}
                            </span>
                            <span style={{ textTransform: "capitalize" }}>
                              ({karyawan.status})
                            </span>
                          </h5>
                          <h6 className={`ps-1 mb-0 mt-1 font-400 opacity-80`}>
                            +{karyawan.karyawan.no_hp}{" "}
                            <strong>({karyawan.karyawan.jabatan.name})</strong>
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            )}
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Gudang</h3>
            </div>
          </div>
          <div className="px-3 mb-4">
            {dashboardConfig.warehouse.length < 1 ? (
              <div
                className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada data
                </h5>
              </div>
            ) : (
              dashboardConfig.warehouse
                .sort(
                  (a: { created_at: string }, b: { created_at: string }) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((warehouse: any, warehouseKey: number) => {
                  return (
                    <div
                      className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-2 ${
                        warehouseKey !== 0 && "mt-3"
                      } `}
                      key={warehouseKey}
                      style={{
                        animationDelay:
                          Math.min(warehouseKey * 200, 3000) + 10 + "ms",
                        borderRadius: 10,
                        minHeight: 55,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="d-flex w-100">
                        <div className="ps-2 align-self-center">
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15">
                            {moment(warehouse.created_at)
                              .tz("Asia/Jakarta")
                              .format("LL")}
                          </h5>
                          <h4 className="ps-1 mb-0 font-400 font-12 mt-1">
                            Reference : {warehouse.reference}
                          </h4>
                          <h4 className="ps-1 mb-0 font-400 font-12 mt-n1">
                            Proyek : {warehouse.proyek}
                          </h4>
                          <h4
                            className="ps-1 mb-0 font-400 font-12 mt-n1"
                            style={{ textTransform: "capitalize" }}
                          >
                            Keterangan : {warehouse.name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </Layout>
      )}
    </>
  );
};

export default DashboardKaryawanProyek;
