/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useEffect, useState } from "react";
import Preloader from "../preloader";
import Header from "../header";
import Footer from "../footer";
import ToastContainer from "../toast";
import HaderNoBackground from "../header/noBackground";

interface LayoutProps {
  children: ReactNode;
  isShowFooter?: boolean;
  urlBack?: boolean;
  urlBackTarget?: string;
  noPadding?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
  noShadow?: boolean;
  noNavMobile?: boolean;
  headerTitle?: string;
  rightChildren?: ReactNode;
  isHiddenBackgroundHeader?: boolean;
  noHeader?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  isShowFooter = true,
  noPadding = false,
  noNavMobile = false,
  noPaddingBottom = false,
  noPaddingTop = false,
  noShadow = false,
  isHiddenBackgroundHeader = false,
  noHeader = false,
  urlBack,
  urlBackTarget,
  headerTitle,
  rightChildren,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isMobileApps, setIsMobileApps] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.background = "";
    document.body.style.overflow = "";
    document.body.classList.remove('dashboard-sober');

    const isMobile = document.querySelector(".mobile-apps");
    if (isMobile) {
      setIsMobileApps(true);
    }

    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, []);

  return (
    <>
      {isLoading && <Preloader />}
      {noHeader ? (
        <></>
      ) : isHiddenBackgroundHeader ? (
        <HaderNoBackground />
      ) : (
        <Header
          urlBack={urlBack}
          noShadow={noShadow}
          urlBackTarget={urlBackTarget}
          headerTitle={headerTitle}
          rightChildren={rightChildren}
        />
      )}
      {!noNavMobile && isMobileApps && <div className="mobile-apps-blur" />}
      {isShowFooter && <Footer />}
      <div
        className={`page-content header-clear-medium ${
          noPadding && "no-padding"
        } ${noPaddingBottom && "no-padding-bottom"} ${noPaddingTop && "no-padding-top"}`}
      >
        {children}
      </div>
      <ToastContainer />
    </>
  );
};

export default Layout;
