/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import FormSelect from "../../../../assets/components/form/formSelect";
import {
  handleSubmitUnits,
  iInitialValuesUnits,
  initialValuesUnits,
  unitsSchema,
} from "./interface";
import { useQueryClient } from "@tanstack/react-query";

interface FormUnitProyeksProps {
  isDataEdit: any | null;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  setIsDataEdit: Dispatch<SetStateAction<any | null>>;
}

const FormUnitProyeks: FC<FormUnitProyeksProps> = ({
  setIsEnableModal,
  setIsDataEdit,
  isDataEdit,
}) => {
  const queryClient = useQueryClient();
  
  return (
    <>
      <div className="card card-style slide-right" style={{ borderRadius: 10 }}>
        <div className="content">
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Units</h4>
          <Formik
            initialValues={
              isDataEdit
                ? (isDataEdit as iInitialValuesUnits)
                : initialValuesUnits
            }
            onSubmit={(values) => {
              handleSubmitUnits(
                values,
                setIsEnableModal,
                queryClient,
                isDataEdit ? isDataEdit.uuid : null
              );
            }}
            validationSchema={unitsSchema}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }} className="form-field form-name slide-top mt-2">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Name:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="round-small mb-0"
                    placeholder="Meter"
                  />
                </div>
                {errors.name && touched.name && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                )}
                <div style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }} className="form-field form-name slide-top mt-2">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Code:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="text"
                    name="code"
                    className="round-small mb-0"
                    placeholder="M, CM"
                  />
                </div>
                {errors.code && touched.code && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.code}
                  </div>
                )}
                <div style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }} className="form-field form-name slide-top mb-3 mt-3">
                  <label className="contactNameField color-theme">
                    Base unit
                    <span>(required)</span>
                  </label>
                  <FormSelect
                    urlSelect={`warehouse/units-list/${
                      isDataEdit ? isDataEdit.uuid : "none"
                    }`}
                    name="base_unit"
                    error={errors}
                    touched={touched}
                    className="round-small z-9"
                    menuPlacement="top"
                    isClearable
                  />
                </div>
                {values.base_unit && (
                  <>
                    <div style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }} className="form-field form-name slide-top mt-2 slide-top">
                      <label
                        htmlFor="c1"
                        className="contactNameField color-theme"
                      >
                        Operator:
                        <span>(required)</span>
                      </label>
                      <Field
                        as="select"
                        type="number"
                        name="operator"
                        className="round-small mb-0"
                      >
                        <option value="" disabled>-- Silahkan Pilih --</option>
                        <option value="*">Multiply (*)</option>
                        <option value="/">Divide (/)</option>
                        <option value="+">Plus (+)</option>
                        <option value="-">Minus (-)</option>
                      </Field>
                    </div>
                    {errors.operator && touched.operator && (
                      <div
                        style={{ display: "block" }}
                        className="invalid-feedback"
                      >
                        {errors.operator}
                      </div>
                    )}
                    <div style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }} className="form-field form-name slide-top mt-2 slide-top">
                      <label
                        htmlFor="c1"
                        className="contactNameField color-theme"
                      >
                        Operator Value:
                        <span>(required)</span>
                      </label>
                      <Field
                        type="number"
                        name="operator_value"
                        className="round-small mb-0"
                        placeholder="1"
                      />
                    </div>
                    {errors.operator_value && touched.operator_value && (
                      <div
                        style={{ display: "block" }}
                        className="invalid-feedback"
                      >
                        {errors.operator_value}
                      </div>
                    )}
                  </>
                )}
                <div
                  style={{
                    width: "100%",
                    gap: 10,
                    position: "relative",
                    zIndex: 1,
                  }}
                  className="d-flex mt-3"
                >
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={() => {
                      setIsDataEdit(null), setIsEnableModal(false);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default FormUnitProyeks;
