/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  iInitalValuesSubmissionKaryawanProyek,
  initalValuesSubmissionKaryawanProyek,
  submissionKaryawanProyekSchema,
} from "../../interface";
import { useQueryClient } from "@tanstack/react-query";

interface ModalAbsensiIzinProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  selectedKaryawan: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  handleSelectedKaryawan: (
    karyawan: any,
    status: string,
    dataCuti?: any
  ) => void;
}

const ModalAbsensiIzin: FC<ModalAbsensiIzinProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  selectedKaryawan,
  handleSelectedKaryawan,
  ...props
}) => {
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onloadend = function () {
        const base64String = reader.result as string;
        setSelectedFile(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["management-absensi-daily"] });
    }, 150);
  };

  const submitStoreSubmission = (
    values: iInitalValuesSubmissionKaryawanProyek
  ) => {
    const dataCuti = {
      ...values,
      file: selectedFile,
    };
    handleSelectedKaryawan(selectedKaryawan, values.status, dataCuti);
    handleCloseModal()
  };

  return (
    <Offcanvas
      show={isOpen}
      className="bg-theme"
      style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
      onHide={() => handleCloseModal()}
      {...props}
      placement="bottom"
    >
      <Offcanvas.Body>
        <h4 className="pb-2">Form Tidak Masuk</h4>
        <Formik
          initialValues={initalValuesSubmissionKaryawanProyek}
          onSubmit={(values) => {
            submitStoreSubmission(values);
          }}
          validationSchema={submissionKaryawanProyekSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="form-custom mb-3 form-floating mt-3">
                <Field
                  as="select"
                  name="status"
                  id="c1"
                  className="form-select rounded-xs"
                  aria-label="Floating label select example"
                >
                  <option value="" disabled>
                    Silahkan Pilih
                  </option>
                  <option value="izin">Izin</option>
                  <option value="sakit">Sakit</option>
                  <option value="alpa">Alpa</option>
                </Field>
                <label htmlFor="c1" className="color-theme">
                  Status
                </label>
                {errors.status && touched.status && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.status}
                  </div>
                )}
              </div>
              <div className="form-field form-text mt-3">
                <label
                  htmlFor="c2"
                  className="contactMessageTextarea color-theme"
                >
                  Keterangan:
                  <span>(required)</span>
                </label>
                <Field
                  type="number"
                  name="note"
                  id="c2"
                  as="textarea"
                  row={1}
                  className="round-small mb-0"
                  placeholder="Keterangan"
                  style={{ height: 100 }}
                />
              </div>
              {errors.note && touched.note && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.note}
                </div>
              )}
              <div className="form-field form-name mt-3">
                <label
                  htmlFor="c2"
                  className="contactMessageTextarea color-theme"
                >
                  FILE
                  <span>(optional)</span>
                </label>
                <input
                  type="file"
                  name="file"
                  id="c2"
                  className="form-control mb-0"
                  onChange={handleFileChange}
                  accept="image/*,application/pdf"
                />
              </div>
              <p className="mb-0 font-12">
                Silahkan masukan gambar atau file untuk bukti lainya.
              </p>
              <div className="d-flex" style={{ gap: 10 }}>
                <button
                  className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "30%" }}
                  type="button"
                  onClick={handleCloseModal}
                >
                  Batal
                </button>
                <button
                  className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "70%" }}
                  type="submit"
                >
                  Simpan Data
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ModalAbsensiIzin;
