/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import moment from "moment-timezone";
import FormSelect from "../../../../assets/components/form/formSelect";

import InputPlusMinus from "../../../../assets/components/form/formInpuPlusMin";
import { BiTrash } from "react-icons/bi";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  handleSubmitItemKeluar,
  iInitialValuesItemsKeluar,
  iSelectItem,
  initialValuesItemsKeluar,
} from "./interface";

interface FormItemsKeluarProps {
  isDataEdit: any | null;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  setIsDataEdit: Dispatch<SetStateAction<any | null>>;
}

const FormItemsKeluar: FC<FormItemsKeluarProps> = ({
  setIsEnableModal,
  setIsDataEdit,
  isDataEdit,
}) => {
  const { uuid: idProyek } = useParams();
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItems] = useState<iSelectItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (isDataEdit) {
      const selectItemEdit: iSelectItem[] = [];
      isDataEdit.details.map((details: any) => {
        const stock =
          details.items.stock.length < 1
            ? 0
            : details.items.stock
                .map(
                  (stock: any, index: number) =>
                    `${stock.stock} ${stock.label}${
                      index < details.items.stock.length - 1 ? " " : ""
                    }`
                )
                .join(" ");

        selectItemEdit.push({
          uuid: details.items_id,
          photo: details.items.file,
          select_unit: details.unit_id,
          stock: stock,
          label: details.items.name,
          code: details.items.code,
          qty: details.qty,
          unit: [
            {
              title: `${details.unit.name} (${details.unit.code})`,
              uuid_unit: details.unit.uuid,
            },
          ],
        });
      });
      setSelectedItems(selectItemEdit);
    }
  }, [isDataEdit]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleSelect = (
    value: string | any,
    qty?: number | string,
    select_unit?: string
  ) => {
    const dataValue = typeof value === "string" ? JSON.parse(value) : value;
    const isItemAlreadySelected = selectedItem.some(
      (item) => item.uuid === dataValue.uuid
    );

    if (qty !== undefined && qty !== "") {
      if (typeof qty === "number" && qty < 1) {
        return handleRemoveSelect(dataValue);
      }
    }

    const dataUnit: { title: string; uuid_unit: any }[] = [];
    dataUnit.push({
      title: `${dataValue.unit.name} (${dataValue.unit.code})`,
      uuid_unit: dataValue.unit.uuid,
    });

    if (dataValue.unit.unit) {
      const baseUnit = dataValue.unit.unit;
      dataUnit.push({
        title: `${baseUnit.name} (${baseUnit.code})`,
        uuid_unit: baseUnit.uuid,
      });
    }

    let stock;
    if (typeof value === "string") {
      stock =
        dataValue.stock.length < 1
          ? 0
          : dataValue.stock
              .map(
                (stock: any, index: number) =>
                  `${stock.stock} ${stock.label}${
                    index < dataValue.stock.length - 1 ? " " : ""
                  }`
              )
              .join(" ");
    } else {
      stock = dataValue.stock;
    }

    const dataToAddSelectUnit = {
      uuid: dataValue.uuid,
      photo: dataValue.file,
      select_unit: dataUnit[0].uuid_unit,
      stock: stock,
      label: dataValue.name,
      code: dataValue.code,
      qty: qty ? qty : 1,
      unit: dataUnit,
    };

    setSelectedItems((prev) => {
      const selectedItemIndex = prev.findIndex(
        (item) => item.uuid === dataValue.uuid
      );

      if (isItemAlreadySelected && selectedItemIndex !== -1) {
        const updatedSelectedItems = [...prev];

        const prevData = prev;
        const selectedItemIndexUnit = prevData.findIndex(
          (item) =>
            item.uuid === dataValue.uuid && item.select_unit === select_unit
        );

        const indexFilterItem =
          selectedItemIndexUnit !== -1
            ? selectedItemIndexUnit
            : selectedItemIndex;

        const checkIsUnitAlreadySelected =
          updatedSelectedItems[indexFilterItem].select_unit;

        if (
          updatedSelectedItems[indexFilterItem].unit.length > 1 &&
          checkIsUnitAlreadySelected !== dataToAddSelectUnit.select_unit &&
          !select_unit &&
          !qty
        ) {
          dataToAddSelectUnit.unit = dataToAddSelectUnit.unit.filter(
            (object) => {
              return object.uuid_unit !== checkIsUnitAlreadySelected;
            }
          );

          updatedSelectedItems[indexFilterItem].unit = updatedSelectedItems[
            indexFilterItem
          ].unit.filter((object) => {
            return object.uuid_unit !== dataToAddSelectUnit.select_unit;
          });

          return [...prev, dataToAddSelectUnit];
        } else {
          if (qty !== undefined) {
            updatedSelectedItems[indexFilterItem].qty = qty && qty;
          } else {
            const oldQty = updatedSelectedItems[indexFilterItem].qty;
            if (typeof oldQty === "number") {
              updatedSelectedItems[indexFilterItem].qty = oldQty + 1;
            } else {
              updatedSelectedItems[indexFilterItem].qty = parseInt(oldQty);
            }
          }

          if (select_unit) {
            updatedSelectedItems[indexFilterItem].select_unit = select_unit;
          }
          return updatedSelectedItems;
        }
      } else {
        return [...prev, dataToAddSelectUnit];
      }
    });
  };

  const handleRemoveSelect = (value: any) => {
    const updatedSelectedKaryawan = selectedItem.filter(
      (item) => item.uuid !== value.uuid
    );
    setSelectedItems(updatedSelectedKaryawan);
  };

  const handleCloseForm = () => {
    setSelectedItems([]);
    setIsDataEdit(null);
    setIsEnableModal(false);
  };

  return (
    <>
      <Formik
        initialValues={
          isDataEdit
            ? (isDataEdit as iInitialValuesItemsKeluar)
            : initialValuesItemsKeluar
        }
        onSubmit={(values) => {
          handleSubmitItemKeluar(
            values,
            idProyek ? idProyek : "",
            selectedFile,
            selectedItem,
            setIsEnableModal,
            queryClient,
            isDataEdit ? isDataEdit.uuid : ""
          );
        }}
        // validationSchema={itemsSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <div
              className="card card-style slide-top mb-2"
              style={{ borderRadius: 10 }}
            >
              <div className="content">
                <h6 className="font-700 mb-n1 color-highlight">Form</h6>
                <h4 className="pb-2">Items Keluar</h4>
                <div
                  style={{ animationDelay: Math.min(2 * 200, 3000) + "ms" }}
                  className="form-field form-name slide-top mt-2"
                >
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Tanggal:
                  </label>
                  <input
                    type="text"
                    value={moment().tz("Asia/Jakarta").format("LL")}
                    disabled
                    readOnly
                  />
                </div>
                <div
                  style={{ animationDelay: Math.min(3 * 200, 3000) + "ms" }}
                  className="form-field form-name slide-top mt-1"
                >
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Reference:
                    <span>(optional)</span>
                  </label>
                  <Field
                    type="text"
                    name="reference"
                    className="round-small mb-0"
                    placeholder="Reference..."
                  />
                </div>
                <div
                  style={{ animationDelay: Math.min(4 * 200, 1000) + "ms" }}
                  className="form-field form-name slide-top mb-3 mt-3"
                >
                  <label className="contactNameField color-theme">
                    Items
                    <span>(required)</span>
                  </label>
                  <FormSelect
                    urlSelect={`warehouse/items-list/${idProyek}`}
                    name="items_id"
                    error={errors}
                    touched={touched}
                    className="round-small z-9"
                    menuPlacement="top"
                    isFormik={false}
                    isClearableAfterSelect
                    onCallback={handleSelect}
                  />
                </div>
              </div>
            </div>
            {selectedItem.length < 1 ? (
              <></>
            ) : (
              selectedItem.map((item, itemKey) => {
                return (
                  <div
                    className={`bg-theme slide-right shadow-m mb-2 py-2 px-2 mx-3 ${
                      itemKey !== 0 && "mt-2"
                    } `}
                    key={itemKey}
                    style={{
                      animationDelay: Math.min(itemKey * 200, 2000) + 10 + "ms",
                      borderRadius: 15,
                      minHeight: 55,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex w-100">
                      <div className="me-1">
                        <img
                          src={
                            item.photo
                              ? item.photo
                              : "/images/items-place-holder.png"
                          }
                          width={60}
                          alt="photo items"
                          style={{ borderRadius: 10 }}
                        />
                      </div>
                      <div className="d-flex flex-column w-100">
                        <div className="ps-2 align-self-center w-100">
                          <h5 className="ps-1 mb-0 font-400 line-height-xs pt-1 font-14 text-hidden-1">
                            {item.label}
                          </h5>
                          <h4 className="ps-1 mb-0 font-11 mt-1">
                            {item.code}
                          </h4>
                          <h4 className="ps-1 mb-0 font-400 font-11 mt-n1">
                            Stock : {item.stock}
                          </h4>
                        </div>
                        <div className="d-flex w-100 gap-2 ps-2">
                          <button
                            className="btn btn-remove"
                            type="button"
                            onClick={() => handleRemoveSelect(item)}
                          >
                            <BiTrash />
                          </button>
                          <InputPlusMinus
                            onCallBack={(value: number | string) =>
                              handleSelect(item, value, item.select_unit)
                            }
                            value={item.qty}
                            styleWrapper={{ width: "100%" }}
                          />
                          <select
                            name=""
                            className="form-control"
                            style={{ borderRadius: 8 }}
                            onChange={(event) =>
                              handleSelect(item, item.qty, event.target.value)
                            }
                          >
                            {item.unit.map((unit, unitKey) => {
                              return (
                                <option key={unitKey} value={unit.uuid_unit}>
                                  {unit.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div
              className="card card-style slide-top mt-2"
              style={{ borderRadius: 10 }}
            >
              <div className="content mt-2">
                <div
                  className="form-field form-name mt-3 slide-top"
                  style={{ animationDelay: Math.min(5 * 200, 3000) + "ms" }}
                >
                  <label
                    htmlFor="c2"
                    className="contactMessageTextarea color-theme"
                  >
                    Attachments
                    <span>(optional)</span>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="c2"
                    className="form-control mb-0"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
                <div
                  className="form-field form-text mt-3 slide-top"
                  style={{ animationDelay: Math.min(6 * 200, 3000) + "ms" }}
                >
                  <label
                    htmlFor="c1"
                    className="contactMessageTextarea color-theme"
                  >
                    Keterangan:
                    <span>(optional)</span>
                  </label>
                  <Field
                    type="text"
                    name="keterangan"
                    id="c1"
                    as="textarea"
                    row={1}
                    className="round-small mb-0"
                    placeholder="Keterangan"
                    style={{ height: 80 }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    gap: 10,
                    position: "relative",
                    zIndex: 1,
                    animationDelay: Math.min(7 * 200, 3000) + "ms",
                  }}
                  className="d-flex mt-3 slide-top"
                >
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={handleCloseForm}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormItemsKeluar;
