/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import FormSelect from "../../../../../../../assets/components/form/formSelect";
import { useEffect, useState } from "react";
import { getListPeminjamanInventarisProyek } from "./interface";
import ModalKehilanganInventaris from "./modal";

const FormKehilagananInventaris = () => {
  const navigation = useNavigate();

  const { uuid: idProyek } = useParams();
  const [selectKaryawan, setSelectKaryawan] = useState<string>("");

  const [selectedInventaris, setSelectedInventaris] = useState<any | null>(
    null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);

  const [listPeminjaman, setListPeminjaman] = useState<any[]>([]);
  const [globalKey, setGlobalKey] = useState<number>(1);
  const [isRefresh, setIsRefresh] = useState<number>(0);

  useEffect(() => {
    if (selectKaryawan) {
      getListPeminjamanInventarisProyek(
        selectKaryawan,
        idProyek,
        setListPeminjaman
      );
      setGlobalKey((prev) => prev + 1);
      setSelectedInventaris(null);
    }
  }, [selectKaryawan, idProyek, isRefresh]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  return (
    <>
      <div
        className="card card-style mb-3 slide-top"
        style={{ borderRadius: 10 }}
      >
        <div className="content">
          <div className="form-field form-name slide-top mb-1">
            <label className="contactNameField color-theme">
              Tanggal Kehilangan
              <span>(required)</span>
            </label>
            <input
              type="text"
              defaultValue={moment().tz("Asia/Jakarta").format("LL")}
              readOnly
              className="round-small"
            />
          </div>
          <div className="form-field form-name slide-top mb-0">
            <label className="contactNameField color-theme">
              Karyawan
              <span>(required)</span>
            </label>
            <FormSelect
              urlSelect={`proyek/karyawan-proyek/${idProyek}`}
              name="karyawan_id"
              isFormik={false}
              className="round-small z-9"
              menuPlacement="top"
              onCallback={(value) => setSelectKaryawan(value)}
            />
          </div>
          <p className="mb-0 font-12">
            Silahkan pilih karyawan terlebih dahulu.
          </p>
        </div>
      </div>
      {selectKaryawan && listPeminjaman.length < 1 && (
        <div
          className="card card-style slide-top"
          style={{
            borderRadius: 10,
            animationDelay: Math.min(globalKey + 1 * 200, 1000) + "ms",
          }}
        >
          <div className="content">
            <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
              Tidak ada data peminjaman.
            </h5>
          </div>
        </div>
      )}
      {selectKaryawan && listPeminjaman.length > 0 && (
        <div
          className="card card-style slide-top"
          style={{
            borderRadius: 10,
            animationDelay: Math.min(globalKey + 1 * 200, 1000) + "ms",
          }}
        >
          <div className="content">
            <h6 className="font-700 mb-n1 color-highlight">Data</h6>
            <h4 className="pb-1">Peminjaman</h4>
            {listPeminjaman.map((peminjaman, peminjamanKey) => {
              return (
                <div
                  className={`slide-right shadow-m mt-2 mb-2 py-2 px-2 bg-theme ${
                    peminjamanKey !== 0 && "mt-3"
                  } `}
                  key={peminjamanKey}
                  style={{
                    animationDelay:
                      Math.min(globalKey + peminjamanKey * 200, 1000) + "ms",
                    borderRadius: 10,
                  }}
                >
                  <div
                    className="d-flex px-2 pb-2 pt-2 pe-3"
                    style={{ alignItems: "center", height: "100%" }}
                  >
                    <div
                      className="ps-2 align-self-center pe-1"
                      style={{ width: "78%" }}
                    >
                      <div className="d-flex flex-column">
                        <h5 className={`ps-1 mb-0 font-600 font-13`}>
                          {peminjaman.inventaris.name} x{" "}
                          {peminjaman.totalKurang}
                        </h5>
                        <span className="ps-1 mb-0 font-400 font-12 mt-n1">
                          {moment(new Date(peminjaman.created_at)).format("ll")}
                        </span>
                      </div>
                    </div>
                    <div className="ms-auto">
                      <button
                        className="btn btn-xs gradient-green shadow-bg shadow-bg-s text-start me-2"
                        onClick={() => {
                          setSelectedInventaris(peminjaman), handleOpen();
                        }}
                      >
                        <div className="bi bi-check-lg font-20"></div>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <button
        className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
        onClick={() =>
          navigation({
            search: `active-tab=tE7hyVeOrVPLfcVsVFNLnVVSwOXQQbDH`,
          })
        }
        style={{ zIndex: 999 }}
      >
        <i className="bi bi-x-lg" style={{ fontSize: 18 }}></i>
      </button>
      {isEnableModal && (
        <ModalKehilanganInventaris
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          selectedInventaris={selectedInventaris}
          setSelectedInventaris={setSelectedInventaris}
          setIsRefresh={setIsRefresh}
        />
      )}
    </>
  );
};

export default FormKehilagananInventaris;
