/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import {
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useFetchDailyPresentProyek } from "../pages/absensi/interface";
import {
  initialIzinPulangKaryawan,
  izinPulangKaryawanSchema,
  submitPresentSubmissionProyek,
} from "./interface";
import { useQueryClient } from "@tanstack/react-query";

interface ModalSubmissionProyekProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  isDataEdit?: any | null;
}

const ModalSubmissionProyek: FC<ModalSubmissionProyekProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  isDataEdit,
  ...props
}) => {
  const { uuid: idProyek } = useParams();
  const { data: dataIsAbsen } = useFetchDailyPresentProyek(idProyek,true);
  const queryClient = useQueryClient();

  const [LIST_KARYAWAN_PROYEK, setLIST_KARYAWAN_PROYEK] = useState<any>([]);

  const [modalDataKaryawan, setModalDataKaryawan] = useState<boolean>(false);
  const [selectedKaryawan, setSelectedKaryawan] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleSelectedKaryawan = (karyawan: any) => {
    setSelectedKaryawan({
      value: karyawan.uuid,
      label: karyawan.name,
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({
        queryKey: ["submission-present-proyek"],
      });
    }, 150);
  };

  useEffect(() => {
    if (dataIsAbsen) {
      const result = dataIsAbsen.data;
      setLIST_KARYAWAN_PROYEK(result.karyawan);
    }
  }, [dataIsAbsen]);

  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "90dvh",
        }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Pengajuan</h4>
          <Formik
            initialValues={
              isDataEdit
                ? {
                    karyawan: isDataEdit.karyawan_id,
                    keterangan: isDataEdit.note,
                  }
                : initialIzinPulangKaryawan
            }
            onSubmit={(values) => {
              if (isDataEdit) {
                values.karyawan = isDataEdit.karyawan_id;
              } else {
                values.karyawan = selectedKaryawan
                  ? selectedKaryawan.value
                  : "";
              }
              submitPresentSubmissionProyek(
                values,
                selectedFile,
                idProyek,
                handleCloseModal,
                isDataEdit && isDataEdit.uuid
              );
            }}
            validationSchema={izinPulangKaryawanSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="karyawan"
                    id="c1"
                    className="form-select rounded-xs"
                    readOnly
                    onClick={() => !isDataEdit && setModalDataKaryawan(true)}
                    onChange={() => {}}
                    value={
                      isDataEdit
                        ? isDataEdit.karyawan.name
                        : selectedKaryawan
                        ? selectedKaryawan.label
                        : "Silahkan Pilih"
                    }
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Karyawan
                  </label>
                  <span>(required)</span>
                </div>
                <div className="form-field form-text mt-3">
                  <label
                    htmlFor="c1"
                    className="contactMessageTextarea color-theme"
                  >
                    Keterangan:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="text"
                    name="keterangan"
                    id="c1"
                    as="textarea"
                    row={2}
                    className="round-small mb-0"
                    placeholder="Keterangan"
                  />
                  {errors.keterangan && touched.keterangan && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.keterangan as string}
                    </div>
                  )}
                </div>
                <p className="mb-0 font-12">
                  Silahkan masukan keterangan kamu kenapa pulang lebih awal.
                </p>

                <div className="form-field form-name mt-3">
                  <label
                    htmlFor="c2"
                    className="contactMessageTextarea color-theme"
                  >
                    FILE
                    <span>(optional)</span>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="c2"
                    className="form-control mb-0"
                    onChange={handleFileChange}
                  />
                </div>
                <p className="mb-0 font-12">
                  Silahkan gambar atau file untuk bukti lainya.
                </p>
                <div className="d-flex" style={{ gap: 10 }}>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "70%" }}
                    type="submit"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={modalDataKaryawan}
        onHide={() => setModalDataKaryawan(false)}
        dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
      >
        <div
          className="content"
          style={{ maxHeight: "75dvh", overflow: "auto" }}
        >
          <div className="d-flex pb-2">
            <div className="align-self-center">
              <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">
                Data
              </h5>
              <h4 className="font-22 font-800 mb-3">Karyawan</h4>
            </div>
            <div className="align-self-center ms-auto">
              <span
                className="icon icon-m"
                onClick={() => setModalDataKaryawan(false)}
              >
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </span>
            </div>
          </div>
          {LIST_KARYAWAN_PROYEK.lengt < 1 ? (
            <div
              className="bg-theme slide-right shadow-m mb-3 mx-1 py-4 px-3"
              style={{
                borderRadius: 15,
                animationDelay: Math.min(1 * 200, 1000) + "ms",
              }}
            >
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                Belum ada riwayat absen
              </h5>
            </div>
          ) : (
            LIST_KARYAWAN_PROYEK.map((karyawan: any, karyawanKey: number) => {
              return (
                <div
                  className={`slide-right ${
                    selectedKaryawan?.value === karyawan.uuid
                      ? "bg-blue-dark"
                      : "bg-theme"
                  } rounded-m shadow-bg shadow-bg-s ${
                    karyawanKey !== 0 && "mt-3"
                  } mb-3`}
                  key={karyawanKey}
                  style={{
                    height: 70,
                    animationDelay: Math.min(karyawanKey * 200, 1000) + "ms",
                    boxShadow:
                      "rgba(0,0,0,.03) 0 20px 25px -5px, rgba(0,0,0,.02) 0 10px 10px -5px",
                  }}
                  onClick={() => handleSelectedKaryawan(karyawan)}
                >
                  <div
                    className="d-flex px-2 pb-2 pt-2"
                    style={{ height: "100%", alignItems: "center" }}
                  >
                    <div className={`ps-2 align-self-center`}>
                      <h5
                        style={{ textTransform: "capitalize" }}
                        className={`ps-1 mb-0 line-height-xs pt-1 ${
                          selectedKaryawan?.value === karyawan.uuid &&
                          "color-white"
                        }`}
                      >
                        {karyawan.name}{" "}
                      </h5>
                      <h6
                        className={`ps-1 mb-0 mt-1 font-400 opacity-80 ${
                          selectedKaryawan?.value === karyawan.uuid &&
                          "color-white"
                        }`}
                      >
                        +{karyawan.no_hp}{" "}
                        <strong>({karyawan.jabatan.name})</strong>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalSubmissionProyek;
