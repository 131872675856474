import { API_CLIENT } from "../../../const";

export interface Option {
  value: string;
  label: string;
}

export const generateUniqueId = (): string => {
  const randomString = Math.random().toString(36).substring(7);
  return `css-slt-${randomString}`;
};

export const fetchOptionsFromServer = async (
  inputValue: string,
  urlSelect: string
) => {
  try {
    const response = await API_CLIENT.get(
      `${urlSelect}${inputValue ? "?query=" + inputValue : ""}`
    );
    const data = response;
    const dataResponse = data.data.data;
    return dataResponse;
  } catch (error) {
    console.error("Error fetching options:", error);
    return [];
  }
};

export const promiseOptions = (inputValue: string, urlSelect: string) =>
  new Promise<Option[]>((resolve) => {
    setTimeout(() => {
      resolve(fetchOptionsFromServer(inputValue, urlSelect));
    }, 1000);
  });
