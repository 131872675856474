/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../../../../../const";
import { toast } from "../../../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export interface iPeminjamanInventarisProyek {
  karyawan_id: string;
  inventaris_id: string;
  jumlah: number;
}

export const initialPeminjamanInventarisProyek: iPeminjamanInventarisProyek = {
  karyawan_id: "",
  inventaris_id: "",
  jumlah: 0,
};

export const peminjamanInventarisProyekSchema = Yup.object().shape({
  karyawan_id: Yup.string().required("Please insert a value"),
  inventaris_id: Yup.string().required("Please insert a value"),
  jumlah: Yup.number().min(1).required("Please insert a value"),
});

export const handleSubmitPeminjamanInventarisProyek = (
  values: iPeminjamanInventarisProyek,
  idProyek: string | undefined,
  handleCloseModal: () => void,
  uuid?: string | null
) => {
  const dataToSend = {
    ...values,
    idProyek: idProyek,
    uuid: uuid,
  };

  API_CLIENT.post("/proyek/peminjaman-inventaris", dataToSend)
    .then((response) => {
      const result = response.data;
        if (result.error) {
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        } else {
          toast.success("Success", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
          handleCloseModal();
        }
    })
    .catch((err) => console.log(err));
};

const fetchPeminjamanInvetarisProyek = async (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/peminjaman-inventaris?filter=${filter}&proyek_id=${proyek_id}&range=${range}`
  );
  return response.data;
};

export const useFetchPeminjamanInvetarisProyek = (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["peminjaman-inventaris"],
    queryFn: () => fetchPeminjamanInvetarisProyek(filter, proyek_id, dateRange),
  });
};