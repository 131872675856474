import { FC, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Calendar, DateRange } from "react-date-range";

import {
  handleSelectDateRange,
  handleSelectRange,
  iSelectionRange,
  monthList,
  selectionRange,
  yearRange,
} from "./interface";
import { CONFIG_APP, caesarEncrypt, textToNumber } from "../../../const";
import FormSelect from "../../../assets/components/form/formSelect";
// import { useNavigate } from "react-router-dom";
import { toast } from "../../../assets/components/toast";

interface FormLaporanProps {
  type?: string;
  isWeekly?: boolean;
}

const FormLaporan: FC<FormLaporanProps> = ({
  type = "absensi",
  isWeekly = false,
}) => {
  // const navigation = useNavigate();

  const [selectDate, setSelectDate] = useState<Date>(new Date());
  const [selectDateRange, setSelectDateRange] =
    useState<iSelectionRange>(selectionRange);

  const [selectProyek, setSelectProyek] = useState<string>("");
  const [selectMonth, setSelectMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectYear, setSelectYear] = useState<number>(
    new Date().getFullYear()
  );
  const [activeTab, setActiveTab] = useState<string>("daily");

  const handleGenerateReport = async () => {
    if (selectProyek === "") {
      return toast.error("Error", "Silahkan Pilih Proyek!!", {
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    let generateUrlSearch;
    let url;
    switch (type) {
      case "absensi":
        url = "absensi";
        break;
      case "pemasukan":
        url = "pemasukan";
        break;
      case "pengeluaran":
        url = "pengeluaran";
        break;
    }
    const key = new Uint8Array(32);
    const keySufix = encodeURIComponent(btoa(String.fromCharCode(...key)));

    const idProyek = caesarEncrypt(selectProyek, 20);
    const idProyekEncrypt = textToNumber(idProyek, keySufix);
    const encryptType = textToNumber(activeTab, keySufix);
    const zeusToken = localStorage.getItem("zeus-token");

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    if (activeTab === "daily" || activeTab === "weekly") {
      const dateToSend = new Date(selectDate).toLocaleDateString(
        "id-ID",
        options
      );
      const dateEncrypt = textToNumber(dateToSend, keySufix);
      generateUrlSearch = `?&type=${encryptType}&prefix=${dateEncrypt}&uuid=${idProyekEncrypt}&urlBack=proyek-laporan&keySufix=${keySufix}`;
    } else if (activeTab === "month") {
      const monthEncrypt = textToNumber(selectMonth.toString(), keySufix);
      const yearEncrypt = textToNumber(selectYear.toString(), keySufix);
      generateUrlSearch = `?&type=${encryptType}&prefix=${monthEncrypt}&yearPrefix=${yearEncrypt}&uuid=${idProyekEncrypt}&urlBack=proyek-laporan&keySufix=${keySufix}`;
    } else if (activeTab === "range") {
      const { startDate, endDate } = selectDateRange;
      const dateToStartSend = new Date(startDate).toLocaleDateString(
        "id-ID",
        options
      );
      const dateEndStartSend = new Date(endDate).toLocaleDateString(
        "id-ID",
        options
      );
      const dateStartEncrypt = textToNumber(dateToStartSend, keySufix);
      const dateEndEncrypt = textToNumber(dateEndStartSend, keySufix);

      generateUrlSearch = `?&type=${encryptType}&prefix=${dateStartEncrypt}&endPrefix=${dateEndEncrypt}&uuid=${idProyekEncrypt}&urlBack=proyek-laporan&keySufix=${keySufix}`;
    }
    window.location.href = `${CONFIG_APP["url-server"]}report/proyek/${url}/${generateUrlSearch}&zeusToken=${zeusToken}`;
    // navigation(`/laporan/view${generateUrlSearch}&zeusToken=${zeusToken}`);
  };

  return (
    <>
      <div className="tabs tabs-pill">
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k || "")}
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3 mt-3 tab-controls rounded-m"
          fill
        >
          <Tab eventKey="daily" title="Harian" tabClassName="font-12 rounded-m">
            <div
              style={{
                boxShadow: "0 5px 14px 0 rgba(0,0,0,.1)",
                borderRadius: 20,
              }}
              className="mb-2"
            >
              <Calendar
                date={selectDate}
                showDateDisplay={false}
                calendarFocus="backwards"
                onChange={(range) =>
                  handleSelectDateRange(range, setSelectDate)
                }
              />
            </div>
          </Tab>
          {isWeekly && (
            <Tab
              eventKey="weekly"
              title="Mingguan"
              tabClassName="font-12 rounded-m"
            >
              <div
                style={{
                  boxShadow: "0 5px 14px 0 rgba(0,0,0,.1)",
                  borderRadius: 20,
                }}
                className="mb-2"
              >
                <Calendar
                  date={selectDate}
                  showDateDisplay={false}
                  calendarFocus="backwards"
                  onChange={(range) =>
                    handleSelectDateRange(range, setSelectDate)
                  }
                />
              </div>
            </Tab>
          )}
          <Tab
            eventKey="month"
            title="Bulanan"
            tabClassName="font-12 rounded-m"
          >
            <div className="form-field form-name">
              <label className="contactNameField color-theme">Bulan</label>
              <select
                value={selectMonth}
                onChange={(event) =>
                  setSelectMonth(parseInt(event.target.value))
                }
                className="round-small form-select"
                style={{
                  padding: "0.475rem 2.25rem 0.475rem 0.75rem",
                  lineHeight: 1.8,
                }}
              >
                {monthList.map((month, monthKey) => {
                  return (
                    <option key={monthKey} value={monthKey + 1}>
                      {month}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-field form-name mt-3 mb-2">
              <label className="contactNameField color-theme">Tahun</label>
              <select
                value={selectYear}
                onChange={(event) =>
                  setSelectYear(parseInt(event.target.value))
                }
                className="round-small form-select"
                style={{
                  padding: "0.475rem 2.25rem 0.475rem 0.75rem",
                  lineHeight: 1.8,
                }}
              >
                {yearRange.map((year, yearKey) => {
                  return (
                    <option key={yearKey} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          </Tab>
          <Tab
            eventKey="range"
            title="Rentang"
            tabClassName="font-12 rounded-m"
          >
            <div
              style={{
                boxShadow: "0 5px 14px 0 rgba(0,0,0,.1)",
                borderRadius: 20,
              }}
              className="mb-2"
            >
              <DateRange
                ranges={[selectDateRange]}
                preventSnapRefocus={true}
                showDateDisplay={false}
                calendarFocus="backwards"
                onChange={(range) =>
                  handleSelectRange(range, setSelectDateRange)
                }
              />
            </div>
          </Tab>
        </Tabs>
        <div className="form-field form-name mt-3">
          <label className="contactNameField color-theme">Proyek</label>
          <FormSelect
            urlSelect="proyek/report/data-proyek"
            name="karyawan"
            className="round-small z-9"
            menuPlacement="top"
            isFormik={false}
            onCallback={(value: string) => setSelectProyek(value)}
          />
        </div>
        <button
          className="btn-full btn gradient-green shadow-bg shadow-bg-m w-100 mt-3"
          type="button"
          onClick={handleGenerateReport}
        >
          Buat Laporan
        </button>
      </div>
    </>
  );
};

export default FormLaporan;
