/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikErrors } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { iInitialValuesPenanggung } from "./interface";

const ModalContact = ({
  setModalContact,
  setFieldValue,
}: {
  setModalContact: Dispatch<SetStateAction<boolean>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<iInitialValuesPenanggung>>;
}) => {
  const [listContact, setListContact] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState(false);

  useEffect(() => {
    const getContact = async () => {
      const contactsString = localStorage.getItem("contacts");
      if (contactsString) {
        const contacts = JSON.parse(contactsString);
        setListContact(contacts);
        setTimeout(() => {
          setIsEnableModal(true);
          setTimeout(() => {
            setIsOpen(true);
          }, 150);
        }, 250);
      }
    };

    getContact();
  }, []);

  const handleCloseModal = () => {
    setIsEnableModal(false);
    setTimeout(() => {
      setIsOpen(false);
      setTimeout(() => {
        setModalContact(false);
      }, 150);
    }, 250);
  };

  const handleSelect = (phone: string) => {
    setFieldValue("no_tlp",phone);
    handleCloseModal();
  };

  return (
    <>
      {isEnableModal && (
        <Offcanvas
          show={isOpen}
          className="bg-theme"
          style={{
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            maxHeight: "90dvh",
          }}
          onHide={() => handleCloseModal()}
          placement="bottom"
        >
          <Offcanvas.Body>
            <h6 className="font-700 mb-n1 color-highlight">Contact</h6>
            <h4 className="pb-2">Daftar Contact</h4>
            <div className="row">
              {/* {listContact} */}
              {listContact.map((contact: any, contactKey: number) => {
                return contact.phones.map((phone: any, phoneKey: number) => {
                  return (
                    <div
                      className="col-md-12"
                      key={contactKey + phoneKey}
                      onClick={() => handleSelect(phone)}
                    >
                      <div className="list-group-item">
                        <div className="ps-1 d-flex flex-column">
                          <strong className="font-600">
                            {contact.displayName}
                          </strong>
                          <span>{phone}</span>
                        </div>
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default ModalContact;
