import { useEffect, useState } from "react";
import ModalManagementKaryawanProyek from "./modal";
import { iKaryawanProyekProps, useFetchKaryawanProyek } from "./interface";
import { BiEdit } from "react-icons/bi";
import ButtonDelete from "../../../../../../assets/components/ButtonDelete";
import NoData from "../../../../../../assets/components/noData";
import { useParams } from "react-router-dom";

const ManagementKaryawanProyek = () => {
  const { uuid: idProyek } = useParams();
  const { data } = useFetchKaryawanProyek(idProyek || null);

  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [dataKaryawan, setDataKaryawan] = useState<iKaryawanProyekProps[]>([]);

  const [isDataEdit, setIsDataEdit] = useState<iKaryawanProyekProps | null>(
    null
  );

  useEffect(() => {
    document.body.style.background = "";
    if (data) {
      if (!data.error) {
        const result = data.data;
        setDataKaryawan(result);
      }
    }
  }, [data]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };
  return (
    <>
      <div className="card card-style">
        <div className="content">
          <div className="d-flex">
            <div>
              <h6 className="font-700 mb-n1 color-highlight">Data</h6>
              <h2 className="pb-2">Karyawan</h2>
            </div>
          </div>
          <div
            className="list-group list-custom list-group-m rounded-m mt-3"
            style={{ overflow: "auto", maxHeight: "calc(100vh - 16.3em)" }}
          >
            {dataKaryawan.length < 1 ? (
              <NoData />
            ) : (
              dataKaryawan.map((karyawan, karyawanKey) => {
                return (
                  <div
                    className="list-group-item slide-right"
                    key={karyawanKey}
                    style={{
                      animationDelay: Math.min(karyawanKey * 200, 1000) + "ms",
                    }}
                  >
                    <div className="ps-1">
                      <strong className="font-600">{karyawan.name}</strong>
                      <div className="d-flex" style={{ gap: 10 }}>
                        <span className="font-600">+{karyawan.no_hp}</span>
                        {karyawan.jabatan && (
                          <span className="font-600">
                            ({karyawan.jabatan.name})
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="align-self-center ms-auto">
                      <button
                        className="btn gradient-blue btn-s text-start"
                        onClick={() => {
                          setIsDataEdit(karyawan), handleOpen();
                        }}
                      >
                        <BiEdit />
                      </button>
                      <ButtonDelete
                        className="btn gradient-red btn-s text-start ms-3"
                        url="proyek/karyawan"
                        KEY="proyek-karyawan"
                        values={karyawan.uuid}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <button
        className="btn btn-fab slide-top gradient-blue shadow-bg shadow-bg-xs"
        onClick={handleOpen}
      >
        <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
      </button>
      {isEnableModal && (
        <ModalManagementKaryawanProyek
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isDataEdit={isDataEdit}
        />
      )}
    </>
  );
};

export default ManagementKaryawanProyek;
