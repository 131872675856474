import { Field, Form, Formik } from "formik";
import {
  ChangeEvent,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
} from "react";
import { Offcanvas } from "react-bootstrap";
import FormSelect from "../../../assets/components/form/formSelect";
import {
  iInitialValuesManagement,
  iManagementProyekProps,
  initialValuesManagement,
  managementProyekSchema,
  useStoreManagementProyek,
} from "./interface";
import { toast } from "../../../assets/components/toast";
import { formatRibuan } from "../../../const";
import { useQueryClient } from "@tanstack/react-query";

interface ModalManagementProyekProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  isDataEdit?: iManagementProyekProps | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

const ModalManagementProyek: FC<ModalManagementProyekProps> = ({
  isOpen,
  setIsOpen,
  // isDataEdit = null,
  setIsEnableModal,
  ...props
}) => {
  const queryClient = useQueryClient();
  const storeData = useStoreManagementProyek();
  
  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["proyek-management"] });
    }, 150);
  };

  const handleSubmit = (
    values: iInitialValuesManagement,
    resetForm: () => void
  ) => {
    storeData.mutate(values, {
      onSuccess: (response) => {
        const result = response.data;
        if (result.error) {
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        } else {
          toast.success("Success", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
          resetForm();
          handleCloseModal();
        }
      },
      onError: (response) => {
        const result = response;
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      },
    });
  };

  return (
    <Offcanvas
      show={isOpen}
      className="bg-theme"
      style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
      onHide={() => handleCloseModal()}
      {...props}
      placement="bottom"
    >
      <Offcanvas.Body>
        <h6 className="font-700 mb-n1 color-highlight">Form</h6>
        <h4 className="pb-2">Management Proyek</h4>
        <Formik
          initialValues={initialValuesManagement}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={managementProyekSchema}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="form-custom mb-3 form-floating mt-3">
                <Field
                  type="text"
                  name="name"
                  id="c1"
                  className="form-control rounded-xs"
                  placeholder="Nama Proyek"
                />
                <label htmlFor="c1" className="color-theme">
                  Nama Proyek
                </label>
                {errors.name && touched.name && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                )}
                <span>(required)</span>
              </div>
              <div className="form-custom mb-3 form-floating mt-3">
                <Field
                  type="text"
                  name="nilai"
                  id="c1"
                  inputMode="numeric"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formatRibuan(event),
                      setFieldValue("nilai", event.target.value);
                  }}
                  className="form-control rounded-xs"
                  placeholder="Nama Proyek"
                />
                <label htmlFor="c1" className="color-theme">
                  Nilai Proyek
                </label>
                {errors.nilai && touched.nilai && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.nilai}
                  </div>
                )}
                <span>(required)</span>
              </div>
              <div className="form-field form-name mb-3 mt-3">
                <label className="contactNameField color-theme">
                  Penanggung Jawab
                  <span>(required)</span>
                </label>
                <FormSelect
                  urlSelect="proyek/management-penanggung-jawab"
                  name="karyawan"
                  error={errors}
                  touched={touched}
                  isMulti
                  className="round-small z-9"
                  menuPlacement="top"
                />
              </div>
              <div
                style={{
                  width: "100%",
                  gap: 10,
                  position: "relative",
                  zIndex: 1,
                }}
                className="d-flex mt-3"
              >
                <button
                  style={{ width: "30%" }}
                  type="button"
                  data-bs-dismiss="offcanvas"
                  className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                  onClick={handleCloseModal}
                >
                  Batal
                </button>
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                >
                  Simpan Data
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ModalManagementProyek;
