/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, Fragment, useEffect, useState } from "react";
import { handleNotifStatus, useFetchNotifications } from "./interface";
import { Offcanvas } from "react-bootstrap";
import moment from "moment-timezone";
import { useQueryClient } from "@tanstack/react-query";

interface NotificationsProps {
  profile: any;
}

const Notifications: FC<NotificationsProps> = ({ profile }) => {
  const queryClient = useQueryClient();
  const { data } = useFetchNotifications(profile && profile.uuid);

  const [totalNotification, setTotalNotification] = useState<number>(0);
  const [dataNotification, setDataNotification] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      const { not_read, data: notif } = data.data;
      setDataNotification(notif);
      setTotalNotification(not_read);
    }
  }, [data]);

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    }, 150);
  };

  return (
    <>
      <button
        className="btn bg-fade1-blue no-shadow color-blue-dark text-start btn-notifications me-4"
        onClick={() => setIsOpen(true)}
      >
        <i className="bi bi-bell font-18 font-800"></i>
        {totalNotification > 0 && (
          <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
            {totalNotification > 9 ? "9+" : totalNotification}
          </span>
        )}
      </button>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "calc(100dvh - 60px)",
        }}
        onHide={() => handleCloseModal()}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-2 color-highlight">Notifications</h6>
          {dataNotification.length > 0 &&
            dataNotification.map((notif, notifKey) => {
              const message = JSON.parse(notif.message);

              return (
                <Fragment key={notifKey}>
                  <div
                    className="d-flex"
                    style={{
                      animationDelay: Math.min(notifKey * 200, 1000) + "ms",
                    }}
                  >
                    <div className="align-self-center me-3">
                      <h4 className="font-14 mb-n1">{message.title}</h4>
                      <span className="mt-n2 opacity-50 font-11 ms-1">
                        {moment(new Date(notif.created_at))
                          .tz("Asia/Jakarta")
                          .fromNow()}
                      </span>
                      <p className="pb-1 mt-0 mb-n2 font-14 line-height-s opacity-80">
                        {message.body}
                      </p>
                    </div>
                  </div>
                  {message.data && message.data.bigPicture && (
                    <img
                      src={message.data.bigPicture}
                      alt={message.title}
                      className="img-fluid rounded-s mb-1 mt-3"
                    />
                  )}
                  <div className="d-flex mt-2 gap-2">
                    {notif.is_read === 0 && (
                      <button
                        className="btn-full btn gradient-blue shadow-bg shadow-bg-m w-100"
                        onClick={() =>
                          handleNotifStatus(notif.uuid, "read", queryClient)
                        }
                      >
                        Di Baca
                      </button>
                    )}
                    <button
                      className="btn-full btn gradient-red shadow-bg shadow-bg-m w-100"
                      onClick={() =>
                        handleNotifStatus(notif.uuid, "delete", queryClient)
                      }
                    >
                      Di Hapus
                    </button>
                  </div>
                  {dataNotification.length !== notifKey + 1 && (
                    <div className="divider my-3" />
                  )}
                </Fragment>
              );
            })}
          {dataNotification.length < 1 && (
            <>
              <div
                className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 15,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada Notifikasi
                </h5>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Notifications;
