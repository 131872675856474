import FormLaporanGeneral from "../../assets/components/formLaporan";
import Layout from "../../assets/components/layout";

const PageHistoryUser = () => {
  return (
    <Layout headerTitle="Riwayat">
      <div className="card card-style">
        <div className="content">
          <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
          <h2 className="font-700 pb-0">Izin</h2>
          <FormLaporanGeneral urlLaporan='izin-user' />
        </div>
      </div>
      <div className="card card-style">
        <div className="content">
          <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
          <h2 className="font-700 pb-0">Absensi</h2>
          <FormLaporanGeneral urlLaporan="absen-user" />
        </div>
      </div>
    </Layout>
  );
};

export default PageHistoryUser;
