/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../../../../const";
import { toast } from "../../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export const satuanQtyBangunan = [
  { value: "batang", label: "Batang" },
  { value: "balok", label: "Balok" },
  { value: "biji", label: "Biji" },
  { value: "buah", label: "Buah" },
  { value: "karung", label: "Karung" },
  { value: "kotak", label: "Kotak" },
  { value: "lembar", label: "Lembar" },
  { value: "liter", label: "Liter" },
  { value: "m2", label: "Meter Persegi" },
  { value: "m3", label: "Meter Kubik" },
  { value: "meter", label: "Meter" },
  { value: "ons", label: "Ons" },
  { value: "pak", label: "Pak" },
  { value: "pasang", label: "Pasang" },
  { value: "paket", label: "Paket" },
  { value: "roll", label: "Roll" },
  { value: "sachet", label: "Sachet" },
  { value: "set", label: "Set" },
  { value: "tube", label: "Tube" },
  { value: "unit", label: "Unit" },
  { value: "dus", label: "Dus" },
  { value: "rim", label: "Rim" },
  { value: "galon", label: "Galon" },
  { value: "potong", label: "Potong" },
  { value: "lbr", label: "Lembar" },
  { value: "slop", label: "Slop" },
  { value: "kardus", label: "Kardus" },
  { value: "tube", label: "Tube" },
  { value: "pail", label: "Pail" },
  { value: "jeriken", label: "Jeriken" },
  { value: "koli", label: "Koli" },
  { value: "tong", label: "Tong" },
  { value: "sak", label: "Sak" },
  { value: "batu", label: "Batu" },
  { value: "kubik", label: "Kubik" },
  { value: "kantong", label: "Kantong" },
  { value: "truk", label: "Truk" },
  { value: "tray", label: "Tray" },
  { value: "kawat", label: "Kawat" },
  { value: "kg", label: "Kilogram" },
  { value: "gram", label: "Gram" },
  { value: "botol", label: "Botol" },
  { value: "pasir", label: "Pasir" },
  { value: "box", label: "Box" },
  { value: "mangkok", label: "Mangkok" },
  { value: "stel", label: "Stel" },
  { value: "sekop", label: "Sekop" },
  { value: "pompa", label: "Pompa" },
];

export const typePembayaran = [
  { value: "cash", label: "Tunai" },
  { value: "credit_card", label: "Kartu Kredit" },
  { value: "debit_card", label: "Kartu Debit" },
  { value: "transfer", label: "Transfer Bank" },
  { value: "ewallet", label: "Dompet Elektronik" },
  { value: "gopay", label: "Gopay" },
  { value: "ovo", label: "OVO" },
  { value: "dana", label: "DANA" },
  { value: "shopeepay", label: "ShopeePay" },
  { value: "linkaja", label: "LinkAja" },
  { value: "bri_epay", label: "BRI ePay" },
  { value: "bca_klikpay", label: "BCA KlikPay" },
  { value: "mandiri_ecash", label: "Mandiri eCash" },
  { value: "kredivo", label: "Kredivo" },
  { value: "alfamart", label: "Pembayaran di Alfamart" },
  { value: "indomaret", label: "Pembayaran di Indomaret" },
  { value: "ovo_link", label: "OVO Link" },
  { value: "gopay_link", label: "Gopay Link" },
  { value: "sakuku", label: "Sakuku" },
  { value: "xl_tunai", label: "XL Tunai" },
  { value: "tcash", label: "TCash" },
  { value: "akulaku", label: "Akulaku" },
  { value: "bni_tapcash", label: "BNI TapCash" },
  { value: "cimb_clicks", label: "CIMB Clicks" },
  { value: "maybank_m2u", label: "Maybank M2U" },
  { value: "ovo_paylater", label: "OVO PayLater" },
];

export const cashFlowFilter = [
  {
    value: "today",
    label: "Hari Ini",
  },
  {
    value: "weeks",
    label: "Mingu Ini",
  },
  {
    value: "month",
    label: "Bulan Ini",
  },
  {
    value: "year",
    label: "Tahun Ini",
  },
  {
    value: "range",
    label: "Range Tanggal",
  },
];

export interface iInitialValuesIn {
  pengirim: string;
  jumlah: string;
  tipe_pembayaran: string;
  penerima: string;
  informasi: string;
  date_received: string;
  no_cek: string;
  bank_name: string;
  date_disbursement: string;
}

export const initialValuesIn: iInitialValuesIn = {
  pengirim: "",
  jumlah: "",
  tipe_pembayaran: "",
  penerima: "",
  informasi: "",
  date_received: "",
  no_cek: "",
  bank_name: "",
  date_disbursement: "",
};

export const cashFlowInSchema = Yup.object().shape({
  pengirim: Yup.string().required("Please insert a value."),
  jumlah: Yup.string().required("Please insert a value."),
  penerima: Yup.string().required("Please insert a value."),
  tipe_pembayaran: Yup.string().required("Please insert a value."),
  informasi: Yup.string().test(
    "payment-validation",
    "Please insert a value.",
    function (value) {
      return !(this.parent.tipe_pembayaran !== "cash" && this.parent.tipe_pembayaran !== "Cek, BG/giro" && !value);
    }
  ),
  date_received: Yup.string().test(
    "payment-validation",
    "Please insert a value.",
    function (value) {
      return !(this.parent.tipe_pembayaran === "Cek, BG/giro" && !value);
    }
  ),
  no_cek: Yup.string().test(
    "payment-validation",
    "Please insert a value.",
    function (value) {
      return !(this.parent.tipe_pembayaran === "Cek, BG/giro" && !value);
    }
  ),
  bank_name: Yup.string().test(
    "payment-validation",
    "Please insert a value.",
    function (value) {
      return !(this.parent.tipe_pembayaran === "Cek, BG/giro" && !value);
    }
  ),
  date_disbursement: Yup.string().test(
    "payment-validation",
    "Please insert a value.",
    function (value) {
      return !(this.parent.tipe_pembayaran === "Cek, BG/giro" && !value);
    }
  ),
});

export const submitStoreCashFlowIn = async (
  values: iInitialValuesIn,
  selectedFile: File | null,
  proyek_id: string | undefined,
  handleCloseModal: () => void
) => {
  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("pengirim", values.pengirim);
  dataToSend.append("penerima", values.penerima);
  dataToSend.append("jumlah", values.jumlah);
  dataToSend.append("tipe_pembayaran", values.tipe_pembayaran);
  dataToSend.append("informasi", values.informasi);
  dataToSend.append("date_received", values.date_received);
  dataToSend.append("no_cek", values.no_cek);
  dataToSend.append("bank_name", values.bank_name);
  dataToSend.append("date_disbursement", values.date_disbursement);
  dataToSend.append("proyek_id", proyek_id || "");

  API_CLIENT.post("/proyek/pemasukan", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
      }
    })
    .catch((err) => console.log(err));
};

const fetchPemasukanProyek = async (
  filter: string,
  proyek_id: string,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/pemasukan?filter=${filter}&proyek_id=${proyek_id}&dateRange=${range}`
  );
  return response.data;
};

export const useFetchPemasukanProyek = (
  filter: string,
  proyek_id: string,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["cashflow-in"],
    queryFn: () => fetchPemasukanProyek(filter, proyek_id, dateRange),
  });
};

export interface iInitialValuesOut {
  name: string;
  qty: number;
  satuan: string;
  ppn: string;
  harga: string;
  tipe_pembayaran: string;
  informasi: string;
  penerima: string;
  supplier: string;
}

export const initialValuesOut: iInitialValuesOut = {
  name: "",
  qty: 0,
  satuan: "",
  ppn: "",
  harga: "",
  tipe_pembayaran: "",
  informasi: "",
  penerima: "",
  supplier: "",
};

export const cashFlowOutSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  satuan: Yup.string().required("Please insert a value."),
  penerima: Yup.string().required("Please insert a value."),
  supplier: Yup.string().required("Please insert a value."),
  tipe_pembayaran: Yup.string().required("Please insert a value."),
  informasi: Yup.string().test(
    "payment-validation",
    "Please insert a value.",
    function (value) {
      return !(this.parent.tipe_pembayaran !== "cash" && !value);
    }
  ),
  qty: Yup.number().min(1).required("Please insert a value."),
  harga: Yup.string().min(1).required("Please insert a value."),
});

export const submitStoreCashFlowOut = async (
  values: iInitialValuesOut,
  selectedFile: File | null,
  proyek_id: string | undefined,
  handleCloseModal: () => void
) => {
  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("name", values.name);
  dataToSend.append("satuan", values.satuan);
  dataToSend.append("penerima", values.penerima);
  dataToSend.append("tipe_pembayaran", values.tipe_pembayaran);
  dataToSend.append("informasi", values.informasi);
  dataToSend.append("qty", values.qty.toString());
  dataToSend.append("harga", values.harga);
  dataToSend.append("ppn", values.ppn);
  dataToSend.append("supplier", values.supplier);
  dataToSend.append("proyek_id", proyek_id || "");

  API_CLIENT.post("/proyek/pengeluaran", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      handleCloseModal();
    })
    .catch((err) => console.log(err));
};

const fetchPengeluaranProyek = async (
  filter: string,
  proyek_id: string,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/pengeluaran?filter=${filter}&proyek_id=${proyek_id}&dateRange=${range}`
  );
  return response.data;
};

export const useFetchPengeluaranProyek = (
  filter: string,
  proyek_id: string,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["cashflow-out", dateRange],
    queryFn: () => fetchPengeluaranProyek(filter, proyek_id, dateRange),
  });
};
