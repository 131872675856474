/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useProfile } from "../../../const";
import { useNavigate } from "react-router-dom";
import { menuFooter } from "./interface";

const Footer = () => {
  const navigation = useNavigate();
  const { data } = useProfile();

  const [profile, setProfile] = useState<any | null>({});

  useEffect(() => {
    if (data) {
      setProfile(data.user);
    }
  }, [data]);

  return (
    profile.roles === "karyawan" && (
      <div id="footer-bar" className="footer-bar">
        {menuFooter.map((menu, keyMenu) => {
          return menu.type === "span" ? (
            <span
              key={keyMenu}
              className={`menu-footer ${
                menu.key === "absen" ? "active-nav" : ""
              }`}
              style={{ width: menu.size }}
              onClick={() => navigation(menu.link)}
            >
              <i className={menu.icons}></i>
              <span>{menu.title}</span>
            </span>
          ) : (
            <a
              href={`${menu.link}`}
              key={keyMenu}
              className={`menu-footer ${
                menu.key === "absen" ? "active-nav" : ""
              }`}
              style={{ width: menu.size }}
            >
              <i className={menu.icons}></i>
              <span>{menu.title}</span>
            </a>
          );
        })}
      </div>
    )
  );
};

export default Footer;
