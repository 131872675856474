import { createPortal } from "react-dom";
import Layout from "../../../../assets/components/layout";
// import FooterManagementProyek from "./footer";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import NavBarProjectManagement from "./navbar";

import DashboardManagementProyek from "./pages/dashboard";
import AbsensiManagementProyek from "./pages/absensi";
import CashFlowPage from "./pages/cashFlow";
import ManagementKaryawanProyek from "./pages/karyawan";
import SettingManagementProyek from "./pages/setting";
import ProyekSubmissionPage from "./submissions";
import PageInventarisProyek from "./pages/inventaris";
import PeminjamanInventarisProyek from "./pages/inventaris/peminjaman";
import PengembalianInventarisProyek from "./pages/inventaris/pengembalian";
import FormPengembalianInventarisProyek from "./pages/inventaris/pengembalian/form";
import PageKehilanganInventarisProyek from "./pages/inventaris/kehilangan";
import FormKehilagananInventaris from "./pages/inventaris/kehilangan/form";
import ProductWarehouseProyek from "../../warehouse/products";
import PagePemasukanItems from "../../warehouse/pemasukan";
import PagePengeluaranItems from "../../warehouse/pengeluaran";
import PageAdjustmentItems from "../../warehouse/adjustment";
import PageReportItems from "../../warehouse/report";

const ManagementProyekDetail = () => {
  const location = useLocation();
  // const navigation = useNavigate();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const [renderComponent, setRenderComponent] = useState(
    <DashboardManagementProyek />
  );
  const [titleHeader, setTitleHeader] = useState("ePresensi");

  useEffect(() => {
    switch (queryParams.get("active-tab")) {
      case "mdx2yuBQwJewIUPFvileaPwwhpgTsk30":
        setRenderComponent(<DashboardManagementProyek />);
        setTitleHeader("Dashboard");
        break;
      case "ekp1qN8fuk6jMo1baAIXRGzLXT768z4R":
        setRenderComponent(<ManagementKaryawanProyek />);
        setTitleHeader("Data Karyawan");
        break;
      case "rnwE2irQvfTfJfCFHM748ur0IveIjzj8":
        setRenderComponent(<AbsensiManagementProyek />);
        setTitleHeader("Absensi");
        break;
      case "XE1IHZUQmu8YxqcdAOqXVJYLHwHh4VoD":
        setRenderComponent(<CashFlowPage />);
        setTitleHeader("History Pemasukan");
        break;
      case "COapq7bGgP5yxdeGs6Qy24TGrpBQz0ju":
        setRenderComponent(<CashFlowPage isCashIn={false} />);
        setTitleHeader("History Pengeluaran");
        break;
      case "Y4d0mYANLfdtEKRtpqYeQoNAfKUHdByh":
        setRenderComponent(<SettingManagementProyek />);
        setTitleHeader("Pengaturan");
        break;
      case "i9EmKc2aNuqtfhrJruY4q6QxB2lzlOZF":
        setRenderComponent(<ProyekSubmissionPage />);
        setTitleHeader("Pengajuan");
        break;
      case "LUUVRSPqeP81IFyq4lg0tuqHu7R9tBMa":
        setRenderComponent(<PageInventarisProyek />);
        setTitleHeader("Inventaris");
        break;
      case "81EFkyWtHP3kxxXYnLxeABiqdpPjBbz8":
        setRenderComponent(<PeminjamanInventarisProyek />);
        setTitleHeader("Peminjaman");
        break;
      case "ou3oBY9t7QhG9eydxVOts7KIobN19iqh":
        setRenderComponent(<PengembalianInventarisProyek />);
        setTitleHeader("Pengembalian");
        break;
      case "F4lwU0vLAl0AgCNIpdyikjvxwa9JmGpA":
        setRenderComponent(<FormPengembalianInventarisProyek />);
        setTitleHeader("Pengembalian");
        break;
      case "tE7hyVeOrVPLfcVsVFNLnVVSwOXQQbDH":
        setRenderComponent(<PageKehilanganInventarisProyek />);
        setTitleHeader("Kehilangan");
        break;
      case "rhfJs0DYbyZkN13H1LxpoWFjwHJQaj0r":
        setRenderComponent(<FormKehilagananInventaris />);
        setTitleHeader("Kehilangan");
        break;
      case "ThMxIp82SMbf1Zv9vi2q29pRvv2zEW6W":
        setRenderComponent(<ProductWarehouseProyek />);
        setTitleHeader("Items");
        break;
      case "dXVMhBxW2BQaECFvqv1Zjqh3jOeCL2US":
        setRenderComponent(<PagePemasukanItems />);
        setTitleHeader("Items Masuk");
        break;
      case "GMFl9SCVbpso38UejtFKESTnJ4rdt2Ia":
        setRenderComponent(<PagePengeluaranItems />);
        setTitleHeader("Items Keluar");
        break;
      case "xV6vQXLwi0mM9AgnuM0KBdYs4Msvgr8B":
        setRenderComponent(<PageAdjustmentItems />);
        setTitleHeader("Penyesuaian Items");
        break;
      case "QMl2risMCfT69EbLbE7Tg9pkxkgKj3vp":
        setRenderComponent(<PageReportItems />);
        setTitleHeader("Laporan Items");
        break;
      default:
        setRenderComponent(<DashboardManagementProyek />);
        setTitleHeader("Dashboard");
        break;
    }
  }, [queryParams]);

  return (
    <Layout
      urlBack
      urlBackTarget="/proyek-management"
      headerTitle={titleHeader}
      // rightChildren={
      //   <>
      //     <span onClick={() =>navigation({
      //       search: 'active-tab=Y4d0mYANLfdtEKRtpqYeQoNAfKUHdByh'
      //     })}>
      //       <i className="bi bi-gear-fill font-16"></i>
      //     </span>
      //   </>
      // }
    >
      {renderComponent}
      {/* {createPortal(<FooterManagementProyek />, document.body)} */}
      {createPortal(<NavBarProjectManagement />, document.body)}
    </Layout>
  );
};

export default ManagementProyekDetail;
