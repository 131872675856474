/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from "react";
import { API_CLIENT } from "../../../../../../../const";
import { toast } from "../../../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";
import * as Yup from "yup";

export interface iPengembalianInventarisProyek {
  karyawan_id: string;
  inventaris_id: string;
  jumlah: number;
}

export const initialPengembalianInventarisProyek: iPengembalianInventarisProyek = {
  karyawan_id: "",
  inventaris_id: "",
  jumlah: 0,
};

export const pengembalianInventarisProyekSchema = Yup.object().shape({
  karyawan_id: Yup.string().required("Please insert a value"),
  inventaris_id: Yup.string().required("Please insert a value"),
  jumlah: Yup.number().min(1).required("Please insert a value"),
});

export const getListPeminjamanInventarisProyek = (
  idKaryawan: string,
  idProyek: string | undefined,
  setListPeminjaman: Dispatch<SetStateAction<any[]>>
) => {
  const dataToSend = {
    idKaryawan: idKaryawan,
    idProyek: idProyek,
  };
  API_CLIENT.post("/proyek/pengembalian-list-inventaris", dataToSend)
    .then((response) => {
      const result = response.data;
      setListPeminjaman(result.data);
    })
    .catch((err) => console.log(err));
};

export const handleSubmitPengembalianBarang = (
  selectedInventaris: any,
  idProyek: string | undefined,
  setIsRefresh: Dispatch<SetStateAction<number>>
) => {
  const dataToSend = {
    data: JSON.stringify(selectedInventaris),
    idProyek: idProyek,
  };

  API_CLIENT.post("/proyek/pengembalian-inventaris", dataToSend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        setIsRefresh((prev) => prev + 1);
      }
    })
    .catch((err) => console.log(err));
};

const fetchPengembalianInvetarisProyek = async (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/pengembalian-inventaris?filter=${filter}&proyek_id=${proyek_id}&range=${range}`
  );
  return response.data;
};

export const useFetchPengembalianInvetarisProyek = (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["pengembalian-inventaris"],
    queryFn: () =>
      fetchPengembalianInvetarisProyek(filter, proyek_id, dateRange),
  });
};

export const handleUpdatePengembalianBarang = (
  selectedInventaris: any,
  handleCloseModal: () => void
) => {

  API_CLIENT.post("/proyek/update-pengembalian-inventaris", selectedInventaris)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
      }
    })
    .catch((err) => console.log(err));
};