/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import {
  ChangeEvent,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { formatRibuan, toThousand } from "../../../../../../const";
import {
  handleSubmitInventarisProyek,
  iInventarisProyek,
  initialInventarisProyek,
  inventarisProyekSchema,
} from "./interface";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface ModalInventarisProyekProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  isDataEdit?: any | null;
}

const ModalInventarisProyek: FC<ModalInventarisProyekProps> = ({
  isOpen,
  setIsOpen,
  isDataEdit = null,
  setIsEnableModal,
  ...props
}) => {
  const { uuid: idProyek } = useParams();
  const queryClient = useQueryClient();

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["inventaris-proyek"] });
    }, 150);
  };
  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Inventaris</h4>
          <Formik
            initialValues={
              isDataEdit
                ? ({
                    ...isDataEdit,
                    nilai: toThousand(isDataEdit.nilai),
                  } as iInventarisProyek)
                : initialInventarisProyek
            }
            onSubmit={(values: iInventarisProyek) => {
              handleSubmitInventarisProyek(
                values,
                idProyek,
                handleCloseModal,
                isDataEdit ? isDataEdit.uuid : null
              );
            }}
            validationSchema={inventarisProyekSchema}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="form-field form-name mt-3">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Nama Barang:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="round-small mb-0"
                    placeholder="Helm..."
                  />
                </div>
                {errors.name && touched.name && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                )}
                <div className="form-field form-name mt-3">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Nilai Satuan Barang:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="text"
                    name="nilai"
                    className="round-small mb-0"
                    placeholder="35.000.000"
                    inputMode="numeric"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formatRibuan(event),
                        setFieldValue("nilai", event.target.value);
                    }}
                  />
                </div>
                {errors.nilai && touched.nilai && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.nilai}
                  </div>
                )}
                <div className="form-field form-name mt-3">
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Stock Awal:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="number"
                    name="stock"
                    className="round-small mb-0"
                    placeholder="35"
                  />
                </div>
                {errors.stock && touched.stock && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.stock}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    gap: 10,
                    position: "relative",
                    zIndex: 1,
                  }}
                  className="d-flex mt-3"
                >
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalInventarisProyek;
