/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../const";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface iManagementProyekProps {
  id: number;
  karyawan_count: number;
  uuid: string;
  name: string;
  nilai: string;
  karyawan: string[];
  penanggun_jawab: penanggunJawab[];
  created_at: string;
  updated_at: string;
}

interface penanggunJawab {
  name: string;
  detail: {
    name: string;
  };
}

export interface iManagementFetch {
  message: string;
  error: boolean;
  data: iManagementProyekProps[];
}

export interface iInitialValuesManagement {
  name: string;
  nilai: string;
  karyawan: any[];
}

export const initialValuesManagement: iInitialValuesManagement = {
  name: "",
  nilai: "",
  karyawan: [],
};

export const managementProyekSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value"),
  nilai: Yup.string().required("Please insert a value"),
  karyawan: Yup.array().min(1, "Please insert at least one value"),
});

const fetchManagementProyek = async () => {
  const response = await API_CLIENT.get<iManagementFetch>("/proyek/management");
  return response.data;
};

export const useFetchManagementProyek = () => {
  return useQuery({
    queryKey: ["proyek-management"],
    queryFn: fetchManagementProyek,
  });
};

export const useStoreManagementProyek = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData: iInitialValuesManagement) => {
      return API_CLIENT.post("/proyek/management", newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["proyek-management"] });
    },
  });
};
