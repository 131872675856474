import { FC, LegacyRef } from "react";
import Webcam from "react-webcam";

const widthVideo = window.innerWidth * 2;
const videoConstraints = {
  width: widthVideo - 30,
  height: window.innerHeight / 1.5,
  facingMode: "user",
  whiteBalance: "continuous",
  zoom: 0,
  focusDepth: 0,
  autoFocus: "continuous",
  flashMode: "on",
  ratio: "cover",
};

interface CameraContainerProps {
  webcamRef: LegacyRef<Webcam>;
}

const CameraContainer: FC<CameraContainerProps> = ({ webcamRef }) => {
  return (
    <>
      <Webcam
        ref={webcamRef}
        audio={false}
        height={100 + "%"}
        width={100 + "%"}
        // height={window.innerHeight}
        // width={widthVideo}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        mirrored={true}
      />
    </>
  );
};

export default CameraContainer;
