/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from "react";

export const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

export interface iSelectionRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const handleSelectDateRange = (
  date: any,
  setSelectDateRange: Dispatch<SetStateAction<Date>>
) => {
  setSelectDateRange(date);
};

const startYear = 2020;
const currentYear = new Date().getFullYear();

// Membuat array dari tahun startYear hingga currentYear
export const yearRange = Array.from(
  { length: currentYear - startYear + 1 },
  (_, index) => startYear + index
);

export const handleSelectRange = (ranges: any, setSelectDateRange: Dispatch<SetStateAction<iSelectionRange>>) => {
  const { startDate, endDate } = ranges.selection;
  setSelectDateRange({ startDate, endDate, key: "selection" });
};