/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Layout from "../../../assets/components/layout";
import { useFetchPenanggungJawabProyek } from "./interface";
import ButtonDelete from "../../../assets/components/ButtonDelete";
import { BiEdit } from "react-icons/bi";
import ModalPenanggungJawabProyek from "./modalPenanggungJawab";
import NoData from "../../../assets/components/noData";

const KaryawanProyekPage = () => {
  const { data } = useFetchPenanggungJawabProyek();

  const [datPenanggungJawab, setDatPenanggungJawab] = useState<any[]>([]);

  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  useEffect(() => {
    if (data) {
      if (!data.error) {
        const result = data.data;
        setDatPenanggungJawab(result);
      }
    }
  }, [data]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  return (
    <Layout noPaddingBottom>
      <div className="card card-style">
        <div className="content">
          <div className="d-flex">
            <div>
              <h6 className="font-700 mb-n1 color-highlight">Data</h6>
              <h2 className="pb-2">Penanggung Jawab</h2>
            </div>
            <div className="align-self-center ms-auto">
              <button
                className="btn btn-xs gradient-blue shadow-bg shadow-bg-xs"
                onClick={() => {
                  setIsDataEdit(null), handleOpen();
                }}
              >
                <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
              </button>
            </div>
          </div>
          <div
            className="list-group list-custom list-group-m rounded-m mt-3"
            style={{ overflow: "auto", maxHeight: "calc(100vh - 16.3em)" }}
          >
            {datPenanggungJawab.length < 1 ? (
              <NoData />
            ) : (
              datPenanggungJawab.map((karyawan, karyawanKey) => {
                return (
                  <div className="list-group-item" key={karyawanKey}>
                    <div className="ps-1">
                      <strong className="font-600">{karyawan.name}</strong>
                      <span className="font-600" inputMode="numeric">
                        {karyawan.no_tlp}
                      </span>
                    </div>
                    <div className="align-self-center ms-auto">
                      <button
                        className="btn gradient-blue btn-s text-start"
                        onClick={() => {
                          setIsDataEdit(karyawan), handleOpen();
                        }}
                      >
                        <BiEdit />
                      </button>
                      <ButtonDelete
                        className="btn gradient-red btn-s text-start ms-3"
                        url="proyek/penanggung-jawab"
                        KEY="proyek-penanggung-jawab"
                        values={karyawan.uuid}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {isEnableModal && (
        <ModalPenanggungJawabProyek
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isDataEdit={isDataEdit}
        />
      )}
    </Layout>
  );
};

export default KaryawanProyekPage;
