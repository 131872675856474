import { Field, Form, Formik } from "formik";
import Layout from "../../assets/components/layout";
import { TimePicker } from "react-ios-time-picker";
import {
  iInitialValuesSettingProyek,
  initialValuesSettingProyek,
  settingProyekProps,
  useFetchSettingProyek,
  useStoreUpdateSettingProyek,
} from "./interface";
import { ChangeEvent, useEffect, useState } from "react";
import Preloader from "../../assets/components/preloader";
import { formatRibuan, generatePostEncrypt } from "../../const";
import { toast } from "../../assets/components/toast";

const PageSettingProyek = () => {
  const { data } = useFetchSettingProyek();
  const updateData = useStoreUpdateSettingProyek();

  const [dataSetting, setDataSetting] = useState<
    settingProyekProps | iInitialValuesSettingProyek
  >(initialValuesSettingProyek);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = (values: iInitialValuesSettingProyek) => {
    updateData.mutate(
      {
        newData: values,
        id: generatePostEncrypt((dataSetting as settingProyekProps).uuid || ""),
      },
      {
        onSuccess: (response) => {
          const result = response.data;
          if (result.error) {
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          } else {
            toast.success("Success", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          }
        },
        onError: (response) => {
          const result = response;
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      if (data.error) {
        // setIsRestirected(true);
      } else {
        setDataSetting(data.data);
      }
      setIsLoading(false);
    }
  }, [data]);
  return (
    <Layout>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="card card-style">
          <div className="content">
            <h6 className="font-700 mb-n1 color-highlight">Pengaturan</h6>
            <h1 className="pb-2">Proyek</h1>
            <div>
              <Formik
                initialValues={dataSetting}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <TimePicker
                        onChange={(values: string) =>
                          setFieldValue("jam_masuk", values)
                        }
                        value={values.jam_masuk}
                        cellHeight={55}
                      />
                      <label className="color-theme">Jam Masuk</label>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <Field
                        type="number"
                        name="toleransi_masuk"
                        id="c1"
                        className="form-control rounded-xs"
                        placeholder="Batas Toleransi Masuk"
                      />
                      <label htmlFor="c1" className="color-theme">
                        Batas Toleransi Masuk (Menit)
                      </label>
                      <span>(required)</span>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <Field
                        type="text"
                        name="potongan_set_hari"
                        inputMode="numeric"
                        id="c1"
                        className="form-control rounded-xs"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          formatRibuan(event),
                            setFieldValue("potongan_set_hari", event.target.value);
                        }}
                        placeholder="Potongan Setengah Hari"
                      />
                      <label htmlFor="c1" className="color-theme">
                        Potongan Setengah Hari
                      </label>
                      <span>(required)</span>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <TimePicker
                        onChange={(values: string) =>
                          setFieldValue("jam_keluar", values)
                        }
                        value={values.jam_keluar}
                        cellHeight={55}
                      />
                      <label className="color-theme">Jam Keluar</label>
                    </div>
                    <button
                      className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                      style={{ width: "100%" }}
                      type="submit"
                    >
                      Simpan Data
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default PageSettingProyek;
