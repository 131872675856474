/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import {
  dataRowJabatan,
  iInitialValuesJabatan,
  initialValuesJabatan,
  jabatanSchema,
  useStoreJabatan,
  useStoreUpdateJabatan,
} from "./interface";
import { toast } from "../../assets/components/toast";
import { generatePostEncrypt } from "../../const";

interface ModalJabatanProps {
  modalStatus: string;
  isProyek: boolean;
  modalEditData: dataRowJabatan | null;
}

const ModalJabatan: FC<ModalJabatanProps> = ({
  modalStatus,
  modalEditData,
  isProyek,
}) => {
  const storeData = useStoreJabatan(isProyek);
  const updateData = useStoreUpdateJabatan(isProyek);

  const [initialForm, setInitialForm] =
    useState<iInitialValuesJabatan>(initialValuesJabatan);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (modalStatus !== "create") {
      setInitialForm({ name: modalEditData?.name || "" });
      setIsLoading(true);
    } else {
      setInitialForm(initialValuesJabatan);
      setIsLoading(true);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  }, [modalStatus, modalEditData]);

  const handleSubmit = (values: any, resetForm: any) => {
    values.isProyek = isProyek;
    if (modalStatus === "create") {
      storeData.mutate(values, {
        onSuccess: (response) => {
          const result = response.data;
          if (result.error) {
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          } else {
            toast.success("Success", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          }
          resetForm();
        },
        onError: (response) => {
          const result = response;
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        },
      });
    } else {
      updateData.mutate(
        { newData: values, id: generatePostEncrypt(modalEditData?.uuid || "") },
        {
          onSuccess: (response) => {
            const result = response.data;
            if (result.error) {
              toast.error("Error", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            } else {
              toast.success("Success", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            }
            resetForm();
          },
          onError: (response) => {
            const result = response;
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          },
        }
      );
    }
  };

  return (
    <div
      id="modal-user"
      style={{ width: "92vw", marginTop: -140 }}
      className="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme"
    >
      {!isLoading && (
        <Formik
          initialValues={initialForm}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={jabatanSchema}
        >
          {() => (
            <Form>
              <div className="content">
                <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">
                  {modalStatus === "create" ? "Tambah" : "Edit"}
                </h5>
                <h1 className="font-24 font-800 mb-3">Data Jabatan</h1>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="name"
                    id="c1"
                    className="form-control rounded-xs"
                    placeholder="Nama Jabatan"
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Jabatan
                  </label>
                  <span>(required)</span>
                </div>
                <button
                  data-bs-dismiss="offcanvas"
                  className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                  style={{ width: "100%" }}
                  type="submit"
                >
                  Simpan Data
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ModalJabatan;
