import {
  Circle,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Layout from "../../assets/components/layout";
import { useEffect, useState } from "react";
import { LatLngTuple } from "leaflet";
import ModalAbsen from "./modalAbsen";
import { calculateDistance, useFetchDailyPresent } from "./interface";
import Preloader from "../../assets/components/preloader";
import { settingAbsensiProps } from "../settingAbsensi/interface";
import SubmissionModal from "./submission";
import { getLocation, markerIcon } from "../../const";
import moment from "moment-timezone";

const AbsensiPage = () => {
  const { data } = useFetchDailyPresent();
  const [location, setLocation] = useState<LatLngTuple | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenSubmission, setIsOpenSubmission] = useState<boolean>(false);

  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isAbsen, setIsAbsen] = useState<boolean>(true);
  const [inRadius, setInRadius] = useState<boolean>(false);
  const [locationPresent, setLocationPresent] = useState<number[]>([]);

  const [isStatusPresent, setIsStatusPresent] = useState<number>(0);
  const [isStatusAbsen, setIsStatusAbsen] = useState<string>("");
  const [settingAbsensi, setSettingAbsensi] =
    useState<settingAbsensiProps | null>(null);

  const [locationOffice, setLocationOffice] = useState<LatLngTuple | null>(
    null
  );

  useEffect(() => {
    const getData = async () => {
      const { latitude, longitude } = (await getLocation()) as {
        latitude: number;
        longitude: number;
      };
      if (latitude && longitude) {
        setLocation([latitude, longitude]);
        setLocationPresent([latitude, longitude]);

        if (locationOffice && settingAbsensi) {
          const distance = calculateDistance(locationOffice, [
            latitude,
            longitude,
          ]);
          if (distance >= settingAbsensi?.radius) setInRadius(false);
          else setInRadius(true);

          setTimeout(() => {
            setIsLoading(false);
          }, 150);
        }
      } else {
        setLocation(null);
      }
    };

    getData();
  }, [locationOffice, settingAbsensi]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  const handleOpenSubmissions = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpenSubmission(true);
    }, 150);
  };

  useEffect(() => {
    if (data) {
      const result = data.data;
      const setting = result.setting;
      setIsStatusPresent(result.status_absen);
      setSettingAbsensi(setting);
      setLocationOffice([
        parseFloat(setting.latitude),
        parseFloat(setting.longitude),
      ]);
    }
  }, [data]);

  useEffect(() => {
    function updateEveryMinute() {
      if (settingAbsensi) {
        moment().tz("Asia/Jakarta");

        const currentTime = moment();
        const waktuAbsenMasuk = moment(settingAbsensi?.jam_masuk, "HH:mm");
        const waktuAbsenKeluar = moment(settingAbsensi?.jam_keluar, "HH:mm");

        if (isStatusPresent === 0) {
          if (
            currentTime
              .clone()
              .isBefore(
                waktuAbsenMasuk
                  .clone()
                  .subtract(settingAbsensi.toleransi_masuk, "minutes")
              )
          ) {
            setIsAbsen(false);
            setIsStatusAbsen("");
          } else if (
            currentTime
              .clone()
              .isAfter(
                waktuAbsenMasuk
                  .clone()
                  .subtract(settingAbsensi.toleransi_masuk, "minutes")
              ) &&
            currentTime
              .clone()
              .isBefore(
                waktuAbsenMasuk
                  .clone()
                  .add(settingAbsensi.toleransi_masuk, "minutes")
              )
          ) {
            setIsAbsen(true);
            setIsStatusAbsen("");
          } else {
            setIsAbsen(false);
            setIsStatusAbsen("terlambat");
          }
        } else {
          if (
            currentTime
              .clone()
              .isBefore(
                waktuAbsenKeluar
                  .clone()
              )
          ) {
            setIsAbsen(false);
          }
        }
      }
    }

    updateEveryMinute();
    const intervalId = setInterval(updateEveryMinute, 60000);
    return () => clearInterval(intervalId);
  }, [settingAbsensi, isStatusPresent]);

  return (
    <Layout
      isShowFooter={false}
      urlBack={true}
      noPadding
      isHiddenBackgroundHeader
      noNavMobile={true}
    >
      {!isLoading && location && locationOffice && (
        <MapContainer
          center={location}
          zoom={inRadius ? 40 : 30}
          scrollWheelZoom={false}
          zoomControl={false}
          boxZoom={false}
          dragging={true}
          tap={false}
          touchZoom={true}
          className="map-background"
          doubleClickZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          <Marker position={location} icon={markerIcon} />
          {!inRadius && (
            <>
              <Marker icon={markerIcon} position={locationOffice} />
              <Polyline positions={[locationOffice, location]} />
            </>
          )}
          <Circle
            center={locationOffice}
            radius={settingAbsensi?.radius}
            pathOptions={{ color: "red", fillColor: "#f04", fillOpacity: 0.3 }}
          />
        </MapContainer>
      )}
      {isLoading && <Preloader />}
      <div className="alert-presensi">
        {!isLoading && (
          <>
            {isStatusPresent === 0 || isStatusPresent === 1 ? (
              <>
                {inRadius ? (
                  isAbsen ? (
                    <div
                      className={`card card-style bg-${
                        isStatusPresent === 0 ? "blue" : "mint"
                      }-dark show shadow-bg shadow-bg-m fade p-0`}
                    >
                      <div className="content my-3">
                        <div className="d-flex">
                          <div className="align-self-center">
                            <p className="color-white mb-0 font-12 ps-3 pe-4 line-height-s">
                              <span className="font-500 color-white font-16 mb-0">
                                {isStatusPresent === 0
                                  ? "Absen Datang"
                                  : "Absen Pulang"}
                              </span>
                              <br />
                              Anda berada di posisi absensi
                            </p>
                          </div>
                          <div className="ms-auto">
                            <button
                              className="btn-full btn gradient-green btn-s"
                              onClick={handleOpen}
                            >
                              Lanjut
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {isStatusAbsen !== "terlambat" ? (
                        <div className="card card-style bg-blue-dark show shadow-bg shadow-bg-m fade p-0">
                          <div className="content my-3">
                            <div className="d-flex">
                              <div className="align-self-center">
                                <i className="bi bi-clock font-36 d-block color-white"></i>
                              </div>
                              <div className="align-self-center">
                                {isStatusPresent == 0 ? (
                                  <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    Waktu absensi masuk mulai jam{" "}
                                    {moment(settingAbsensi?.jam_masuk, "HH:mm")
                                      .subtract(
                                        settingAbsensi?.toleransi_masuk,
                                        "minutes"
                                      )
                                      .tz("Asia/Jakarta")
                                      .format("H:mm")}
                                    .
                                  </p>
                                ) : (
                                  <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                    Waktu absensi pulang jam{" "}
                                    {moment(settingAbsensi?.jam_keluar, "HH:mm")
                                      .tz("Asia/Jakarta")
                                      .format("H:mm")}
                                    .
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="card card-style bg-red-dark show shadow-bg shadow-bg-m fade p-0">
                          <div className="content my-3">
                            <div className="d-flex">
                              <div className="align-self-center">
                                <i className="bi bi-emoji-frown-fill font-36 d-block color-white"></i>
                              </div>
                              <div className="align-self-center">
                                <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                  Waktu absensi sudah terlewat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <div className="card card-style bg-red-dark show shadow-bg shadow-bg-m fade p-0">
                    <div className="content my-3">
                      <div className="d-flex">
                        <div className="align-self-center">
                          <i className="bi bi-emoji-frown-fill font-36 d-block color-white"></i>
                        </div>
                        <div className="align-self-center">
                          <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                            Kamu berada di luar area absensi.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="card card-style bg-blue-dark show shadow-bg shadow-bg-m fade p-0">
                <div className="content my-3">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <i className="bi bi-emoji-sunglasses-fill font-36 d-block color-white"></i>
                    </div>
                    <div className="align-self-center">
                      <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                        Kamu Sudah melakukan absensi hari ini.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="button-overlay-map" style={{ top: 13.5, right: 8 }}>
        {isStatusPresent === 1 && (
          <button
            className="btn btn-xs gradient-blue shadow-bg shadow-bg-m text-start"
            onClick={handleOpenSubmissions}
          >
            <i
              className="bi bi-person-raised-hand"
              style={{ fontSize: 18 }}
            ></i>
          </button>
        )}
        {/* {isStatusPresent !== 0 && (
          <button
            className="btn btn-xs gradient-orange shadow-bg shadow-bg-m text-start"
          >
            <i
              className="bi bi-clock-history"
              style={{ fontSize: 18 }}
            ></i>
          </button>
        )} */}
      </div>
      {isEnableModal && location && (
        <ModalAbsen
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsEnableModal={setIsEnableModal}
          location={locationPresent}
        />
      )}
      {isEnableModal && (
        <SubmissionModal
          location={locationPresent}
          isOpen={isOpenSubmission}
          setIsOpen={setIsOpenSubmission}
          setIsEnableModal={setIsEnableModal}
        />
      )}
    </Layout>
  );
};

export default AbsensiPage;
