/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import FormProductWarehouse from "./form";
import { useFetchItemsWarehouse } from "./interface";
import { BiEdit } from "react-icons/bi";
import ButtonDelete from "../../../../assets/components/ButtonDelete";
import { useParams } from "react-router-dom";

const ProductWarehouseProyek = () => {
  const { uuid: idProyek } = useParams();
  const { data } = useFetchItemsWarehouse(idProyek);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  const [listItems, setListItems] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const result = data.data;
      setListItems(result);
    }
  }, [data]);

  return (
    <>
      {!isEnableModal && (
        <>
          <div
            className="d-flex px-3 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Data Items</h3>
            </div>
          </div>
          {listItems.length < 1 ? (
            <div
              className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
              style={{
                borderRadius: 15,
                animationDelay: Math.min(1 * 200, 1000) + "ms",
              }}
            >
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                Belum ada data items
              </h5>
            </div>
          ) : (
            listItems.map((item, itemKey) => {
              return (
                <div
                  className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                    itemKey !== 0 && "mt-3"
                  } `}
                  key={itemKey}
                  style={{
                    animationDelay: Math.min(itemKey * 200, 2000) + 10 + "ms",
                    borderRadius: 15,
                    minHeight: 55,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="d-flex w-100">
                    <div className="d-flex">
                      <div className="me-2">
                        <img
                          src={
                            item.file
                              ? item.file
                              : "/images/items-place-holder.png"
                          }
                          width={60}
                          alt="photo items"
                          style={{ borderRadius: 10 }}
                        />
                      </div>
                      <div className="ps-2 align-self-center">
                        <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                          {item.name}
                        </h5>
                        <h4 className="ps-1 mb-0 font-400 font-12 mt-1">
                          Kode : {item.code}
                        </h4>
                        <h4 className="ps-1 mb-0 font-400 font-12 mt-n1">
                          Stock :{" "}
                          {item.stock.length < 1
                            ? 0
                            : item.stock
                                .map(
                                  (stock: any, index: number) =>
                                    `${stock.stock} ${stock.label}${
                                      index < item.stock.length - 1 ? " " : ""
                                    }`
                                )
                                .join(" ")}
                        </h4>
                      </div>
                    </div>
                    <div className="ms-auto d-flex">
                      <button
                        className="btn-s btn gradient-green shadow-bg shadow-bg-xs"
                        onClick={() => {
                          setIsDataEdit(item), setIsEnableModal(true);
                        }}
                      >
                        <BiEdit />
                      </button>
                      {item.stock.length < 1 && (
                        <ButtonDelete
                          className="btn-s ms-2 btn gradient-red shadow-bg shadow-bg-xs"
                          url="warehouse/items-masuk"
                          KEY="items-masuk"
                          values={item.uuid}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          <button
            className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
            onClick={() => {
              setIsDataEdit(null), setIsEnableModal(true);
            }}
            style={{ zIndex: 999 }}
          >
            <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
          </button>
        </>
      )}
      {isEnableModal && (
        <FormProductWarehouse
          isDataEdit={isDataEdit}
          setIsEnableModal={setIsEnableModal}
          setIsDataEdit={setIsDataEdit}
        />
      )}
    </>
  );
};

export default ProductWarehouseProyek;
