import { useState } from "react";
import { Link } from "react-router-dom";
import { API_CLIENT, appFirebase } from "../../const";

import { getMessaging, getToken } from "firebase/messaging";

const generateRandomToken = () => {
  return (
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
  );
};

const requestPermission = async () => {
  try {
    return await Notification.requestPermission();
  } catch (error) {
    console.error("Error requesting permission:", error);
    return "denied";
  }
};

const LoginPage = () => {
  const [loginData, setLoginData] = useState<{
    username: string;
    password: string;
  }>({ username: "", password: "" });

  const [isValidForm, setIsValidForm] = useState<boolean>(true);
  const [isValidLogin, setIsValidLogin] = useState<boolean>(true);
  const [isMessageLogin, setIsMessageLogin] = useState<string>("");

  const handleSubmitLogin = async () => {
    if (loginData.username === "" || loginData.password === "") {
      setIsValidLogin(true);
      setIsMessageLogin("");
      return setIsValidForm(false);
    }

    setIsValidForm(true);

    let tokenFcm;
    try {
      const messaging = getMessaging(appFirebase);
      const permissionGranted = await requestPermission();

      if (permissionGranted === "granted") {
        tokenFcm = await getToken(messaging, {
          vapidKey:
            "BADJhH4Ems57ZesJ1MvWnmIEI0QRyXDazoa1ntjO2PHpXw0I9PEO7PJwZ3VoBj_d7tvZhwvTnf6ow_BGgJODwQ4",
        });
      } else {
        tokenFcm = generateRandomToken();
      }
    } catch (error) {
      tokenFcm = generateRandomToken();
    }

    const dataSend = {
      ...loginData,
      token: tokenFcm,
    };

    API_CLIENT.post("/login-web", dataSend)
      .then((response) => {
        const result = response.data;
        if (result.error) {
          setIsValidLogin(false);
          setIsMessageLogin(result.message);
        } else {
          localStorage.setItem("zeus-token", result.data.token);
          localStorage.setItem("isWeb", "true");
          window.location.href = "/";
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="page-content mb-0 pb-5 pt-4">
      <div
        className="card card-style mb-0 bg-transparent shadow-0 mx-0 rounded-0 mt-2"
        style={{ minHeight: "calc(100vh)" }}
      >
        <div className="card-center">
          <div className="card card-style mt-3 mb-3 pt-4 pb-1">
            <div className="content">
              <div
                className="d-flex flex-column"
                style={{ alignItems: "center" }}
              >
                <img
                  src="/images/logo-login.gif"
                  style={{ width: "70%" }}
                  alt="login logo"
                />
                <div style={{ width: "98%" }} className="mt-5">
                  <div className="demo-animation">
                    {!isValidForm && (
                      <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 m-0 mb-3">
                        <div className="content my-3">
                          <div className="d-flex">
                            <div className="align-self-center">
                              <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center">
                              <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                <strong>Verification required.</strong>
                                <br />
                                Silahkan masukan username dan password.
                              </p>
                            </div>
                            <div className="ms-auto">
                              <button
                                className="btn-close opacity-20 font-11 mt-n2 me-n2"
                                onClick={() => setIsValidForm(true)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isValidLogin && (
                      <div className="card card-style bg-red-dark alert-dismissible show shadow-bg shadow-bg-m fade p-0 m-0 mb-3">
                        <div className="content my-3">
                          <div className="d-flex">
                            <div className="align-self-center">
                              <i className="bi bi-exclamation-triangle font-36 color-white d-block"></i>
                            </div>
                            <div className="align-self-center">
                              <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                <strong>Verification required.</strong>
                                <br />
                                {isMessageLogin}
                              </p>
                            </div>
                            <div className="ms-auto">
                              <button
                                className="btn-close opacity-20 font-11 mt-n2 me-n2"
                                onClick={() => setIsValidLogin(true)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-custom mb-3 form-icon form-floating">
                      <i
                        className="bi bi-person-circle font-14"
                        style={{ left: 0 }}
                      ></i>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        className="form-control rounded-xs"
                        placeholder="username"
                        value={loginData.username}
                        onChange={(event) => {
                          setLoginData((prev) => ({
                            ...prev,
                            username: event.target.value,
                          }));
                        }}
                      />
                      <label htmlFor="username" className="color-theme">
                        Username
                      </label>
                      <span>(required)</span>
                    </div>
                    <div className="form-custom mb-3 form-icon form-floating">
                      <i
                        className="bi bi-asterisk font-12"
                        style={{ left: 0 }}
                      ></i>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control rounded-xs"
                        placeholder="password"
                        value={loginData.password}
                        onChange={(event) => {
                          setLoginData((prev) => ({
                            ...prev,
                            password: event.target.value,
                          }));
                        }}
                      />
                      <label htmlFor="password" className="color-theme">
                        Password
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn rounded-sm btn-m gradient-green text-uppercase font-700 mt-4 mb-3 btn-full shadow-bg shadow-bg-s"
                      style={{ width: "100%" }}
                      onClick={handleSubmitLogin}
                    >
                      SIGN IN
                    </button>
                    <div className="d-flex">
                      <Link to="" className="color-theme opacity-30 font-12">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
