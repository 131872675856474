import Layout from "../../assets/components/layout"

const PageInfo = () => {
    return (
        <Layout headerTitle="Info">
            <>
            </>
        </Layout>
    )
}

export default PageInfo