/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";
import { BiSolidHeart } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { menuAccess, menuActiveProyek, menuHeaderProyek } from "./interface";
import { useProfile } from "../../../../../const";

const NavBarProjectManagement = () => {
  const { data } = useProfile();
  const navigation = useNavigate();

  const [profile, setProfile] = useState<any | null>({});

  useEffect(() => {
    if (data) {
      setProfile(data.user);
    }
  }, [data]);

  const handleNavigation = (active: string) => {
    navigation({
      search: `active-tab=${active}`,
    });
    document.getElementById("collapseMenu")?.click();
  };

  const location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const [activeTab, setActiveTab] = useState("dashboard");

  const backToHomePage = () => {
    document.getElementById("collapseMenu")?.click();
    navigation("/");
  };

  useEffect(() => {
    menuActiveProyek(queryParams.get("active-tab"), setActiveTab);
  }, [queryParams]);

  return (
    <>
      <div
        id="menu-main"
        style={{ minWidth: 280, maxWidth: 300 }}
        className="offcanvas offcanvas-start offcanvas-detached rounded-m"
      >
        <div className="bg-theme mx-3 rounded-m shadow-m mt-3 mb-3"></div>
        {menuHeaderProyek.map((header, headerKey) => {
          return (
            <div key={headerKey}>
              <div className={`menu-divider ${headerKey !== 0 ? "mt-3" : ""}`}>
                {header.title}
              </div>
              <div className="menu-list">
                <div className="card card-style rounded-m p-3 py-2 mb-0">
                  {header.children.map((menu, keyMenu) => {
                    const isActive = activeTab === menu.key;
                    const isAccess =
                      profile.roles === "penaggung-jawab-proyek" ?
                      menuAccess.some((object) => {
                        return object !== menu.key;
                      }) : true;
                    return (
                      isAccess && (
                        <span
                          key={keyMenu}
                          className={`${isActive ? "active-item" : ""}`}
                          onClick={() =>
                            menu.key === "backToHomePage"
                              ? backToHomePage()
                              : handleNavigation(menu.key)
                          }
                        >
                          <div
                            className={`icon ${menu.gradient} shadow-bg shadow-bg-xs`}
                          >
                            <i className={`bi ${menu.icon}`}></i>
                          </div>
                          <span>{menu.title}</span>
                        </span>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        <p className="text-center mb-0 mt-3 pb-3 font-9 text-uppercase font-600 color-theme">
          Made with
          <BiSolidHeart className="color-red-dark font-9 m-1" size={15} />
          by{" "}
          <a
            href="https://bocahgabut.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bocah Gabut
          </a>
          <span className="copyright-year"> 2023</span>
        </p>
      </div>
    </>
  );
};

export default NavBarProjectManagement;
