import { createPortal } from "react-dom";
import Layout from "../../assets/components/layout";
import ModalUser from "./modal";
import { useEffect, useState } from "react";
import {
  dataRowUser,
  iInitialValuesUsers,
  initialValuesUsers,
  useFetchUsers,
} from "./interface";
import { BiEdit } from "react-icons/bi";
import ButtonDelete from "../../assets/components/ButtonDelete";

const PageUser = () => {
  const { data } = useFetchUsers();

  const [modalStatus, setModalStatus] = useState<string>("create");
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const [dataUsers, setDataUsers] = useState<dataRowUser[]>([]);
  const [dataEditModal, setDataEditModal] = useState<
    dataRowUser | iInitialValuesUsers
  >(initialValuesUsers);

  useEffect(() => {
    if (data) {
      if (data.error) {
        // setIsRestirected(true);
      } else {
        setDataUsers(
          data.data.map((role: dataRowUser, key: number) => ({
            no: key + 1,
            id: role.id,
            uuid: role.uuid,
            name: role.name,
            roles: role.roles,
            profile: role.profile,
            email: role.email,
            jabatan: role.jabatan,
            status: role.status,
            gapok: role.gapok,
            jabatan_name: role.jabatan_name,
          }))
        );
      }
    }
  }, [data]);

  const handleEdit = (values: dataRowUser) => {
    setModalStatus("edit");
    setDataEditModal(values);
    setIsShowModal(true);
  };

  return (
    <Layout>
      <div className="card card-style">
        <div className="content">
          <h4>Data Pengguna</h4>
          <p>Management pengguna, Seperti admin</p>
          <div
            className="list-group list-custom list-group-m rounded-m"
            style={{ position: "relative" }}
          >
            {dataUsers.length > 0 ? (
              dataUsers.map((user, userKey) => {
                return (
                  <div className="list-group-item" key={userKey}>
                    <img
                      src={user.profile}
                      alt="profile"
                      className="me-2 rounded-xs"
                      width={50}
                      height={40}
                    />
                    <div className="ps-1" style={{ width: "100%" }}>
                      <strong className="font-600">{user.name}</strong>
                      <span>{user.jabatan_name}</span>
                    </div>
                    <div className="d-flex">
                      <button
                        data-bs-toggle="offcanvas"
                        data-bs-target="#modal-user"
                        className="btn gradient-blue btn-s text-start"
                        onClick={() => handleEdit(user)}
                      >
                        <BiEdit />
                      </button>
                      <ButtonDelete
                        className="btn gradient-red btn-s text-start ms-3"
                        url="users"
                        KEY="users"
                        values={user.uuid}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="d-flex flex-column text-center">
                <img src="/images/no-data.svg" height={250} alt="not found" />
                <h1 className="font-24 font-800 mt-3">Tidak ada data.</h1>
              </div>
            )}
          </div>
          <button
            data-bs-toggle="offcanvas"
            data-bs-target="#modal-user"
            className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
            style={{ width: "100%" }}
            onClick={() => {
              setModalStatus("create"), setIsShowModal(true);
            }}
          >
            Tambah Data Pengguna
          </button>
        </div>
      </div>
      {createPortal(
        <ModalUser
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          modalStatus={modalStatus}
          modalEditData={dataEditModal}
        />,
        document.body
      )}
    </Layout>
  );
};

export default PageUser;
