/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_CLIENT } from "../../const";
import * as Yup from "yup";

export const jabatanSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
});

export interface dataRowJabatan {
  no: number;
  id: string;
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface iInitialValuesJabatan {
  name: string;
}

export const initialValuesJabatan: iInitialValuesJabatan = {
  name: "",
};

const fetchJabatan = async (isProyek: boolean) => {
  const response = await API_CLIENT.get<any>(`/jabatan?isProyek=${isProyek}`);
  return response.data;
};

export const useFetchJabatan = (isProyek: boolean) => {
  return useQuery({
    queryKey: [`jabatan-${isProyek}`],
    queryFn: () => fetchJabatan(isProyek),
  });
};

export const useStoreJabatan = (isProyek: boolean) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData: iInitialValuesJabatan) => {
      return API_CLIENT.post("/jabatan", newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`jabatan-${isProyek}`] });
    },
  });
};

export const useStoreUpdateJabatan = (isProyek: boolean) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: {
      newData: iInitialValuesJabatan;
      id: string;
    }) => {
      const { newData, id } = variables;
      return API_CLIENT.patch(`/jabatan/${id}`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`jabatan-${isProyek}`] });
    },
  });
};
