/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import ModalPeminjamanInventarisProyek from "./modal";
import { useParams } from "react-router-dom";
import { cashFlowFilter } from "../../cashFlow/interface";
import ButtonFilterRange from "../../../../../../../assets/components/filterRange";
import { useFetchPeminjamanInvetarisProyek } from "./interface";
import moment from "moment";
import ButtonDelete from "../../../../../../../assets/components/ButtonDelete";
// import { BiEdit } from "react-icons/bi";

const PeminjamanInventarisProyek = () => {
  const { uuid: idProyek } = useParams();
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data, refetch } = useFetchPeminjamanInvetarisProyek(
    selectedFilter,
    idProyek,
    dateRange
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  const [dataHistory, setDataHistory] = useState<any[]>([]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  useEffect(() => {
    if (data) {
      const result = data.data;
      setDataHistory(result);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataHistory(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div
        className="d-flex px-4 slide-top mb-2"
        style={{ alignItems: "center" }}
      >
        <div>
          <h3 className="mb-0">Riwayat Peminjaman</h3>
        </div>
      </div>
      {dataHistory.length < 1 ? (
        <div
          className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
          style={{
            borderRadius: 15,
            animationDelay: Math.min(1 * 200, 1000) + "ms",
          }}
        >
          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
            Belum ada riwayat peminjaman
          </h5>
        </div>
      ) : (
        dataHistory.map((history, historyKey) => {
          return (
            <div
              className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                historyKey !== 0 && "mt-3"
              } `}
              key={historyKey}
              style={{
                animationDelay: Math.min(historyKey * 200, 1000) + "ms",
                borderRadius: 15,
              }}
            >
              <div className="d-flex">
                <div className="ps-2 align-self-center">
                  <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                    {history.karyawan.name}{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      ({history.karyawan.jabatan.name})
                    </span>
                  </h5>
                  <div className="d-flex flex-column">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <h4 className="ps-1 mb-0 font-600 font-13 mt-1">
                        {history.inventaris.name} x {history.jumlah}{" "}
                      </h4>
                      <span
                        className={`ps-1 mb-0 font-400 font-13 mt-1`}
                        style={{ color: history.status === 0 ? "red" : "" }}
                      >
                        {history.status === 0
                          ? "Belum dikembalikan"
                          : "Sudah dikembalikan"}
                      </span>
                    </div>
                    <span className="ps-1 mb-0 font-400 font-12 mt-n1">
                      {moment(new Date(history.created_at)).format("ll")}
                    </span>
                  </div>
                </div>
                {history.is_edit && (
                  <div className="ms-auto d-flex gap-1">
                    {/* <button
                      className="btn-s btn gradient-green shadow-bg shadow-bg-xs"
                      onClick={() => {
                        setIsDataEdit(history), handleOpen();
                      }}
                    >
                      <BiEdit />
                    </button> */}
                    <ButtonDelete
                      className="btn-s btn gradient-red shadow-bg shadow-bg-xs"
                      url="proyek/peminjaman-inventaris"
                      KEY="peminjaman-inventaris"
                      values={history.uuid}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
      <button
        className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
        onClick={() => {
          setIsDataEdit(null), handleOpen();
        }}
        style={{ zIndex: 999 }}
      >
        <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
      </button>
      {isEnableModal && (
        <ModalPeminjamanInventarisProyek
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isDataEdit={isDataEdit}
        />
      )}
    </>
  );
};

export default PeminjamanInventarisProyek;
