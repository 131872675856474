interface iMenuFooter {
  title: string;
  link: string;
  key: string;
  size: string;
  icons: string;
  type: string;
}

export const menuFooter: iMenuFooter[] = [
  {
    key: "dashboard",
    title: "Dashboard",
    link: "/",
    icons: "bi bi-house-fill font-18",
    size: "18%",
    type: "span",
  },
  {
    key: "submission",
    title: "Pengajuan",
    link: "/submission",
    icons: "bi bi-phone-vibrate font-18",
    size: "18%",
    type: "span",
  },
  {
    key: "absen",
    title: "Absensi",
    link: "absen",
    icons: "bi bi-camera-fill font-28",
    size: "28%",
    type: "a",
  },
  {
    key: "profile",
    title: "Profile",
    link: "profile",
    icons: "bi bi-person-fill font-18",
    size: "18%",
    type: "a",
  },
  {
    key: "history",
    title: "Riwayat",
    link: "/history-user",
    icons: "bi bi-clock-history font-18",
    size: "18%",
    type: "span",
  },
  // {
  //   key: "info",
  //   title: "Info",
  //   link: "/info",
  //   icons: "bi bi-exclamation-circle font-18",
  //   size: "18%",
  //   type: "span",
  // },
];
