import "core-js/stable";
import "regenerator-runtime/runtime";
import "core-js/actual";
import "reset-css";
import "normalize.css";
import "./assets/scss/global.scss"
import "./assets/scss/_utils.scss"
import "./assets/scss/_icons.scss"
import "./assets/scss/_form.scss"
import "./assets/scss/_animations.scss"
import 'sweetalert2/src/sweetalert2.scss'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from "react-router-dom";
import router from "./router";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('zeus_root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>,
)
