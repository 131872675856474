import { Dispatch, SetStateAction } from "react";

interface iMenuHeaderProyek {
  title: string;
  children: {
    title: string;
    key: string;
    gradient: string;
    icon: string;
  }[];
}

export const menuHeaderProyek: iMenuHeaderProyek[] = [
  {
    title: "NAVIGATION",
    children: [
      {
        title: "Dashboard",
        key: "mdx2yuBQwJewIUPFvileaPwwhpgTsk30",
        gradient: "gradient-red",
        icon: "bi-house-fill",
      },
      {
        title: "Karyawan",
        key: "ekp1qN8fuk6jMo1baAIXRGzLXT768z4R",
        gradient: "gradient-yellow",
        icon: "bi-person-arms-up",
      },
      {
        title: "Absensi",
        key: "rnwE2irQvfTfJfCFHM748ur0IveIjzj8",
        gradient: "gradient-green",
        icon: "bi-calendar-check",
      },
      {
        title: "Izin Pulang Cepet",
        key: "i9EmKc2aNuqtfhrJruY4q6QxB2lzlOZF",
        gradient: "gradient-red",
        icon: "bi-person-raised-hand",
      },
      {
        title: "Pemasukan",
        key: "XE1IHZUQmu8YxqcdAOqXVJYLHwHh4VoD",
        gradient: "gradient-blue",
        icon: "bi-app-indicator",
      },
      {
        title: "Pengeluaran",
        key: "COapq7bGgP5yxdeGs6Qy24TGrpBQz0ju",
        gradient: "gradient-orange",
        icon: "bi-archive",
      },
    ],
  },
  {
    title: "INVENTARIS",
    children: [
      {
        title: "Inventaris",
        key: "LUUVRSPqeP81IFyq4lg0tuqHu7R9tBMa",
        gradient: "gradient-red",
        icon: "bi-box-seam",
      },
      {
        title: "Peminjaman",
        key: "81EFkyWtHP3kxxXYnLxeABiqdpPjBbz8",
        gradient: "gradient-blue",
        icon: "bi-box-arrow-in-up",
      },
      {
        title: "Pengembalian",
        key: "ou3oBY9t7QhG9eydxVOts7KIobN19iqh",
        gradient: "gradient-orange",
        icon: "bi-box-arrow-in-down",
      },
      {
        title: "Kehilangan",
        key: "tE7hyVeOrVPLfcVsVFNLnVVSwOXQQbDH",
        gradient: "gradient-green",
        icon: "bi-boxes",
      },
    ],
  },
  {
    title: "GUDANG",
    children: [
      {
        title: "Items",
        key: "ThMxIp82SMbf1Zv9vi2q29pRvv2zEW6W",
        gradient: "gradient-red",
        icon: "bi-bricks",
      },
      {
        title: "Pemasukan",
        key: "dXVMhBxW2BQaECFvqv1Zjqh3jOeCL2US",
        gradient: "gradient-orange",
        icon: "bi-window-plus",
      },
      {
        title: "Pengeluaran",
        key: "GMFl9SCVbpso38UejtFKESTnJ4rdt2Ia",
        gradient: "gradient-blue",
        icon: "bi-eject",
      },
      {
        title: "Penyesuaian",
        key: "xV6vQXLwi0mM9AgnuM0KBdYs4Msvgr8B",
        gradient: "gradient-red",
        icon: "bi-wrench",
      },
      {
        title: "Laporan",
        key: "QMl2risMCfT69EbLbE7Tg9pkxkgKj3vp",
        gradient: "gradient-green",
        icon: "bi-file-earmark-pdf",
      },
    ],
  },
  {
    title: "PENGATURAN",
    children: [
      {
        title: "Setting",
        key: "Y4d0mYANLfdtEKRtpqYeQoNAfKUHdByh",
        gradient: "gradient-red",
        icon: "bi-gear-fill",
      },
      {
        title: "Kembali",
        key: "backToHomePage",
        gradient: "gradient-blue",
        icon: "bi-x-lg",
      },
    ],
  },
];

export const menuAccess = ["Y4d0mYANLfdtEKRtpqYeQoNAfKUHdByh"];

export const menuActiveProyek = (
  queryParams: string | null,
  setActiveTab: Dispatch<SetStateAction<string>>
) => {
  switch (queryParams) {
    case "mdx2yuBQwJewIUPFvileaPwwhpgTsk30":
      setActiveTab("mdx2yuBQwJewIUPFvileaPwwhpgTsk30");
      break;
    case "ekp1qN8fuk6jMo1baAIXRGzLXT768z4R":
      setActiveTab("ekp1qN8fuk6jMo1baAIXRGzLXT768z4R");
      break;
    case "rnwE2irQvfTfJfCFHM748ur0IveIjzj8":
      setActiveTab("rnwE2irQvfTfJfCFHM748ur0IveIjzj8");
      break;
    case "XE1IHZUQmu8YxqcdAOqXVJYLHwHh4VoD":
      setActiveTab("XE1IHZUQmu8YxqcdAOqXVJYLHwHh4VoD");
      break;
    case "COapq7bGgP5yxdeGs6Qy24TGrpBQz0ju":
      setActiveTab("COapq7bGgP5yxdeGs6Qy24TGrpBQz0ju");
      break;
    case "Y4d0mYANLfdtEKRtpqYeQoNAfKUHdByh":
      setActiveTab("Y4d0mYANLfdtEKRtpqYeQoNAfKUHdByh");
      break;
    case "i9EmKc2aNuqtfhrJruY4q6QxB2lzlOZF":
      setActiveTab("i9EmKc2aNuqtfhrJruY4q6QxB2lzlOZF");
      break;
    case "LUUVRSPqeP81IFyq4lg0tuqHu7R9tBMa":
      setActiveTab("LUUVRSPqeP81IFyq4lg0tuqHu7R9tBMa");
      break;
    case "81EFkyWtHP3kxxXYnLxeABiqdpPjBbz8":
      setActiveTab("81EFkyWtHP3kxxXYnLxeABiqdpPjBbz8");
      break;
    case "ou3oBY9t7QhG9eydxVOts7KIobN19iqh":
      setActiveTab("ou3oBY9t7QhG9eydxVOts7KIobN19iqh");
      break;
    case "F4lwU0vLAl0AgCNIpdyikjvxwa9JmGpA":
      setActiveTab("ou3oBY9t7QhG9eydxVOts7KIobN19iqh");
      break;
    case "tE7hyVeOrVPLfcVsVFNLnVVSwOXQQbDH":
      setActiveTab("tE7hyVeOrVPLfcVsVFNLnVVSwOXQQbDH");
      break;
    case "rhfJs0DYbyZkN13H1LxpoWFjwHJQaj0r":
      setActiveTab("tE7hyVeOrVPLfcVsVFNLnVVSwOXQQbDH");
      break;
    case "ThMxIp82SMbf1Zv9vi2q29pRvv2zEW6W":
      setActiveTab("ThMxIp82SMbf1Zv9vi2q29pRvv2zEW6W");
      break;
    case "dXVMhBxW2BQaECFvqv1Zjqh3jOeCL2US":
      setActiveTab("dXVMhBxW2BQaECFvqv1Zjqh3jOeCL2US");
      break;
    case "GMFl9SCVbpso38UejtFKESTnJ4rdt2Ia":
      setActiveTab("GMFl9SCVbpso38UejtFKESTnJ4rdt2Ia");
      break;
    case "xV6vQXLwi0mM9AgnuM0KBdYs4Msvgr8B":
      setActiveTab("xV6vQXLwi0mM9AgnuM0KBdYs4Msvgr8B");
      break;
    case "QMl2risMCfT69EbLbE7Tg9pkxkgKj3vp":
      setActiveTab("QMl2risMCfT69EbLbE7Tg9pkxkgKj3vp");
      break;
    default:
      setActiveTab("mdx2yuBQwJewIUPFvileaPwwhpgTsk30");
      break;
  }
};
