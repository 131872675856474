import * as Yup from "yup";
import { API_CLIENT } from "../../../../../../const";
import { toast } from "../../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export interface iInitialValuesKaryawanProyek {
  name: string;
  no_hp: string;
  jabatan_id: string;
  gapok: string;
  uang_makan: string;
}

export const initialValuesKaryawanProyek: iInitialValuesKaryawanProyek = {
  name: "",
  no_hp: "",
  jabatan_id: "",
  gapok: "",
  uang_makan: "",
};

export const KaryawanProyekSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  no_hp: Yup.string().required("Please insert a value."),
  jabatan_id: Yup.string().required("Please insert a value."),
  gapok: Yup.string().required("Please insert a value."),
  uang_makan: Yup.string().required("Please insert a value."),
});

export const submitStoreKaryawanProyek = async (
  values: iInitialValuesKaryawanProyek,
  proyek_id: string | undefined,
  handleCloseModal: () => void,
  isEdit: iKaryawanProyekProps | null
) => {
  const dataToSend = new FormData();
  dataToSend.append("name", values.name);
  dataToSend.append("no_hp", values.no_hp);
  dataToSend.append("jabatan_id", values.jabatan_id);
  dataToSend.append("gapok", values.gapok);
  dataToSend.append("uang_makan", values.uang_makan);
  dataToSend.append("proyek_id", proyek_id || "");

  let storeUpdate;
  if (isEdit) {
    storeUpdate = API_CLIENT.post(`/proyek/karyawan/${isEdit.uuid}`, dataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } else {
    storeUpdate = API_CLIENT.post("/proyek/karyawan", dataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  storeUpdate
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      handleCloseModal();
    })
    .catch((err) => console.log(err));
};

interface iKaryawanProyekFetch {
  message: string;
  error: boolean;
  data: iKaryawanProyekProps[];
}

export interface iKaryawanProyekProps {
  id: number;
  name: string;
  no_hp: string;
  jabatan_id: string;
  jabatan?: {
    name:string;
    uuid:string;
  };
  gapok: string;
  uang_makan: string;
  uuid: string;
  created_at: string;
  updated_at: string;
}

const fetchKaryawanProyek = async (idProyek: string | null) => {
  const response = await API_CLIENT.get<iKaryawanProyekFetch>(
    `/proyek/karyawan?idProyek=${idProyek}`
  );
  return response.data;
};

export const useFetchKaryawanProyek = (idProyek: string | null) => {
  return useQuery({
    queryKey: ["proyek-karyawan"],
    queryFn: () => fetchKaryawanProyek(idProyek),
  });
};
