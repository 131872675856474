import Layout from "../../assets/components/layout";
import Webcam from "react-webcam";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { toast } from "../../assets/components/toast";
import { API_CLIENT } from "../../const";

const AbsenPhoto = (): JSX.Element => {
  const [videoConstraints, setVideoConstraints] = useState({
    width: 1080,
    height: 1080,
    facingMode: "environment",
    whiteBalance: "continuous",
    focusDepth: 0,
    autoFocus: "continuous",
    flashMode: "on",
    aspectRatio: 1,
  });

  useEffect(() => {
    const updateVideoConstraints = () => {
      setVideoConstraints({
        ...videoConstraints,
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateVideoConstraints);

    return () => {
      window.removeEventListener("resize", updateVideoConstraints);
    };
  }, []);

  const navigation = useNavigate();
  const { uuid: idProyek } = useParams();
  const webcamRef = useRef<Webcam>(null);
  const [selectImage, setSelectImage] = useState<string | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);

  const handleScreenShot = () => {
    if (webcamRef.current && !selectImage) {
      const imgSrc = webcamRef.current.getScreenshot();
      setSelectImage(imgSrc);
    }
  };

  const handleOpenModal = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
    }, 150);
  };

  const handleSubmitData = (value: { keterangan: string }) => {
    const dataToSend = {
      photo: selectImage,
      idProyek: idProyek,
      keterangan: value.keterangan,
    };

    // console.log(dataToSend);
    API_CLIENT.post("/proyek/present-picture", dataToSend)
      .then((response) => {
        const result = response.data;
        if (result.error) {
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        } else {
          toast.success("Success", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
          setTimeout(() => {
            navigation(
              `/proyek-management-details/${idProyek}?active-tab=rnwE2irQvfTfJfCFHM748ur0IveIjzj8`
            );
          }, 150);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Layout isHiddenBackgroundHeader noPadding noHeader>
        <div className="container-camera">
          <div className="camera-top"></div>
          <div className="camera-content">
            {selectImage ? (
              <img src={selectImage} />
            ) : (
              <Webcam
                ref={webcamRef}
                audio={false}
                // height={100 + "%"}
                // width={100 + "%"}
                // height={1080}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                mirrored={false}
                screenshotQuality={100}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                }}
              />
            )}
          </div>
          {/* <span className="text-couter">
            Total Karyawan Teredeteksi : {facesDetected}
          </span> */}
          <div className="camera-bottom">
            <button
              className="btn btn-camera-option"
              onClick={() =>
                selectImage ? setSelectImage(null) : navigation(-1)
              }
            >
              <i className="bi bi-x-lg"></i>
            </button>
            <div className="button-screnshot-container">
              <button
                className="btn btn-screnshot"
                onClick={() => handleScreenShot()}
              ></button>
            </div>
            <button
              className="btn btn-camera-option"
              disabled={selectImage ? false : true}
              onClick={() => handleOpenModal()}
            >
              <i className="bi bi-check-lg"></i>
            </button>
          </div>
        </div>
      </Layout>
      {isEnableModal && (
        <Offcanvas
          show={isOpen}
          style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
          onHide={() => handleCloseModal()}
          placement="bottom"
        >
          <Offcanvas.Body>
            <h6 className="font-700 mb-n1 color-highlight">Informasi</h6>
            <h4 className="pb-2">Form Absensi</h4>
            <Formik
              initialValues={{ keterangan: "" }}
              onSubmit={(values: { keterangan: string }) => {
                handleSubmitData(values);
              }}
            >
              {() => (
                <Form>
                  <div className="form-field form-text mt-1">
                    <label
                      htmlFor="c1"
                      className="contactMessageTextarea color-theme"
                    >
                      Keterangan:
                      <span>(required)</span>
                    </label>
                    <Field
                      type="text"
                      name="keterangan"
                      id="c1"
                      as="textarea"
                      row={2}
                      className="round-small mb-0"
                      placeholder="Keterangan"
                      required
                    />
                  </div>
                  <p className="mb-0 font-12">
                    Silahkan masukan keterangan tambahan berapa orang dalam
                    photo tersebut seperti berapa tukang yang ada,helper yang
                    ada dll.
                  </p>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <button
                      className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                      style={{ width: "30%" }}
                      type="button"
                      onClick={handleCloseModal}
                    >
                      Batal
                    </button>
                    <button
                      className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                      style={{ width: "70%" }}
                      type="submit"
                    >
                      Simpan Data
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default AbsenPhoto;
