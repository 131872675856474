import {
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import CameraContainer from "./camera";
import Webcam from "react-webcam";
import { submitPresent } from "./interface";
import { useQueryClient } from "@tanstack/react-query";

interface ModalAbsenProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  location: number[]
}

const ModalAbsen: FC<ModalAbsenProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  location,
  ...props
}) => {
  const queryClient = useQueryClient();
  const webcamRef = useRef<Webcam>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpenCamera = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setSelectedImage(imageSrc);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["daily-present"] });
    }, 150);
  };

  return (
    <Offcanvas
      show={isOpen}
      style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
      onHide={() => handleCloseModal()}
      {...props}
      placement="bottom"
    >
      <Offcanvas.Body>
        <div
          className="container-webcame"
          style={{ height: window.innerHeight / 1.5 }}
        >
          {selectedImage ? (
            <img src={selectedImage} alt="absens" />
          ) : (
            <CameraContainer webcamRef={webcamRef} />
          )}
        </div>
        <div className="d-flex" style={{ gap: 10 }}>
          <button
            className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-2"
            style={{ width: selectedImage ? "40%" : "100%" }}
            onClick={() => {
              selectedImage ? setSelectedImage("") : handleOpenCamera();
            }}
          >
            {selectedImage ? "Ulangi Photo" : "Ambil Photo"}
          </button>
          {selectedImage && (
            <button
              className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-2"
              style={{ width: selectedImage ? "60%" : "" }}
              onClick={() => submitPresent(selectedImage,location[0],location[1],handleCloseModal)}
            >
              Absen
            </button>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ModalAbsen;
