/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormSelectWithFormik } from "./selectWithFormik";
import { FormSelectWithouthFormik } from "./selectWithoutFormik";

interface FormSelect {
  urlSelect: string;
  name: string;
  menuPlacement?: string;
  className?: string;
  error?: any;
  touched?: any;
  isMulti?: boolean;
  isFormik?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isClearableAfterSelect?: boolean;
  onCallback?: (value: any) => void;
}

const FormSelect: React.FC<FormSelect> = (props) => {
  const { isFormik = true } = props;

  return (
    <>
      {isFormik && <FormSelectWithFormik {...props} />}
      {!isFormik && <FormSelectWithouthFormik {...props} />}
    </>
  );
};

export default FormSelect;
