/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { ChangeEvent } from "react";
import moment from "moment-timezone";
import { initializeApp } from "firebase/app";

import L from "leaflet";

export const CONFIG_APP = {
  "url-api": import.meta.env.BCH_API_URL,
  "url-server": import.meta.env.BCH_SERVER_URL,
  "default-lang": import.meta.env.BCH_DEFAULT_LANG,
  "secrect-key-val": import.meta.env.BCH_SECRET,
};

const firebaseConfig = {
  apiKey: "AIzaSyDbGg2CpLz-URvWnSqkud_tw8DoUx_pLyw",
  authDomain: "epresensi-410114.firebaseapp.com",
  projectId: "epresensi-410114",
  storageBucket: "epresensi-410114.appspot.com",
  messagingSenderId: "606411572487",
  appId: "1:606411572487:web:4e76408d70f8ffbe3c31a2",
  measurementId: "G-0M81S9VG6M",
};

export const appFirebase = initializeApp(firebaseConfig);

const nprogress = NProgress.configure({ showSpinner: true });

export const getZeusToken = () => {
  // const metas = document.getElementsByTagName('meta');
  // for (let i = 0; i < metas.length; i++) {
  //   if (metas[i].getAttribute('name') === 'zeus-token') {
  //     return metas[i].getAttribute('content');
  //   }
  // }
  // localStorage.setItem("zeus-token", '233-200-170-190-205-160-187-204-210-177-185-179-120-229-199-163-191-161-151-124-135-109-182-195-128-133-183-170-195-120-195-139');
  // localStorage.setItem("zeus-token", '115-220-165-173-167-187-130-178-164-190-164-148-187-131-148-123-121-219-203-183-156-158-104-174-121-152-227-188-175-163-167-174');
  const token = localStorage.getItem("zeus-token");
  return token;
};

const fetchProfile = async () => {
  const response = await API_CLIENT.post<any>("/profile");
  return response.data;
};

export const useProfile = () => {
  return useQuery({
    queryKey: ["profile"],
    queryFn: fetchProfile,
  });
};

export const API_CLIENT = axios.create({
  baseURL: CONFIG_APP["url-api"],
  headers: {
    "Content-type": "application/json",
    "x-signature": caesarEncrypt(
      btoa(
        caesarEncrypt(CONFIG_APP["secrect-key-val"], 10) +
          ";" +
          btoa("" + Math.random() * 9999)
      ),
      20
    ),
    Authorization: `Bearer ${getZeusToken()}`,
  },
});

API_CLIENT.interceptors.request.use((config) => {
  nprogress.start();
  // if (config.url !== "/auth/refresh-token") {
  //   refreshToken();
  // }
  return config;
});

API_CLIENT.interceptors.response.use(
  (response) => {
    nprogress.done();
    return response;
  },
  (error) => {
    nprogress.done();
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export function caesarEncrypt(text: string, shift: number) {
  let encryptedText = "";

  for (let i = 0; i < text.length; i++) {
    let char = text[i];
    if (char.match(/[a-z]/i)) {
      const code = text.charCodeAt(i);
      if (code >= 65 && code <= 90) {
        char = String.fromCharCode(((code - 65 + shift) % 26) + 65);
      } else if (code >= 97 && code <= 122) {
        char = String.fromCharCode(((code - 97 + shift) % 26) + 97);
      }
    }
    encryptedText += char;
  }

  return encryptedText;
}

export function caesarDecrypt(text: string, shift: number) {
  let decryptedText = "";

  for (let i = 0; i < text.length; i++) {
    let char = text[i];
    if (char.match(/[a-z]/i)) {
      const code = text.charCodeAt(i);
      if (code >= 65 && code <= 90) {
        // Huruf besar (A-Z)
        char = String.fromCharCode(((code - 65 - shift + 26) % 26) + 65);
      } else if (code >= 97 && code <= 122) {
        // Huruf kecil (a-z)
        char = String.fromCharCode(((code - 97 - shift + 26) % 26) + 97);
      }
    }
    decryptedText += char;
  }

  return decryptedText;
}

export function generateKeyRandom(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    key += characters.charAt(randomIndex);
  }
  return key;
}

export function numberToText(
  inputNumber: string | number,
  key: string | number
) {
  const encodedValues = inputNumber.toString().split("-");
  let result = "";

  for (let i = 0; i < encodedValues.length; i++) {
    const encodedValue = parseInt(encodedValues[i], 10);
    const keyChar = key.toString()[i % key.toString().length].charCodeAt(0);
    const charCode = (encodedValue - keyChar + 256) % 256;
    result += String.fromCharCode(charCode);
  }

  return result;
}

export function textToNumber(inputString: string, key: string) {
  let result = "";
  for (let i = 0; i < inputString.length; i++) {
    const char = inputString[i];
    const charCode = char.charCodeAt(0);
    const keyChar = key[i % key.length].charCodeAt(0);
    const encodedChar = charCode + keyChar;

    result += encodedChar;
    if (i < inputString.length - 1) {
      result += "-";
    }
  }
  return result;
}

export const generatePostEncrypt = (values: string) => {
  const dataSend = caesarEncrypt(values, 20);
  const keyLength = values.length;
  const keyShuffle = generateKeyRandom(keyLength);
  const data = btoa(textToNumber(dataSend, keyShuffle) + ";" + keyShuffle);

  return data;
};

export const formatRibuan = (event: ChangeEvent<HTMLInputElement>) => {
  const number_string = event.target.value.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  let separator = "";
  let formattedValue = split[0].substr(0, sisa);
  if (ribuan) {
    separator = sisa ? "." : "";
    formattedValue += separator + ribuan.join(".");
  }

  return (event.target.value = formattedValue);
};

export const toThousand = (
  value: string | number | undefined = "0"
): string => {
  const stringValue = typeof value === "string" ? value : value.toString();

  const [integerPart, decimalPart] = stringValue.split(".");

  const negativeSign = integerPart.startsWith("-") ? "-" : "";

  const number_string = stringValue.replace(/[^,\d]/g, "");

  const split = number_string.split(",");
  const sisa = split[0].length % 3;
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  let separator = "";
  let formattedValue = split[0].substr(0, sisa);
  if (ribuan) {
    separator = sisa ? "." : "";
    formattedValue += separator + ribuan.join(".");
  }

  const result =
    negativeSign + formattedValue + (decimalPart ? "." + decimalPart : "");

  return result;
};

export const checkDate = (date: Date, selisih: number) => {
  const dateNow = new Date();
  const selisihWaktu: number = date.getTime() - dateNow.getTime();
  const selisihHari: number = selisihWaktu / (1000 * 60 * 60 * 24);

  return selisihHari > selisih;
};

export const encryptData = async (
  data: string,
  key: Uint8Array
): Promise<string> => {
  const encodedData = new TextEncoder().encode(data);
  const encodedKey = await crypto.subtle.importKey(
    "raw",
    key,
    "AES-CBC",
    false,
    ["encrypt"]
  );
  const encryptedData = await crypto.subtle.encrypt(
    { name: "AES-CBC", iv: crypto.getRandomValues(new Uint8Array(16)) },
    encodedKey,
    encodedData
  );

  // Encode hasil enkripsi untuk URL dengan menggunakan metode base64url
  const encodedEncryptedData = new Uint8Array(encryptedData);
  return btoa(String.fromCharCode.apply(null, Array.from(encodedEncryptedData)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

export function capitalize(str: string) {
  // Pastikan str tidak null atau undefined
  if (!str) {
    return str;
  }

  // Ubah huruf pertama menjadi besar dan sisanya kecil
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const getLocation = async () => {
  const isWeb = localStorage.getItem("isWeb");
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  if (isWeb) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          reject(error);
        },
        options
      );
    });
  } else {
    const latitude = localStorage.getItem("location-latitude");
    const longitude = localStorage.getItem("location-longitude");

    return {
      latitude,
      longitude,
    };
  }
};

export const greet = () => {
  const currentTime = moment().tz("Asia/Jakarta");
  const hour = currentTime.hours();

  if (hour >= 0 && hour < 5) {
    return "Selamat Malam";
  } else if (hour >= 5 && hour < 12) {
    return "Selamat Pagi";
  } else if (hour >= 12 && hour < 18) {
    return "Selamat Siang";
  } else {
    return "Selamat Malam";
  }
};

export const markerIcon = new L.Icon({
  iconUrl: "/marker-icon.png",
  iconSize: [25, 31], // ukuran ikon yang lebih kecil
  iconAnchor: [12, 31], // titik ikon yang akan sesuai dengan lokasi marker
  popupAnchor: [0, -31],
});
