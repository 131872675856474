import { Field, Form, Formik } from "formik";
import {
  ChangeEvent,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { formatRibuan } from "../../../../../../const";
import {
  cashFlowInSchema,
  iInitialValuesIn,
  initialValuesIn,
  submitStoreCashFlowIn,
  typePembayaran,
} from "./interface";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface modalCashFlowProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

const ModalCashFlow: FC<modalCashFlowProps> = ({
  isOpen,
  setIsOpen,
  setIsEnableModal,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { uuid: idProyek } = useParams();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({ queryKey: ["cashflow-in"] });
    }, 150);
  };

  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "90dvh",
        }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Pemasukan</h4>
          <Formik
            initialValues={initialValuesIn}
            onSubmit={(values: iInitialValuesIn) => {
              submitStoreCashFlowIn(
                values,
                selectedFile,
                idProyek,
                handleCloseModal
              );
            }}
            validationSchema={cashFlowInSchema}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="pengirim"
                    id="c1"
                    className="form-control rounded-xs"
                    placeholder="Nama Pengirim"
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Pengirim
                  </label>
                  <span>(required)</span>
                  {errors.pengirim && touched.pengirim && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.pengirim}
                    </div>
                  )}
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="text"
                    inputMode="numeric"
                    name="jumlah"
                    id="c5"
                    className="form-control rounded-xs"
                    placeholder="Jumlah"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formatRibuan(event),
                        setFieldValue("jumlah", event.target.value);
                    }}
                  />
                  <label htmlFor="c5" className="color-theme">
                    Jumlah
                  </label>
                  {errors.jumlah && touched.jumlah && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.jumlah}
                    </div>
                  )}
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    as="select"
                    name="tipe_pembayaran"
                    id="c7"
                    className="form-select rounded-xs"
                    aria-label="Floating label select example"
                  >
                    <option value="" disabled>
                      Silahkan Pilih
                    </option>
                    {typePembayaran.map((payment, paymentKey) => {
                      return (
                        <option key={paymentKey} value={payment.value}>
                          {payment.label}
                        </option>
                      );
                    })}
                    <option value="Cek, BG/giro">Cek, BG/giro</option>
                  </Field>
                  <label htmlFor="c7" className="color-theme">
                    Tipe Pembayaran
                  </label>
                  <span>(required)</span>
                  {errors.tipe_pembayaran && touched.tipe_pembayaran && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.tipe_pembayaran}
                    </div>
                  )}
                </div>

                {values.tipe_pembayaran !== "cash" &&
                  values.tipe_pembayaran !== "Cek, BG/giro" &&
                  values.tipe_pembayaran !== "" && (
                    <div className="form-custom form-floating mb-3">
                      <Field
                        type="text"
                        name="informasi"
                        id="c9"
                        className="form-control rounded-xs"
                        placeholder="Informasi"
                      />
                      <label htmlFor="c9" className="color-theme">
                        Informasi
                      </label>
                      {errors.informasi && touched.informasi ? (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.informasi}
                        </div>
                      ) : (
                        <p className="mb-3 font-11">
                          harap masukkan informasi yang diperlukan seperti{" "}
                          <strong className="text-danger">
                            nomor rekening, kartu kredit, atau data pembayaran
                          </strong>{" "}
                          yang sesuai.
                        </p>
                      )}
                    </div>
                  )}
                {values.tipe_pembayaran !== "cash" &&
                  values.tipe_pembayaran !== "" &&
                  values.tipe_pembayaran === "Cek, BG/giro" && (
                    <>
                      <div className="form-custom mb-3 form-floating">
                        <Field
                          type="date"
                          name="date_received"
                          id="c8"
                          className="form-control rounded-xs"
                          placeholder="Tanggal Diterima"
                        />
                        <label htmlFor="c8" className="color-theme">
                          Tanggal Diterima
                        </label>
                        {errors.date_received && touched.date_received && (
                          <div
                            style={{ display: "block" }}
                            className="invalid-feedback"
                          >
                            {errors.date_received}
                          </div>
                        )}
                      </div>
                      <div className="form-custom mb-3 form-floating">
                        <Field
                          type="text"
                          name="no_cek"
                          id="c8"
                          className="form-control rounded-xs"
                          placeholder="No. Cek"
                        />
                        <label htmlFor="c8" className="color-theme">
                          No. Cek
                        </label>
                        {errors.no_cek && touched.no_cek && (
                          <div
                            style={{ display: "block" }}
                            className="invalid-feedback"
                          >
                            {errors.no_cek}
                          </div>
                        )}
                      </div>
                      <div className="form-custom mb-3 form-floating">
                        <Field
                          type="text"
                          name="bank_name"
                          id="c8"
                          className="form-control rounded-xs"
                          placeholder="Nama Bank"
                        />
                        <label htmlFor="c8" className="color-theme">
                          Nama Bank
                        </label>
                        {errors.bank_name && touched.bank_name && (
                          <div
                            style={{ display: "block" }}
                            className="invalid-feedback"
                          >
                            {errors.bank_name}
                          </div>
                        )}
                      </div>
                      <div className="form-custom mb-3 form-floating">
                        <Field
                          type="date"
                          name="date_disbursement"
                          id="c8"
                          className="form-control rounded-xs"
                          placeholder="Tanggal Pencairan"
                        />
                        <label htmlFor="c8" className="color-theme">
                          Tanggal Pencairan
                        </label>
                        {errors.date_disbursement && touched.date_disbursement && (
                          <div
                            style={{ display: "block" }}
                            className="invalid-feedback"
                          >
                            {errors.date_disbursement}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="text"
                    name="penerima"
                    id="c8"
                    className="form-control rounded-xs"
                    placeholder="Penerima"
                  />
                  <label htmlFor="c8" className="color-theme">
                    Penerima
                  </label>
                  {errors.penerima && touched.penerima && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.penerima}
                    </div>
                  )}
                </div>
                <div className="form-field form-name mt-3">
                  <label
                    htmlFor="f2"
                    className="contactMessageTextarea color-theme"
                  >
                    FILE
                    <span>(optional)</span>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="f2"
                    className="form-control mb-0"
                    onChange={handleFileChange}
                  />
                </div>
                <p className="mb-0 font-12">
                  Silahkan masukan gambar atau file untuk bukti lainya.
                </p>
                <div className="d-flex" style={{ gap: 10 }}>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "70%" }}
                    type="submit"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalCashFlow;
