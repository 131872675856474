import { useParams } from "react-router-dom";
import FormLaporanGeneral from "../../../../assets/components/formLaporan";

const PageReportItems = () => {
  const { uuid: idProyek } = useParams();

  return (
    <>
      <div className="card card-style">
        <div className="content">
          <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
          <h2 className="font-700 pb-0">Items Masuk</h2>
          <FormLaporanGeneral urlLaporan={`warehouse/items-masuk/${idProyek}`} />
        </div>
      </div>
      <div className="card card-style">
        <div className="content">
          <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
          <h2 className="font-700 pb-0">Items Keluar</h2>
          <FormLaporanGeneral urlLaporan={`warehouse/items-keluar/${idProyek}`} />
        </div>
      </div>
      <div className="card card-style">
        <div className="content">
          <h5 className="color-highlight font-13 mb-n1">Laporan</h5>
          <h2 className="font-700 pb-0">Penyesuaian Items</h2>
          <FormLaporanGeneral urlLaporan={`warehouse/items-adjustment/${idProyek}`} />
        </div>
      </div>
    </>
  );
};

export default PageReportItems;
