import { Field, Form, Formik } from "formik";
import Layout from "../../assets/components/layout";

import { TimePicker } from "react-ios-time-picker";
import { Circle, MapContainer, Marker, TileLayer } from "react-leaflet";

import { useEffect, useState } from "react";
import GetLocation from "./getLocation";
import {
  iInitialValuesSettingAbsensi,
  initialValuesSettingAbsensi,
  settingAbsensiProps,
  useFetchSettingAbsensi,
  useStoreUpdateSettingAbsensi,
} from "./interface";
import Preloader from "../../assets/components/preloader";
import { generatePostEncrypt, markerIcon } from "../../const";
import { toast } from "../../assets/components/toast";

const SettingAbsensiPage = () => {
  const { data } = useFetchSettingAbsensi();
  const updateData = useStoreUpdateSettingAbsensi();

  const [dataSetting, setDataSetting] = useState<
    settingAbsensiProps | iInitialValuesSettingAbsensi
  >(initialValuesSettingAbsensi);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = (values: iInitialValuesSettingAbsensi) => {
    updateData.mutate(
      {
        newData: values,
        id: generatePostEncrypt(
          (dataSetting as settingAbsensiProps).uuid || ""
        ),
      },
      {
        onSuccess: (response) => {
          const result = response.data;
          if (result.error) {
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          } else {
            toast.success("Success", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          }
        },
        onError: (response) => {
          const result = response;
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      if (data.error) {
        // setIsRestirected(true);
      } else {
        setDataSetting(data.data);
      }
      setIsLoading(false);
    }
  }, [data]);

  return (
    <Layout>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="card card-style">
          <div className="content">
            <h6 className="font-700 mb-n1 color-highlight">Pengaturan</h6>
            <h1 className="pb-2">Absensi</h1>
            <div>
              <Formik
                initialValues={dataSetting}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <TimePicker
                        onChange={(values: string) =>
                          setFieldValue("jam_masuk", values)
                        }
                        value={values.jam_masuk}
                        cellHeight={55}
                      />
                      <label className="color-theme">Jam Masuk</label>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <Field
                        type="number"
                        name="toleransi_masuk"
                        id="c1"
                        className="form-control rounded-xs"
                        placeholder="Batas Toleransi Masuk"
                      />
                      <label htmlFor="c1" className="color-theme">
                        Batas Toleransi Masuk (Menit)
                      </label>
                      <span>(required)</span>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <TimePicker
                        onChange={(values: string) =>
                          setFieldValue("jam_keluar", values)
                        }
                        value={values.jam_keluar}
                        cellHeight={55}
                      />
                      <label className="color-theme">Jam Keluar</label>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <Field
                        type="number"
                        name="radius"
                        id="c1"
                        className="form-control rounded-xs"
                        placeholder="Batas Jarak"
                      />
                      <label htmlFor="c1" className="color-theme">
                        Batas Jarak
                      </label>
                      <span>(required)</span>
                    </div>
                    <MapContainer
                      center={[
                        parseFloat(values.latitude),
                        parseFloat(values.longitude),
                      ]}
                      zoom={40}
                      scrollWheelZoom={false}
                      zoomControl={false}
                      boxZoom={false}
                      dragging={true}
                      tap={false}
                      touchZoom={true}
                      className="map-setting"
                      doubleClickZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}"
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}
                      />
                      <Marker
                        icon={markerIcon}
                        position={[
                          parseFloat(values.latitude),
                          parseFloat(values.longitude),
                        ]}
                      />
                      <Circle
                        center={[
                          parseFloat(values.latitude),
                          parseFloat(values.longitude),
                        ]}
                        radius={values.radius}
                        pathOptions={{
                          color: "red",
                          fillColor: "#f04",
                          fillOpacity: 0.3,
                        }}
                      />
                      <GetLocation setFieldValue={setFieldValue} />
                    </MapContainer>
                    <p className="mb-0 mt-2">
                      <b>
                        Silahkan kamu klik di peta untuk menentukan lokasi
                        absensi.
                      </b>
                    </p>
                    <button
                      className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                      style={{ width: "100%" }}
                      type="submit"
                    >
                      Simpan Data
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SettingAbsensiPage;
