import { useProfile } from "../../const";
import DashboardKaryawanProyek from "./karyawanProyek";
import DashboardKaryawanSober from "./karyawanSober";
import DashboardSuperAdmin from "./superAdmin";

const DashboardPage = () => {
  const { data: profile } = useProfile();

  return (
    <>
      {profile && profile.user.roles === "karyawan" && <DashboardKaryawanSober profile={profile} />}
      {profile && profile.user.roles === "penaggung-jawab-proyek" && <DashboardKaryawanProyek profile={profile} />}
      {profile && profile.user.roles === "super-admin" && <DashboardSuperAdmin />}
    </>
  );
};

export default DashboardPage;
