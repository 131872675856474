/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import ModalSubmissionProyek from "./modal";
import { useFetchSubmissionKaryawanProyek } from "./interface";
import { useParams } from "react-router-dom";
import { cashFlowFilter } from "../pages/cashFlow/interface";
import ButtonFilterRange from "../../../../../assets/components/filterRange";
import moment from "moment";
import ButtonDelete from "../../../../../assets/components/ButtonDelete";
import { BiEdit } from "react-icons/bi";

const ProyekSubmissionPage = () => {
  const { uuid: idProyek } = useParams();
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data, refetch } = useFetchSubmissionKaryawanProyek(
    selectedFilter,
    idProyek,
    dateRange
  );

  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [listSubmission, setListSubmission] = useState<any[]>([]);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  useEffect(() => {
    if (data) {
      const result = data.data;
      setListSubmission(result);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setListSubmission(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };
  return (
    <>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "95%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div
        className="d-flex py-2 px-3 slide-top"
        style={{ alignItems: "center" }}
      >
        <div>
          <h3 className="mb-0">Riwayat</h3>
        </div>
      </div>
      <div className="px-3">
        {listSubmission.length < 1 ? (
          <div
            className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3"
            style={{
              borderRadius: 15,
              animationDelay: Math.min(1 * 200, 1000) + "ms",
            }}
          >
            <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
              Belum ada riwayat
            </h5>
          </div>
        ) : (
          listSubmission.map((submission, submissionKey) => {
            return (
              <div
                className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 ${
                  submissionKey !== 0 && "mt-3"
                } `}
                key={submissionKey}
                style={{
                  animationDelay: Math.min(submissionKey * 200, 1000) + "ms",
                  borderRadius: 15,
                }}
              >
                <div className="d-flex">
                  <div className="ps-2 align-self-center d-flex flex-column">
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                      {submission.karyawan.name}{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        ({submission.karyawan.jabatan.name})
                      </span>
                    </h5>
                    <span className="ps-1 mb-0 font-400 font-12">
                      {moment(new Date(submission.created_at)).format("ll")}{" "}
                      {submission.jam}
                      <span
                        className={`ms-2 ${
                          submission.status === "MASUK"
                            ? "color-green-dark"
                            : "color-red-dark"
                        }`}
                      >
                        {submission.status}
                      </span>
                    </span>
                    <span className="ps-1 mb-0 font-400 font-12 mt-n2">
                      Keterangan : {submission.note}
                    </span>
                  </div>
                  {submission.is_edit && (
                    <div
                      className="ms-auto"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="btn gradient-blue btn-s text-start me-2"
                        onClick={() => {
                          setIsDataEdit(submission), handleOpen();
                        }}
                      >
                        <BiEdit />
                      </button>
                      <ButtonDelete
                        className="btn gradient-red btn-s text-start"
                        url="proyek/submission-present"
                        KEY="submission-present-proyek"
                        values={submission.uuid}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
      <button
        className="btn btn-fab slide-top gradient-blue shadow-bg shadow-bg-xs"
        onClick={() => {
          setIsDataEdit(null), handleOpen();
        }}
      >
        <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
      </button>
      {isEnableModal && (
        <ModalSubmissionProyek
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isDataEdit={isDataEdit}
        />
      )}
    </>
  );
};

export default ProyekSubmissionPage;
