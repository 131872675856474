/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { API_CLIENT } from "../../../../../../../const";
import * as Yup from "yup";
import { toast } from "../../../../../../../assets/components/toast";

const fetchDetailsInventaris = async (uuid: string | undefined) => {
  const response = await API_CLIENT.get<any>(`/proyek/inventaris/${uuid}`);
  return response.data;
};

export const useFetchDetailsInventaris = (uuid: string | undefined) => {
  return useQuery({
    queryKey: ["details-inventaris"],
    queryFn: () => fetchDetailsInventaris(uuid),
  });
};

export const stockInventarisProyekSchema = Yup.object().shape({
  jumlah: Yup.number().min(1).required("Please insert a value"),
});

export const handleSubmitStockInventarisProyek = (
  values: { jumlah: string },
  idInventaris: string | undefined,
  handleCloseModal: () => void,
  uuid?: string | null
) => {
  const dataToSend = {
    ...values,
    idInventaris: idInventaris,
    uuid: uuid,
  };

  API_CLIENT.post("/proyek/inventaris-stock", dataToSend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
      }
    })
    .catch((err) => console.log(err));
};

const fetchStockInventaris = async (
  filter: string,
  inventaris_id: string | undefined,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/stock-inventaris-list?filter=${filter}&inventaris_id=${inventaris_id}&range=${range}`
  );
  return response.data;
};

export const useFetchStockInventaris = (
  filter: string,
  inventaris_id: string | undefined,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["stock-inventaris"],
    queryFn: () => fetchStockInventaris(filter, inventaris_id, dateRange),
  });
};
