/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { MenuPlacement } from "react-select";

import AsyncSelect from "react-select/async";
import { Option, generateUniqueId, promiseOptions } from "./interface";

const FormSelectWithouthFormik: React.FC<any> = (props) => {
  const {
    urlSelect,
    className,
    menuPlacement,
    onCallback,
    isClearableAfterSelect = false,
  } = props;

  const [value, setValue] = useState<
    { value: string; label: string } | { value: string; label: string }[] | null
  >(null);
  const [isPlacement] = useState<MenuPlacement>(
    (menuPlacement as MenuPlacement) || "bottom"
  );

  const handleCallBack = (selectValue: string) => {
    onCallback(selectValue);
    isClearableAfterSelect && setValue(null);
  };

  return (
    <>
      <AsyncSelect
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={value}
        menuPlacement={isPlacement}
        onChange={(selectedOption) => {
          const selectValue =
            (selectedOption as Option)?.value ||
            (selectedOption as unknown as string);

          setValue(selectedOption as Option);
          onCallback && handleCallBack(selectValue);
        }}
        className={`${className ? className : ""}`}
        cacheOptions
        id={generateUniqueId()}
        loadOptions={(values) => promiseOptions(values, urlSelect)}
        defaultOptions
        {...props}
      />
    </>
  );
};

export { FormSelectWithouthFormik };
