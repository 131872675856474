/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import ReactApexChart from "react-apexcharts";

interface chartProyekProps {
  xaxis?: {
    categories?: [];
  };
  series?: [];
}

const ChartProyek: FC<chartProyekProps> = (props) => {
  const series = props.series || [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91, 60, 30, 41, 32],
    },
    {
      name: "series-2",
      data: [25, 30, 15, 30, 29, 12, 60, 41, 60, 70, 91, 12],
    },
  ];

  const options = {
    chart: {
      id: "basic-bar",
      stacked: false,
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return new Intl.NumberFormat().format(parseInt(value));
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: props?.xaxis?.categories || [
        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
      ],
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 0,
      left: 0,
      blur: 3,
      opacity: 0.5,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "10px",
        isDumbbell: true,
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="area" />;
};

export default ChartProyek;
