/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient, useQuery } from "@tanstack/react-query";
import {
  API_CLIENT,
  caesarEncrypt,
  generateKeyRandom,
  textToNumber,
} from "../../../../../../const";
import { toast } from "../../../../../../assets/components/toast";
import { iInitialValuesManagement } from "../../../interface";
import { NavigateFunction } from "react-router-dom";

const fetchSettingMangement = async (idProyek: string | null) => {
  const response = await API_CLIENT.get<any>(`/proyek/management/${idProyek}`);
  return response.data;
};

export const useFetchSettingMangement = (idProyek: string | null) => {
  return useQuery({
    queryKey: ["general-setting-proyek"],
    queryFn: () => fetchSettingMangement(idProyek),
  });
};

export const submitUpdateManagementProyek = async (
  values: iInitialValuesManagement,
  proyek_id: string | undefined,
  queryClient: QueryClient
) => {
  API_CLIENT.post(`/proyek/management/${proyek_id}`, values, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      queryClient.invalidateQueries({ queryKey: ["general-setting-proyek"] });
    })
    .catch((err) => console.log(err));
};

export const handleDeleteProyek = (
  idProyek: string | undefined,
  queryClient: QueryClient,
  navigation: NavigateFunction
) => {
  const dataSend = caesarEncrypt(idProyek || "", 20);
  const keyLength = idProyek && idProyek.length;
  const keyShuffle = generateKeyRandom(keyLength || 0);
  const data = btoa(textToNumber(dataSend, keyShuffle) + ";" + keyShuffle);

  API_CLIENT.delete(`/proyek/management/${data}`)
    .then((response) => {
      const result = response.data;
      console.log(result);
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ["proyek-management"] });
        navigation("/proyek-management", { replace: true });
        setTimeout(() => {
          toast.success("Success", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        }, 350);
      }
    })
    .catch((err) => console.log(err));
};
