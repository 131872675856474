/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { cashFlowFilter } from "./interface";
import { useFetchPengeluaranProyek } from "./interface";
import { toThousand } from "../../../../../../const";
import { useParams } from "react-router-dom";
import ButtonFilterRange from "../../../../../../assets/components/filterRange";

const CashFlowOut = () => {
  const { uuid } = useParams();
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data, refetch } = useFetchPengeluaranProyek(
    selectedFilter,
    uuid ? uuid : "",
    dateRange
  );

  const [dataHistory, setDataHistory] = useState([]);
  const [keyGlobal, setKeyGlobal] = useState<number>(1);
  const [totalPengeluaran, setTotalPengeluaran] = useState<number>(1);

  useEffect(() => {
    document.body.style.background = "#fafafa";
    if (data) {
      const result = data.data;
      setDataHistory(result.data);
      setTotalPengeluaran(result.totalPengeluaran);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataHistory(result.data);
        setTotalPengeluaran(result.totalPengeluaran);
        setKeyGlobal((prev) => prev + 1);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div className="card card-style slide-top" style={{ borderRadius: 10 }}>
        <div className="content">
          <h6 className="font-700 font-14 mb-2 color-highlight">
            Total Pengeluaran
          </h6>
          <h2 className="pb-0 font-22">
            <span className="font-600">Rp</span>.{" "}
            {toThousand(totalPengeluaran.toString())}
          </h2>
        </div>
      </div>

      <div className="d-flex px-4 slide-top" style={{ alignItems: "center" }}>
        <div>
          <h3 className="mb-0">Riwayat Transaksi</h3>
        </div>
        {/* <div className="align-self-center ms-auto">
          <button className="btn btn-xxs gradient-red shadow-bg-m text-start">
            Filter
            <i className="bi bi-chevron-down ps-3 ms-n1"></i>
          </button>
        </div> */}
      </div>
      <div>
        {dataHistory.length < 1 ? (
          <div
            className="d-flex justify-content-center py-5 mt-3"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <img
              src="/images/no-data.svg"
              alt="no transaction"
              style={{ width: "50%" }}
              className="slide-top"
            />
            <span
              className="ps-1 mb-0 font-400 mt-4 font-18 slide-top"
              style={{ animationDelay: "200ms" }}
            >
              Belum ada transaksi.
            </span>
          </div>
        ) : (
          dataHistory.map((history: any, historyKey) => {
            const ppn = history.ppn ? history.ppn : 0;
            const totalPayment =
              parseInt(history.harga, 10) * history.qty + parseInt(ppn, 10);
            return (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-3 mb-3"
                key={keyGlobal + historyKey}
                style={{
                  borderRadius: 10,
                  // minHeight: 83,
                  animationDelay: Math.min(historyKey * 200, 1000) + "ms",
                }}
              >
                <div
                  className="d-flex px-2 pb-2 pt-2 pe-3"
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <div
                    className="ps-2 align-self-center"
                    style={{ width: "40%" }}
                  >
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15 text-hidden-1">
                      {history.name}
                    </h5>
                    <span className="ps-1 mb-0 mt-1 font-500 opacity-70 font-12 text-hidden-1">
                      {history.supplier}
                    </span>
                    <span className="ps-1 mt-n2 mb-0 font-400 opacity-70 font-12 text-hidden-1">
                      {history.formatted_created_at}
                    </span>
                    {history.file && (
                      <>
                        <br />
                        <a href={history.file} className="ps-1">
                          Bukti Pengeluaran
                        </a>
                      </>
                    )}
                  </div>
                  <div className="ms-auto">
                    <h6 className="ps-1 mb-0 line-height-xs pt-1 font-16">
                      Rp. {toThousand(totalPayment.toString())}
                    </h6>
                  </div>
                </div>
              </div>
            );
          })
        )}
        <div style={{ marginBottom: "4.3rem" }} />
      </div>
    </>
  );
};

export default CashFlowOut;
