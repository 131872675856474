/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";
import FormSelect from "../../../../../../../assets/components/form/formSelect";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getListPeminjamanInventarisProyek,
  handleSubmitPengembalianBarang,
} from "./interface";
import { toast } from "../../../../../../../assets/components/toast";

const FormPengembalianInventarisProyek = () => {
  const { uuid: idProyek } = useParams();
  const navigation = useNavigate();

  const [selectKaryawan, setSelectKaryawan] = useState<string>("");
  const [selectedInventaris, setSelectedInventaris] = useState<any[]>([]);
  const [listPeminjaman, setListPeminjaman] = useState<any[]>([]);

  const [globalKey, setGlobalKey] = useState<number>(1);
  const [isRefresh, setIsRefresh] = useState<number>(0);

  useEffect(() => {
    if (selectKaryawan) {
      getListPeminjamanInventarisProyek(
        selectKaryawan,
        idProyek,
        setListPeminjaman
      );
      setGlobalKey((prev) => prev + 1);
      setSelectedInventaris([]);
    }
  }, [selectKaryawan, idProyek, isRefresh]);

  const handleSelectedKaryawan = (inventari: any, jumlah: number) => {
    setSelectedInventaris((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.value === inventari.uuid
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, jumlah: jumlah } : item
        );
      } else {
        return [
          ...prev,
          {
            value: inventari.uuid,
            max: inventari.jumlah,
            name: inventari.inventaris.name,
            jumlah: jumlah,
          },
        ];
      }
    });
  };

  const handleRemoveSelect = (inventari: any) => {
    const updatedSelectedKaryawan = selectedInventaris.filter(
      (item) => item.value !== inventari.uuid
    );
    setSelectedInventaris(updatedSelectedKaryawan);
  };

  const handleSubmit = () => {
    selectedInventaris.map((inventaris) => {
      if (inventaris.jumlah > inventaris.max) {
        return toast.error(
          "Error",
          `Jumlah pengembalian ${inventaris.name} melebihi jumlah peminjaman!!`,
          {
            autoClose: 3000,
            closeOnClick: true,
            fullWidth: true,
          }
        );
      }
    });
    handleSubmitPengembalianBarang(selectedInventaris, idProyek,setIsRefresh);
  };

  return (
    <>
      <div className="card card-style mb-3 slide-top" style={{ borderRadius: 10 }}>
        <div className="content">
          <div className="form-field form-name slide-top mb-1">
            <label className="contactNameField color-theme">
              Tanggal Pengembalian
              <span>(required)</span>
            </label>
            <input
              type="text"
              defaultValue={moment().tz("Asia/Jakarta").format("LL")}
              readOnly
              className="round-small"
            />
          </div>
          <div className="form-field form-name slide-top mb-0">
            <label className="contactNameField color-theme">
              Karyawan
              <span>(required)</span>
            </label>
            <FormSelect
              urlSelect={`proyek/karyawan-proyek/${idProyek}`}
              name="karyawan_id"
              isFormik={false}
              className="round-small z-9"
              menuPlacement="top"
              onCallback={(value) => setSelectKaryawan(value)}
            />
          </div>
          <p className="mb-0 font-12">
            Silahkan pilih karyawan terlebih dahulu.
          </p>
        </div>
      </div>
      {selectKaryawan && listPeminjaman.length < 1 && (
        <div
          className="card card-style slide-top"
          style={{
            borderRadius: 10,
            animationDelay: Math.min(globalKey + 1 * 200, 1000) + "ms",
          }}
        >
          <div className="content">
            <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
              Tidak ada data peminjaman.
            </h5>
          </div>
        </div>
      )}
      {selectKaryawan && listPeminjaman.length > 0 && (
        <div
          className="card card-style slide-top"
          style={{
            borderRadius: 10,
            animationDelay: Math.min(globalKey + 1 * 200, 1000) + "ms",
          }}
        >
          <div className="content">
            <h6 className="font-700 mb-n1 color-highlight">Data</h6>
            <h4 className="pb-1">Peminjaman</h4>
            {listPeminjaman.map((peminjaman, peminjamanKey) => {
              const isKaryawanAlreadySelected = selectedInventaris.some(
                (item) => item.value === peminjaman.uuid
              );
              const karyawanAlSelected = selectedInventaris.find(
                (item) => item.value === peminjaman.uuid
              );
              return (
                <div
                  className={`slide-right shadow-m mt-2 mb-2 py-2 px-2 ${
                    isKaryawanAlreadySelected ? "bg-blue-dark" : "bg-theme"
                  } ${peminjamanKey !== 0 && "mt-3"} `}
                  key={peminjamanKey}
                  style={{
                    animationDelay:
                      Math.min(globalKey + peminjamanKey * 200, 1000) + "ms",
                    borderRadius: 10,
                  }}
                >
                  <div
                    className="d-flex px-2 pb-2 pt-2 pe-3"
                    style={{ alignItems: "center", height: "100%" }}
                  >
                    <div
                      className="ps-2 align-self-center pe-1"
                      style={{ width: "78%" }}
                    >
                      <div className="d-flex flex-column">
                        <h5
                          className={`ps-1 mb-0 font-600 font-13 ${
                            isKaryawanAlreadySelected && "color-white"
                          }`}
                        >
                          {peminjaman.inventaris.name} x {peminjaman.jumlah}
                        </h5>
                        <span className="ps-1 mb-0 font-400 font-12 mt-n1">
                          {moment(new Date(peminjaman.created_at)).format("ll")}
                        </span>
                      </div>
                      {isKaryawanAlreadySelected && (
                        <div className="form-field form-name mt-1 mb-1">
                          <input
                            min={1}
                            max={peminjaman.jumlah}
                            type="number"
                            className="round-small bg-theme mb-0"
                            defaultValue={karyawanAlSelected.jumlah}
                            onChange={(value: any) =>
                              handleSelectedKaryawan(
                                peminjaman,
                                value.target.value
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="ms-auto">
                      {!isKaryawanAlreadySelected ? (
                        <button
                          className="btn btn-xs gradient-green shadow-bg shadow-bg-s text-start me-2"
                          onClick={() =>
                            handleSelectedKaryawan(
                              peminjaman,
                              peminjaman.jumlah
                            )
                          }
                        >
                          <div className="bi bi-check-lg font-20"></div>
                        </button>
                      ) : (
                        <button
                          className="btn btn-xs gradient-red shadow-bg shadow-bg-s text-start"
                          onClick={() => handleRemoveSelect(peminjaman)}
                        >
                          <div className="bi bi-x-circle-fill font-20"></div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {selectedInventaris.length > 0 && (
              <button
                className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3 slide-top"
                style={{
                  width: "100%",
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
                onClick={() => handleSubmit()}
              >
                Simpan Data
              </button>
            )}
          </div>
        </div>
      )}
      <button
        className="btn btn-fab slide-top gradient-red shadow-bg shadow-bg-xs"
        onClick={() =>
          navigation({
            search: `active-tab=ou3oBY9t7QhG9eydxVOts7KIobN19iqh`,
          })
        }
        style={{ zIndex: 999 }}
      >
        <i className="bi bi-x-lg" style={{ fontSize: 18 }}></i>
      </button>
    </>
  );
};

export default FormPengembalianInventarisProyek;
