/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_CLIENT } from "../../const";
import { dataRowJabatan } from "../position/interface";
import * as Yup from "yup";

export const getJabatanUser = async () => {
  const response = await API_CLIENT.get("jabatan");
  const data = response.data;
  const newArr: { value: string; label: string }[] = [];

  data.data.map((jabatan: dataRowJabatan) => {
    newArr.push({
      value: jabatan.uuid,
      label: jabatan.name,
    });
  });

  return newArr;
};

export interface iInitialValuesUsers {
  name: string;
  roles: string;
  jabatan: string;
  status: string;
  email: string;
  gapok: number;
}

export const initialValuesUsers: iInitialValuesUsers = {
  name: "",
  roles: "",
  jabatan: "",
  status: "",
  email: "",
  gapok: 0,
};

export interface dataRowUser {
  no: number;
  name: string;
  id: number;
  uuid: string;
  roles: string;
  profile: string;
  email: string;
  jabatan: string;
  status: string;
  gapok: number;
  jabatan_name: number;
}

export const usersSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  roles: Yup.string().required("Please insert a value."),
  jabatan: Yup.string().required("Please insert a value."),
  status: Yup.string().required("Please insert a value."),
  email: Yup.string()
    .email()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "email must be a valid email"
    )
    .required("Please insert a value."),
});

export const useStoreUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData: iInitialValuesUsers) => {
      return API_CLIENT.post("/users", newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};

export const useStoreUpdateUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: {
      newData: iInitialValuesUsers;
      id: string;
    }) => {
      const { newData, id } = variables;
      return API_CLIENT.patch(`/users/${id}`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};

const fetchUsers = async () => {
  const response = await API_CLIENT.get<any>("/users");
  return response.data;
};

export const useFetchUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });
};

export const roleUser = [
  {
    value: "super-admin",
    label: "Admin",
  },
  // {
  //   value: "admin",
  //   label: "Admin",
  // },
  {
    value: "karyawan",
    label: "Karyawan",
  },
];
