/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { cashFlowFilter } from "./interface";
import { useFetchPemasukanProyek } from "./interface";
import { toThousand } from "../../../../../../const";
import { useParams } from "react-router-dom";
import ButtonFilterRange from "../../../../../../assets/components/filterRange";

import moment from "moment-timezone";

const CashIn = () => {
  const { uuid } = useParams();
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data, refetch } = useFetchPemasukanProyek(
    selectedFilter,
    uuid ? uuid : "",
    dateRange
  );

  const [dataHistory, setDataHistory] = useState([]);
  const [keyGlobal, setKeyGlobal] = useState<number>(1);
  const [totalPengeluaran, setTotalPengeluaran] = useState<number>(1);

  useEffect(() => {
    document.body.style.background = "#fafafa";
    if (data) {
      const result = data.data;
      setDataHistory(result.data);
      setTotalPengeluaran(result.totalPengeluaran);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataHistory(result.data);
        setTotalPengeluaran(result.totalPengeluaran);
        setKeyGlobal((prev) => prev + 1);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div className="card card-style slide-top" style={{ borderRadius: 10 }}>
        <div className="content">
          <h6 className="font-700 font-14 mb-2 color-highlight">
            Total Pemasukan
          </h6>
          <h2 className="pb-0 font-22">
            <span className="font-600">Rp</span>.{" "}
            {toThousand(totalPengeluaran.toString())}
          </h2>
        </div>
      </div>

      <div className="d-flex px-4 slide-top" style={{ alignItems: "center" }}>
        <div>
          <h3 className="mb-0">Riwayat Transaksi</h3>
        </div>
      </div>
      <div>
        {dataHistory.length < 1 ? (
          <div
            className="d-flex justify-content-center py-5 mt-3"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <img
              src="/images/no-data.svg"
              alt="no transaction"
              style={{ width: "50%" }}
              className="slide-top"
            />
            <span
              className="ps-1 mb-0 font-400 mt-4 font-18 slide-top"
              style={{ animationDelay: "200ms" }}
            >
              Belum ada transaksi.
            </span>
          </div>
        ) : (
          dataHistory.map((history: any, historyKey) => {
            const totalPayment = history.jumlah;
            return (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-3 mb-3"
                key={keyGlobal + historyKey}
                style={{
                  borderRadius: 10,
                  //   minHeight: 83,
                  animationDelay: Math.min(historyKey * 200, 1000) + "ms",
                }}
              >
                <div
                  className="d-flex px-2 pb-2 pt-2 pe-3"
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <div
                    className="ps-2 align-self-center"
                    style={{ width: "45%" }}
                  >
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15 text-hidden-1">
                      {history.pengirim}
                    </h5>
                    <div className="d-flex flex-column">
                      <span className="ps-1 mb-0 font-400 opacity-70 font-12">
                        {history.formatted_created_at}
                      </span>
                      <span className="ps-1 mb-0 font-400 opacity-70 font-12">
                        Pembayaran : {history.tipe_pembayaran}
                      </span>
                      {history.tipe_pembayaran === "Cek, BG/giro" && (
                        <>
                          <span className="ps-1 mb-0 mt-n1 font-400 opacity-70 font-12">
                            Tanggal Diterima :{" "}
                            {moment(new Date(history.date_received))
                              .tz("Asia/Jakarta")
                              .format("ll")}
                          </span>
                          <span className="ps-1 mb-0 mt-n1 font-400 opacity-70 font-12">
                            Tanggal Cair :{" "}
                            {moment(new Date(history.date_disbursement))
                              .tz("Asia/Jakarta")
                              .format("ll")}
                          </span>
                          <span className="ps-1 mb-0 mt-n1 font-400 opacity-70 font-12">
                            Bank : {history.bank_name}
                          </span>
                          <span className="ps-1 mb-0 mt-n1 font-400 opacity-70 font-12">
                            No. Cek : {history.no_cek}
                          </span>
                        </>
                      )}
                    </div>
                    {history.file && (
                      <>
                        <br />
                        <a href={history.file} className="ps-1">
                          Bukti Pengeluaran
                        </a>
                      </>
                    )}
                  </div>
                  <div className="ms-auto">
                    <h6 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-2">
                      Rp. {toThousand(totalPayment.toString())}
                    </h6>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default CashIn;
