/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import {
  initialValuesManagement,
  managementProyekSchema,
} from "../../../interface";
import { ChangeEvent, useEffect, useState } from "react";
import { formatRibuan, toThousand } from "../../../../../../const";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleDeleteProyek,
  submitUpdateManagementProyek,
  useFetchSettingMangement,
} from "./interface";
import Preloader from "../../../../../../assets/components/preloader";
import FormSelect from "../../../../../../assets/components/form/formSelect";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";

const SettingManagementProyek = () => {
  const queryClient = useQueryClient();
  const { uuid: idProyek } = useParams();
  const navigation = useNavigate();
  const { data } = useFetchSettingMangement(idProyek || null);

  const [generalConfig, setGeneralConfig] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isValueDelete, setIsValueDelete] = useState<string>("");

  useEffect(() => {
    document.body.style.background = "";
    if (data) {
      const result = data.data;
      setGeneralConfig(result);
      setTimeout(() => {
        setIsLoading(false);
      }, 150);
    }
  }, [data]);

  const handleOpenModalDelete = () => {
    queryClient.invalidateQueries({
      queryKey: ["general-setting-proyek"],
    });
    setTimeout(() => {
      setIsOpenModalDelete(true);
    }, 150);
  };

  return (
    <>
      {isLoading && !generalConfig ? (
        <Preloader />
      ) : (
        <>
          <div className="card card-style">
            <div className="content">
              <h6 className="font-700 mb-n1 color-highlight">General</h6>
              <h4 className="pb-2">Management Proyek</h4>
              <Formik
                initialValues={
                  generalConfig
                    ? {
                        name: generalConfig.name,
                        nilai: toThousand(generalConfig.nilai),
                        karyawan: generalConfig.listPenanggungJawab,
                      }
                    : initialValuesManagement
                }
                onSubmit={(values) => {
                  submitUpdateManagementProyek(values, idProyek, queryClient);
                }}
                validationSchema={managementProyekSchema}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <Field
                        type="text"
                        name="name"
                        id="c1"
                        className="form-control rounded-xs"
                        placeholder="Nama Proyek"
                      />
                      <label htmlFor="c1" className="color-theme">
                        Nama Proyek
                      </label>
                      {errors.name && touched.name && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.name as string}
                        </div>
                      )}
                      <span>(required)</span>
                    </div>
                    <div className="form-custom mb-3 form-floating mt-3">
                      <Field
                        type="text"
                        name="nilai"
                        id="c1"
                        inputMode="numeric"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          formatRibuan(event),
                            setFieldValue("nilai", event.target.value);
                        }}
                        className="form-control rounded-xs"
                        placeholder="Nama Proyek"
                      />
                      <label htmlFor="c1" className="color-theme">
                        Nilai Proyek
                      </label>
                      {errors.nilai && touched.nilai && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.nilai as string}
                        </div>
                      )}
                      <span>(required)</span>
                    </div>
                    <div className="form-field form-name mb-3 mt-3">
                      <label className="contactNameField color-theme">
                        Penanggung Jawab
                        <span>(required)</span>
                      </label>
                      <FormSelect
                        urlSelect="proyek/management-penanggung-jawab"
                        name="karyawan"
                        error={errors}
                        touched={touched}
                        isMulti
                        className="round-small z-9"
                      />
                    </div>
                    <button
                      style={{ width: "100%", zIndex: 1 }}
                      type="submit"
                      className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                    >
                      Update Data
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="card card-style">
            <div className="content">
              <h4 className="pb-2">Danger Zone</h4>
              <div className="d-flex flex-column">
                <h5 className="font-700 mb-n1">Hapus proyek ini</h5>
                <span className="mt-2 font-12" style={{ lineHeight: 1.4 }}>
                  Setelah Anda menghapus proyek ini, tidak ada jalan untuk
                  kembali. Harap yakin.
                </span>
                <button
                  type="button"
                  className="btn gradient-red btn-full shadow-bg shadow-bg-m mt-3"
                  onClick={() => handleOpenModalDelete()}
                >
                  Hapus proyek ini
                </button>
              </div>
            </div>
          </div>
          <Modal
            show={isOpenModalDelete}
            onHide={() => setIsOpenModalDelete(false)}
            dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
          >
            <div className="content" style={{ margin: 0 }}>
              <div
                className="d-flex align-items-center px-3 pt-2"
                style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.07)" }}
              >
                <div className="align-self-center">
                  <h4 className="font-18 font-800 mb-0">
                    Hapus {generalConfig.name}
                  </h4>
                </div>
                <div className="align-self-center ms-auto">
                  <span
                    className="icon icon-m"
                    onClick={() => setIsOpenModalDelete(false)}
                  >
                    <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex mt-3 px-3 flex-column">
                <span className="font-500">
                  Untuk konfirmasi, ketik "<strong>{generalConfig.name}</strong>
                  " pada kotak di bawah
                </span>
                <div className="form-field form-name form-danger">
                  <input
                    type="text"
                    className="round-small"
                    onChange={(event) => setIsValueDelete(event.target.value)}
                  />
                </div>
                <button
                  disabled={isValueDelete === generalConfig.name ? false : true}
                  type="button"
                  className="btn gradient-red btn-full shadow-bg shadow-bg-m mt-1 mb-3"
                  onClick={() =>
                    handleDeleteProyek(idProyek, queryClient, navigation)
                  }
                >
                  Hapus proyek ini
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default SettingManagementProyek;
