/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { API_CLIENT } from "../../../const";

export interface iInitialValuesPenanggung {
  name: string;
  status: string;
  username: string;
  password: string;
  no_tlp: string;
}

export const initialValuesPenanggung: iInitialValuesPenanggung = {
  name: "",
  status: "",
  username: "",
  password: "",
  no_tlp: "",
};

export interface dataRowUser {
  no: number;
  name: string;
  id: number;
  uuid: string;
  roles: string;
  profile: string;
  email: string;
  jabatan: string;
  status: string;
  gapok: number;
  jabatan_name: number;
}

export const penanggungJawabSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  status: Yup.string().required("Please insert a value."),
  username: Yup.string().required("Please insert a value."),
  no_tlp: Yup.string().required("Please insert a value."),
});

function shuffleName(fullName: string): string {
  const nameArray = fullName.split(' ');
  for (let i = nameArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [nameArray[i], nameArray[j]] = [nameArray[j], nameArray[i]];
  }
  return nameArray.join(' ');
}

export const generateUsername = (fullName: string): string => {
  const shuffledName = shuffleName(fullName);
  const cleanName = shuffledName.replace(/\s/g, '').toLowerCase();
  const timestamp = Date.now().toString(36).slice(-6);
  const uniqueSuffix = Math.floor(Math.random() * 1000);
  const username = `${cleanName}${timestamp}${uniqueSuffix}`;
  return username.slice(0, 15);
};

export const useStorePenanggungJawabProyek = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData: any) => {
      return API_CLIENT.post("/proyek/penanggung-jawab", newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["proyek-penanggung-jawab"] });
    },
  });
};

const fetchPenanggungJawabProyek = async () => {
  const response = await API_CLIENT.get<any>(
    "/proyek/penanggung-jawab"
  );
  return response.data;
};

export const useFetchPenanggungJawabProyek = () => {
  return useQuery({
    queryKey: ["proyek-penanggung-jawab"],
    queryFn: fetchPenanggungJawabProyek,
  });
};

export const useStoreUpdatePenanggungJawaProyek = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: {
      newData: iInitialValuesPenanggung;
      id: string;
    }) => {
      const { newData, id } = variables;
      return API_CLIENT.patch(`/proyek/penanggung-jawab/${id}`, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["proyek-penanggung-jawab"] });
    },
  });
};