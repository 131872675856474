import { useLocation } from "react-router-dom";
import Layout from "../../assets/components/layout";
import { CONFIG_APP } from "../../const";
import { useEffect, useState } from "react";
import Preloader from "../../assets/components/preloader";

const ViewReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = "hidden";
      setIsLoading(false);
    }, 250);
  }, []);

  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const searchParamsString = searchParams.toString();
  const { url, urlBack } = searchParamsObject;

  return (
    <Layout
      noPadding
      headerTitle="Laporan"
      urlBack
      urlBackTarget={"/" + urlBack}
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <iframe
        //   sandbox="allow-modals"
          className="w-100"
          style={{ marginTop: "57px", height: "calc(100dvh - 57px)" }}
          src={`${CONFIG_APP["url-server"]}report/${url}?${searchParamsString}`}
          frameBorder="0"
          scrolling="no"
        />
      )}
    </Layout>
  );
};

export default ViewReport;
