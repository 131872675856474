import { FC, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  handleSelectDateRange,
  handleSelectRange,
  iSelectionRange,
  monthList,
  selectionRange,
  yearRange,
} from "../../../pages/proyek/laporan/interface";
import { Calendar, DateRange } from "react-date-range";
import { CONFIG_APP, textToNumber } from "../../../const";

interface FormLaporanGeneralProps {
  urlLaporan: string;
}

const FormLaporanGeneral: FC<FormLaporanGeneralProps> = ({ urlLaporan }) => {
  const [activeTab, setActiveTab] = useState<string>("daily");
  const [selectDate, setSelectDate] = useState<Date>(new Date());
  const [selectDateRange, setSelectDateRange] =
    useState<iSelectionRange>(selectionRange);

  const [selectMonth, setSelectMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectYear, setSelectYear] = useState<number>(
    new Date().getFullYear()
  );

  const handleGenerateReport = () => {
    let generateUrlSearch;
    const key = new Uint8Array(32);
    const keySufix = encodeURIComponent(btoa(String.fromCharCode(...key)));

    const encryptType = textToNumber(activeTab, keySufix);
    const zeusToken = localStorage.getItem("zeus-token");
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (activeTab === "daily") {
      const dateToSend = new Date(selectDate).toLocaleDateString(
        "id-ID",
        options
      );
      const dateEncrypt = textToNumber(dateToSend, keySufix);
      generateUrlSearch = `?&type=${encryptType}&prefix=${dateEncrypt}&keySufix=${keySufix}`;
    } else if (activeTab === "month") {
      const monthEncrypt = textToNumber(selectMonth.toString(), keySufix);
      const yearEncrypt = textToNumber(selectYear.toString(), keySufix);
      generateUrlSearch = `?&type=${encryptType}&prefix=${monthEncrypt}&yearPrefix=${yearEncrypt}&keySufix=${keySufix}`;
    } else if (activeTab === "range") {
      const { startDate, endDate } = selectDateRange;
      const dateToStartSend = new Date(startDate).toLocaleDateString(
        "id-ID",
        options
      );
      const dateEndStartSend = new Date(endDate).toLocaleDateString(
        "id-ID",
        options
      );
      const dateStartEncrypt = textToNumber(dateToStartSend, keySufix);
      const dateEndEncrypt = textToNumber(dateEndStartSend, keySufix);

      generateUrlSearch = `?&type=${encryptType}&prefix=${dateStartEncrypt}&endPrefix=${dateEndEncrypt}&keySufix=${keySufix}`;
    }
    const urlFinal = `${CONFIG_APP["url-server"]}report/${urlLaporan}/${generateUrlSearch}&zeusToken=${zeusToken}`;
    window.location.href = urlFinal
  };

  return (
    <>
      <div className="tabs tabs-pill">
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k || "")}
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3 mt-3 tab-controls rounded-m"
          fill
        >
          <Tab eventKey="daily" title="Harian" tabClassName="font-12 rounded-m">
            <div
              style={{
                boxShadow: "0 5px 14px 0 rgba(0,0,0,.1)",
                borderRadius: 20,
              }}
              className="mb-2"
            >
              <Calendar
                date={selectDate}
                showDateDisplay={false}
                calendarFocus="backwards"
                onChange={(range) =>
                  handleSelectDateRange(range, setSelectDate)
                }
              />
            </div>
          </Tab>
          <Tab
            eventKey="month"
            title="Bulanan"
            tabClassName="font-12 rounded-m"
          >
            <div className="form-field form-name">
              <label className="contactNameField color-theme">Bulan</label>
              <select
                value={selectMonth}
                onChange={(event) =>
                  setSelectMonth(parseInt(event.target.value))
                }
                className="round-small form-select"
                style={{
                  padding: "0.475rem 2.25rem 0.475rem 0.75rem",
                  lineHeight: 1.8,
                }}
              >
                {monthList.map((month, monthKey) => {
                  return (
                    <option key={monthKey} value={monthKey + 1}>
                      {month}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-field form-name mt-3 mb-2">
              <label className="contactNameField color-theme">Tahun</label>
              <select
                value={selectYear}
                onChange={(event) =>
                  setSelectYear(parseInt(event.target.value))
                }
                className="round-small form-select"
                style={{
                  padding: "0.475rem 2.25rem 0.475rem 0.75rem",
                  lineHeight: 1.8,
                }}
              >
                {yearRange.map((year, yearKey) => {
                  return (
                    <option key={yearKey} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          </Tab>
          <Tab
            eventKey="range"
            title="Rentang"
            tabClassName="font-12 rounded-m"
          >
            <div
              style={{
                boxShadow: "0 5px 14px 0 rgba(0,0,0,.1)",
                borderRadius: 20,
              }}
              className="mb-2"
            >
              <DateRange
                ranges={[selectDateRange]}
                preventSnapRefocus={true}
                showDateDisplay={false}
                calendarFocus="backwards"
                onChange={(range) =>
                  handleSelectRange(range, setSelectDateRange)
                }
              />
            </div>
          </Tab>
        </Tabs>
        <button
          className="btn-full btn gradient-green shadow-bg shadow-bg-m w-100 mt-3"
          type="button"
          onClick={handleGenerateReport}
        >
          Buat Laporan
        </button>
      </div>
    </>
  );
};

export default FormLaporanGeneral;
