/* eslint-disable @typescript-eslint/no-explicit-any */
import { LatLngTuple } from "leaflet";
import { API_CLIENT } from "../../const";
import { toast } from "../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export function calculateDistance(
  origin: LatLngTuple,
  destination: LatLngTuple
) {
  // return distance in meters
  const lon1 = toRadian(origin[1]),
    lat1 = toRadian(origin[0]),
    lon2 = toRadian(destination[1]),
    lat2 = toRadian(destination[0]);

  const deltaLat = lat2 - lat1;
  const deltaLon = lon2 - lon1;

  const a =
    Math.pow(Math.sin(deltaLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));
  const EARTH_RADIUS = 6371;
  return c * EARTH_RADIUS * 1000;
}

function toRadian(degree: number) {
  return (degree * Math.PI) / 180;
}

const fetchDailyPresent = async () => {
  const response = await API_CLIENT.get<any>("/daily-present");
  return response.data;
};

export const useFetchDailyPresent = () => {
  return useQuery({
    queryKey: ["daily-present"],
    queryFn: fetchDailyPresent,
  });
};

export const submitPresent = async (
  photo: string,
  latitude: number,
  longitude: number,
  handleCloseModal: () => void
) => {
  const dataToSend = {
    photo: photo,
    latitude: latitude,
    longitude: longitude,
  };

  API_CLIENT.post("/present", dataToSend)
    .then((response) => {
      const result = response.data;
      // console.log(result);
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      handleCloseModal();
    })
    .catch((err) => console.log(err));
};

export const submitPresentSubmission = async (
  note: string,
  latitude: number,
  longitude: number,
  selectedFile: File | null,
  handleCloseModal: () => void
) => {
  const dataToSend = new FormData();
  dataToSend.append("note", note);
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("latitude", latitude.toString());
  dataToSend.append("longitude", longitude.toString());

  API_CLIENT.post("/submission-present", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      handleCloseModal();
    })
    .catch((err) => console.log(err));
};

const fetchDailyPresentList = async (filter: string, range?: any) => {
  const dataSend = {
    filter: filter,
    range: range,
  };
  const response = await API_CLIENT.post<any>(
    "/present-list",
    dataSend
  );
  return response.data;
};

export const useFetchDailyPresentList = (filter: string, range?: any) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["present-list"],
    queryFn: () => fetchDailyPresentList(filter, dateRange),
  });
};
