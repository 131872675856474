import { ElementType } from "react";
import { BiHomeAlt2, BiUser, BiBriefcaseAlt, BiDesktop, BiLeaf, BiDoorOpen, BiBook, BiHardHat, BiCamera, BiUnite } from "react-icons/bi";
import Swal from "sweetalert2";
import { API_CLIENT } from "../../../const";
import { toast } from "../toast";

interface iMenuHeaderAdmin {
  title: string;
  url: string;
  gradient: string;
  icon: ElementType;
}

export const menuHeaderAdmin: iMenuHeaderAdmin[] = [
  {
    title: "Dashboard",
    url: "/",
    gradient: "gradient-red",
    icon: BiHomeAlt2,
  },
  {
    title: "Jabatan",
    url: "/position",
    gradient: "gradient-green",
    icon: BiUser,
  },
  {
    title: "Pengguna",
    url: "/users",
    gradient: "gradient-blue",
    icon: BiBriefcaseAlt,
  },
  {
    title: "Pengajuan",
    url: "/submission-report",
    gradient: "gradient-orange",
    icon: BiBook,
  },
  {
    title: "Data Absen",
    url: "/list-absen",
    gradient: "gradient-red",
    icon: BiCamera,
  },
  {
    title: "Laporan",
    url: "/laporan",
    gradient: "gradient-yellow",
    icon: BiDoorOpen,
  },
];

export const menuSettingHeader: iMenuHeaderAdmin[] = [
  {
    title: "Absensi",
    url: "/setting-absen",
    gradient: "gradient-blue",
    icon: BiDesktop,
  },
  {
    title: "Proyek",
    url: "/setting-proyek",
    gradient: "gradient-orange",
    icon: BiHardHat,
  },
];

export const menuProyekHeader: iMenuHeaderAdmin[] = [
  {
    title: "Management",
    url: "/proyek-management",
    gradient: "gradient-green",
    icon: BiLeaf,
  },
  {
    title: "Penanggung Jawab",
    url: "/proyek-karyawan",
    gradient: "gradient-red",
    icon: BiDoorOpen,
  },
  {
    title: "Jabatan",
    url: "/proyek-jabatan",
    gradient: "gradient-yellow",
    icon: BiDoorOpen,
  },
  // {
  //   title: "Absensi",
  //   url: "/proyek-absensi",
  //   gradient: "gradient-yellow",
  //   icon: BiDoorOpen,
  // },
  {
    title: "Laporan",
    url: "/proyek-laporan",
    gradient: "gradient-blue",
    icon: BiDoorOpen,
  },
];

export const warehouseProyek: iMenuHeaderAdmin[] = [
  {
    title: "Satuan",
    url: "/units",
    gradient: "gradient-blue",
    icon: BiUnite,
  },
];

export const handleLogout = () => {
  Swal.fire({
    title: "Anda Yakin?",
    text: "Apakah anda ingin logout?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Logout!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const zeusToken = localStorage.getItem('zeus-token')
      const dataToSend = {
        zeusToken: zeusToken 
      }
      try {
        API_CLIENT.post("/logout",dataToSend)
          .then((response) => {
            const result = response.data;
            if (result.error) {
              toast.error("Error", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            } else {
              toast.success("Success", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
              setTimeout(() => {
                const isWeb = localStorage.getItem('isWeb')
                if(isWeb){
                  localStorage.removeItem('isWeb')
                  window.location.href = '/login'
                }else{
                  window.flutter_inappwebview.callHandler('logoutSuccess');
                }
              }, 150);
            }
          })
          .catch((err) => console.log(err));
        // window.flutter_inappwebview.callHandler('logoutSuccess');
      } catch (error) {
        Swal.fire("Error", "Terjadi kesalahan saat proses.", "error");
      }
    }
  });
}