/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../const";
import { toast } from "../../assets/components/toast";
import { QueryClient } from "@tanstack/react-query";

export const updateProfile = (values: any, queryClient: QueryClient) => {
  API_CLIENT.post("/update-profile", values)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        queryClient.invalidateQueries({ queryKey: ["profile"] });
      }
    })
    .catch((err) => console.log(err));
};

export const updateProfileImage = (
  selectedFile: File,
  uuid: string,
  isProfile: boolean = false,
  queryClient: QueryClient,
) => {
  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("uuid", uuid);
  dataToSend.append("isProfile", (isProfile ? 1 : 0).toString());
  API_CLIENT.post(`/update-profile-image`, dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        queryClient.invalidateQueries({ queryKey: ["profile"] });
      }
    })
    .catch((err) => console.log(err));
};

export const updateProfileSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  username: Yup.string().required("Please insert a value."),
  email: Yup.string()
    .email()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      "email must be a valid email"
    )
    .required("Please insert a value."),
});

export const handleUpdatePassword = (value: any,uuid: string) => {
  if(value.newPassword !== value.retypeNewPassword){
    return toast.error("Error", 'Silahkan ulangi password baru.', {
      autoClose: 3000,
      closeOnClick: true,
    });
  }

  const dataSend = {
    ...value,
    uuid: uuid
  }
  API_CLIENT.post("/update-password", dataSend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
    })
    .catch((err) => console.log(err));
}