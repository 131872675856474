import { CSSProperties, FC, HTMLAttributes } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";

interface InputPlusMinusProps extends HTMLAttributes<HTMLInputElement> {
  className?: string;
  value: number | string;
  max?: number;
  min?: number;
  classNameInput?: string;
  styleWrapper?: CSSProperties;
  onCallBack?: (value: number | string) => void;
}

const InputPlusMinus: FC<InputPlusMinusProps> = (props) => {
  const {
    className,
    value,
    max,
    min = 0,
    onCallBack,
    styleWrapper,
    ...inputProps
  } = props;
  const handlePlusMinus = (type?: string, inputValue?: string) => {
    let newValue: string | number | undefined = "",
      newValueInput: string | number = "";

    if (inputValue !== undefined && inputValue !== "") {
      newValueInput = parseInt(inputValue);
    }

    if (typeof value === "number") {
      if (type && type === "min") {
        newValue = value - 1;
      } else if (type && type === "plus") {
        newValue = value + 1;
      } else {
        newValue = newValueInput;
      }

      if (typeof newValue === "number") {
        if (min && newValue < min) {
          newValue = min;
        } else if (max && newValue > max) {
          newValue = max;
        }
      }
    } else {
      newValue = newValueInput;
    }
    
    if (onCallBack) {
      onCallBack(newValue ? newValue : "");
    }
  };

  return (
    <>
      <div className={`a32lqq-unf-quantity ${className}`} style={styleWrapper}>
        <button
          className="css-12xfsmg"
          type="button"
          onClick={() => handlePlusMinus("min")}
          disabled={min === value ? true : value === "" ? true : false}
        >
          <BiMinus />
        </button>
        <input
          type="number"
          value={value}
          className="css-jrfgt7-input"
          onChange={(event) => handlePlusMinus("", event.target.value)}
          // onChange={() => {}}
          {...inputProps}
        />
        <button
          className="css-12xfsmg"
          type="button"
          onClick={() => handlePlusMinus("plus")}
          disabled={max && max === value ? true : value === "" ? true : false}
        >
          <BiPlus />
        </button>
      </div>
    </>
  );
};

export default InputPlusMinus;
