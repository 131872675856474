import { FC, useEffect, useState } from "react";
import Layout from "../../assets/components/layout";
import ModalJabatan from "./modal";
import { createPortal } from "react-dom";
import { dataRowJabatan, useFetchJabatan } from "./interface";
import { BiEdit } from "react-icons/bi";
import ButtonDelete from "../../assets/components/ButtonDelete";

interface PagePosition {
  isProyek?: boolean;
}

const PagePosition: FC<PagePosition> = ({ isProyek = false }) => {
  const { data } = useFetchJabatan(isProyek);

  const [modalStatus, setModalStatus] = useState<string>("create");
  const [dataJabatan, setDataJabatan] = useState<dataRowJabatan[]>([]);
  const [dataEditModal, setDataEditModal] = useState<dataRowJabatan | null>(
    null
  );

  useEffect(() => {
    if (data) {
      if (data.error) {
        // setIsRestirected(true);
      } else {
        setDataJabatan(
          data.data.map((role: dataRowJabatan, key: number) => ({
            no: key + 1,
            id: role.id,
            uuid: role.uuid,
            name: role.name,
          }))
        );
      }
    }
  }, [data]);

  const handleEdit = (values: dataRowJabatan) => {
    setModalStatus("edit");
    setDataEditModal(values);
  };

  return (
    <Layout>
      <div className="card overflow-visible card-style">
        <div className="content">
          <h4>Data Jabatan</h4>
          <p>Management jabatan, Seperti {isProyek ? 'tukang, helper,' : 'admin, designer,'} dll...</p>
          <div style={{ maxHeight: "55vh", overflow: "auto" }}>
            <div className="table-responsive">
              <table className="table color-theme mb-2">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: 30 }}>
                      No.
                    </th>
                    <th scope="col">Nama</th>
                    <th scope="col" style={{ width: "9.8em" }}>
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataJabatan.length > 0 ? (
                    dataJabatan.map((jabatan, jabatanKey) => {
                      return (
                        <tr key={jabatanKey}>
                          <td>{jabatan.no}</td>
                          <td>{jabatan.name}</td>
                          <td>
                            <button
                              data-bs-toggle="offcanvas"
                              data-bs-target="#modal-user"
                              onClick={() => handleEdit(jabatan)}
                              className="btn gradient-blue shadow-bg shadow-bg-m text-start"
                            >
                              <BiEdit />
                            </button>
                            <ButtonDelete
                              url="jabatan"
                              KEY={`jabatan-${isProyek}`}
                              values={jabatan.uuid}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Tidak ada data.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <button
            data-bs-toggle="offcanvas"
            data-bs-target="#modal-user"
            className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
            style={{ width: "100%" }}
            onClick={() => setModalStatus("create")}
          >
            Tambah Data Jabatan
          </button>
        </div>
      </div>
      {createPortal(
        <ModalJabatan
          isProyek={isProyek}
          modalStatus={modalStatus}
          modalEditData={dataEditModal}
        />,
        document.body
      )}
    </Layout>
  );
};

export default PagePosition;
