/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  dataRowUser,
  getJabatanUser,
  iInitialValuesUsers,
  initialValuesUsers,
  roleUser,
  useStoreUpdateUsers,
  useStoreUser,
  usersSchema,
} from "./interface";
import { toast } from "../../assets/components/toast";
import { generatePostEncrypt } from "../../const";

interface ModalUserProps {
  modalStatus: string;
  isShowModal: boolean;
  modalEditData: dataRowUser | iInitialValuesUsers;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
}

const statusUser = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "deactive",
    label: "Deactive",
  },
];

const ModalUser: FC<ModalUserProps> = ({
  modalStatus = "create",
  isShowModal = false,
  modalEditData,
  setIsShowModal,
}) => {
  const offcanvasRef = useRef<HTMLButtonElement>(null);

  const [jabatanOptions, setJabatanOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [initialForm, setInitialForm] =
    useState<iInitialValuesUsers>(initialValuesUsers);
  const [isLoading, setIsLoading] = useState(true);

  const storeData = useStoreUser();
  const updateData = useStoreUpdateUsers();

  useEffect(() => {
    const fetchData = async () => {
      const jabatanData = await getJabatanUser();
      setJabatanOptions(jabatanData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (modalStatus !== "create") {
      setInitialForm(modalEditData);
      setIsLoading(true);
    } else {
      setInitialForm(initialValuesUsers);
      setIsLoading(true);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  }, [modalStatus, modalEditData]);

  const handleSubmit = (values: any, resetForm: any) => {
    if (modalStatus === "create") {
      storeData.mutate(values, {
        onSuccess: (response) => {
          const result = response.data;
          if (result.error) {
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          } else {
            toast.success("Success", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          }
          offcanvasRef.current && offcanvasRef.current.click();
          resetForm();
        },
        onError: (response) => {
          const result = response;
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        },
      });
    } else {
      updateData.mutate(
        {
          newData: values,
          id: generatePostEncrypt((modalEditData as dataRowUser).uuid || ""),
        },
        {
          onSuccess: (response) => {
            const result = response.data;
            if (result.error) {
              toast.error("Error", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            } else {
              toast.success("Success", result.message, {
                autoClose: 3000,
                closeOnClick: true,
              });
            }
            offcanvasRef.current && offcanvasRef.current.click();
            resetForm();
          },
          onError: (response) => {
            const result = response;
            toast.error("Error", result.message, {
              autoClose: 3000,
              closeOnClick: true,
            });
          },
        }
      );
    }
  };

  const handleCloseModal = (resetForm: any) => {
    resetForm();
    setTimeout(() => {
      setIsShowModal(false);
    }, 250);
  };

  return (
    <div
      id="modal-user"
      aria-hidden="true"
      className="offcanvas offcanvas-bottom rounded-m bg-theme no-border-bottom-radius"
      style={{ maxHeight:'90dvh' }}
    >
      {isShowModal && !isLoading && (
        <Formik
          initialValues={initialForm}
          validationSchema={usersSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
        >
          {({ errors, touched, resetForm }) => (
            <Form>
              <div className="content">
                <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">
                  {modalStatus === "create" ? "Tambah" : "Edit"}
                </h5>
                <h1 className="font-24 font-800 mb-3">Data Pengguna</h1>
                {modalStatus === "create" && (
                  <div className="alert bg-fade2-red alert-dismissible color-red-dark rounded-s fade show pe-2 mt-3">
                    <strong>Catatan: </strong>
                    Pastikan email yang di daftarkan aktif, Karena username dan
                    password akan di kirim lewat email!!
                  </div>
                )}
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="name"
                    id="c1"
                    className="form-control rounded-xs"
                    placeholder="Nama Lengkap"
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Lengkap
                  </label>
                  {errors.name && touched.name && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.name}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    type="email"
                    name="email"
                    id="c2"
                    className="form-control rounded-xs"
                    placeholder="Email"
                  />
                  <label htmlFor="c2" className="color-theme">
                    Email
                  </label>
                  {errors.email && touched.email && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.email}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    as="select"
                    name="roles"
                    id="c3"
                    className="form-select rounded-xs"
                    aria-label="Floating label select example"
                  >
                    <option value="" disabled>
                      Silahkan Pilih
                    </option>
                    {roleUser.map(
                      (
                        roles: { value: string; label: string },
                        rolesKey: number
                      ) => {
                        return (
                          <option key={rolesKey} value={roles.value}>
                            {roles.label}
                          </option>
                        );
                      }
                    )}
                  </Field>
                  <label htmlFor="c3" className="color-theme">
                    Roles
                  </label>
                  {errors.roles && touched.roles && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.roles}
                    </div>
                  )}
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    as="select"
                    name="jabatan"
                    id="c4"
                    className="form-select rounded-xs"
                    aria-label="Floating label select example"
                  >
                    <option value="" disabled>
                      Silahkan Pilih
                    </option>
                    {jabatanOptions.map(
                      (
                        jabatan: { value: string; label: string },
                        jabatanKey: number
                      ) => {
                        return (
                          <option key={jabatanKey} value={jabatan.value}>
                            {jabatan.label}
                          </option>
                        );
                      }
                    )}
                  </Field>
                  <label htmlFor="c4" className="color-theme">
                    Jabatan
                  </label>
                  {errors.jabatan && touched.jabatan && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.jabatan}
                    </div>
                  )}
                </div>
                <div className="form-custom mb-3 form-floating">
                  <Field
                    as="select"
                    name="status"
                    id="c5"
                    className="form-select rounded-xs"
                    aria-label="Floating label select example"
                  >
                    <option value="" disabled>
                      Silahkan Pilih
                    </option>
                    {statusUser.map(
                      (
                        status: { value: string; label: string },
                        statusKey: number
                      ) => {
                        return (
                          <option key={statusKey} value={status.value}>
                            {status.label}
                          </option>
                        );
                      }
                    )}
                  </Field>
                  <label htmlFor="c5" className="color-theme">
                    Status
                  </label>
                  {errors.status && touched.status && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.status}
                    </div>
                  )}
                </div>
                {/* {
                  values.roles === 'karyawan' && 
                  <div className="form-custom mb-3 form-floating">
                  <Field
                    type="number"
                    name="gapok"
                    id="c6"
                    className="form-control rounded-xs"
                    placeholder="Gaji Pokok"
                  />
                  <label htmlFor="c6" className="color-theme">
                    Gaji Pokok
                  </label>
                  {errors.gapok && touched.gapok && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.gapok}
                    </div>
                  )}
                  <span>(required)</span>
                </div>
                } */}
                <div style={{ width: "100%", gap: 10 }} className="d-flex mt-3">
                  <button
                    ref={offcanvasRef}
                    style={{ width: "30%" }}
                    type="button"
                    data-bs-dismiss="offcanvas"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={() => handleCloseModal(resetForm)}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ModalUser;
