/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  settingProyekProps,
  useFetchSettingProyek,
} from "../../../../../settingProyek/interface";
import moment from "moment";
import ModalAbsenProyek from "./modalAbsenProyek";
import { useFetchDailyPresentProyek, useFetchPresentProyek } from "./interface";
import { useParams } from "react-router-dom";
import { cashFlowFilter } from "../cashFlow/interface";
import ButtonFilterRange from "../../../../../../assets/components/filterRange";

interface listAbsensiProps {
  selectedKaryawanPresent: any[];
  isAbsenPicture: number;
  handleSelectedKaryawan: (karyawan: any, status: string) => void;
  handleRemoveSelect: (karyawan: any) => void;
  setSelectedKaryawan: Dispatch<SetStateAction<any | null>>;
  setIsEnbaleModalCuti: Dispatch<SetStateAction<boolean>>;
  setIsOpenCuti: Dispatch<SetStateAction<boolean>>;
  setSelectedKaryawanPresent: Dispatch<SetStateAction<any[]>>;
}

const ListAbsensi: FC<listAbsensiProps> = ({
  selectedKaryawanPresent,
  isAbsenPicture,
  setSelectedKaryawan,
  handleSelectedKaryawan,
  handleRemoveSelect,
  setIsEnbaleModalCuti,
  setIsOpenCuti,
  setSelectedKaryawanPresent,
}) => {
  const { uuid: idProyek } = useParams();
  const { data: setting } = useFetchSettingProyek();
  const { data: dataIsAbsen } = useFetchDailyPresentProyek(idProyek);

  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data: dailyPresent, refetch } = useFetchPresentProyek(
    idProyek,
    selectedFilter,
    dateRange
  );
  const [listAbsensi, setListAbsensi] = useState<any[]>([]);

  const [settingAbsensi, setSettingAbsensi] =
    useState<settingProyekProps | null>(null);

  const [isAbsen, setIsAbsen] = useState<boolean>(true);
  const [isStatusPresent, setIsStatusPresent] = useState<number>(0);
  const [isStatusAbsen, setIsStatusAbsen] = useState<string>("");

  const [isOpenModalProyek, setIsOpenModalProyek] = useState<boolean>(false);
  const [isEnableModalProyek, setIsEnableModalProyek] =
    useState<boolean>(false);

  useEffect(() => {
    if (dailyPresent) {
      const result = dailyPresent.data;
      setListAbsensi(result);
    }
  }, [dailyPresent]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setListAbsensi(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  useEffect(() => {
    if (setting) {
      const result = setting.data;
      setSettingAbsensi(result);
    }
    if (dataIsAbsen) {
      const result = dataIsAbsen.data;
      setIsStatusPresent(result.hashAbsenMasuk);
    }
  }, [setting, dataIsAbsen]);

  useEffect(() => {
    function updateEveryMinute() {
      if (settingAbsensi) {
        moment().tz("Asia/Jakarta");

        const currentTime = moment();
        const waktuAbsenMasuk = moment(settingAbsensi?.jam_masuk, "HH:mm");
        const waktuAbsenKeluar = moment(settingAbsensi?.jam_keluar, "HH:mm");

        if (isStatusPresent === 0) {
          if (
            currentTime
              .clone()
              .isBefore(
                waktuAbsenMasuk
                  .clone()
                  .subtract(settingAbsensi.toleransi_masuk, "minutes")
              )
          ) {
            setIsAbsen(false);
            setIsStatusAbsen("");
          } else if (
            currentTime
              .clone()
              .isAfter(
                waktuAbsenMasuk
                  .clone()
                  .subtract(settingAbsensi.toleransi_masuk, "minutes")
              ) &&
            currentTime
              .clone()
              .isBefore(
                waktuAbsenMasuk
                  .clone()
                  .add(settingAbsensi.toleransi_masuk, "minutes")
              )
          ) {
            setIsAbsen(true);
            setIsStatusAbsen("");
          } else {
            setIsAbsen(false);
            setIsStatusAbsen("terlambat");
          }
        } else {
          if (currentTime.clone().isBefore(waktuAbsenKeluar.clone())) {
            setIsAbsen(false);
          }
        }
      }
    }

    updateEveryMinute();
    const intervalId = setInterval(updateEveryMinute, 60000);
    return () => clearInterval(intervalId);
  }, [settingAbsensi, isStatusPresent]);

  const handleOpenModalAbsen = () => {
    setIsEnableModalProyek(true);
    setSelectedKaryawanPresent([]);
    setTimeout(() => {
      setIsOpenModalProyek(true);
    }, 150);
  };

  return (
    <>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "95%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div style={{ maxHeight:'calc(100dvh - 330px)',overflow:'auto',paddingBottom:30,paddingTop:10 }}>
        {listAbsensi.length < 1 ? (
          <div
            className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
            style={{
              borderRadius: 15,
              animationDelay: Math.min(1 * 200, 1000) + "ms",
            }}
          >
            <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
              Belum ada riwayat absen
            </h5>
          </div>
        ) : (
          listAbsensi.map((absensi, absensiKey) => {
            return (
              <div
                className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                  absensiKey !== 0 && "mt-3"
                } `}
                key={absensiKey}
                style={{
                  animationDelay: Math.min(absensiKey * 200, 1000) + "ms",
                  borderRadius: 15,
                }}
              >
                <div className="ps-2 align-self-center">
                  <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                    {absensi.karyawan.name}{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      ({absensi.karyawan.jabatan.name})
                    </span>
                  </h5>
                  <span className="ps-1 mb-0 font-400 font-12">
                  {moment(new Date(absensi.created_at)).format("ll")}{" "} {absensi.jam}
                    <span
                      className={`ms-2 ${
                        absensi.status === 'MASUK'
                          ? "color-green-dark"
                          : "color-red-dark"
                      }`}
                    >
                      {absensi.status}
                    </span>
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div style={{ position: "fixed", width: "100dvw", bottom: -15 }}>
        {isAbsen ? (
          isAbsenPicture === 0 ? (
            <div className="card card-style bg-red-dark show shadow-bg shadow-bg-m fade p-0">
              <div className="content my-3">
                <div className="d-flex">
                  <div className="align-self-center">
                    <i className="bi bi-emoji-frown-fill font-36 d-block color-white"></i>
                  </div>
                  <div className="align-self-center">
                    <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                      Silahkan lakukan absensi Photo terlebih dahulu.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`card card-style bg-${
                isStatusPresent === 0 ? "blue" : "mint"
              }-dark show shadow-bg shadow-bg-m fade p-0`}
            >
              <div className="content my-3">
                <div className="d-flex">
                  <div className="align-self-center">
                    <p className="color-white mb-0 font-12 ps-3 pe-4 line-height-s">
                      <span className="font-500 color-white font-16 mb-0">
                        {isStatusPresent === 0
                          ? "Absen Datang"
                          : "Absen Pulang"}
                      </span>
                      <br />
                      Silahkan Lakukan absensi.
                    </p>
                  </div>
                  <div className="ms-auto">
                    <button
                      className="btn-full btn gradient-green btn-s"
                      onClick={handleOpenModalAbsen}
                    >
                      Lanjut
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : isStatusAbsen !== "terlambat" ? (
          <div className="card card-style bg-blue-dark show shadow-bg shadow-bg-m fade p-0">
            <div className="content my-3">
              <div className="d-flex">
                <div className="align-self-center">
                  <i className="bi bi-clock font-36 d-block color-white"></i>
                </div>
                <div className="align-self-center">
                  {isStatusPresent == 0 ? (
                    <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                      Waktu absensi masuk mulai jam{" "}
                      {moment(settingAbsensi?.jam_masuk, "HH:mm")
                        .subtract(settingAbsensi?.toleransi_masuk, "minutes")
                        .tz("Asia/Jakarta")
                        .format("H:mm")}
                      .
                    </p>
                  ) : (
                    <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                      Waktu absensi pulang jam{" "}
                      {moment(settingAbsensi?.jam_keluar, "HH:mm")
                        .tz("Asia/Jakarta")
                        .format("H:mm")}
                      .
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card card-style bg-red-dark show shadow-bg shadow-bg-m fade p-0">
            <div className="content my-3">
              <div className="d-flex">
                <div className="align-self-center">
                  <i className="bi bi-emoji-frown-fill font-36 d-block color-white"></i>
                </div>
                <div className="align-self-center">
                  <p className="color-white mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                    Waktu absensi sudah terlewat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isEnableModalProyek && (
        <ModalAbsenProyek
          isOpen={isOpenModalProyek}
          setIsOpen={setIsOpenModalProyek}
          setIsEnableModalProyek={setIsEnableModalProyek}
          isStatusPresent={isStatusPresent}
          setSelectedKaryawanIzin={setSelectedKaryawan}
          setIsEnbaleModalCuti={setIsEnbaleModalCuti}
          setIsOpenCuti={setIsOpenCuti}
          handleSelectedKaryawan={handleSelectedKaryawan}
          handleRemoveSelect={handleRemoveSelect}
          selectedKaryawanPresent={selectedKaryawanPresent}
        />
      )}
    </>
  );
};

export default ListAbsensi;
