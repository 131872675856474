/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { useFetchDailyPresentProyek } from "./interface";
import { useParams } from "react-router-dom";
import { API_CLIENT } from "../../../../../../const";
import { toast } from "../../../../../../assets/components/toast";
import { useQueryClient } from "@tanstack/react-query";

interface ModalAbsenProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  isStatusPresent: number;
  setSelectedKaryawanIzin: Dispatch<SetStateAction<any | null>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModalProyek: Dispatch<SetStateAction<boolean>>;
  setIsEnbaleModalCuti: Dispatch<SetStateAction<boolean>>;
  setIsOpenCuti: Dispatch<SetStateAction<boolean>>;
  handleSelectedKaryawan: (karyawan: any, status: string) => void;
  handleRemoveSelect: (karyawan: any) => void;
  selectedKaryawanPresent: any[];
}

const ModalAbsenProyek: FC<ModalAbsenProps> = ({
  isOpen,
  isStatusPresent,
  setIsOpen,
  setSelectedKaryawanIzin,
  setIsEnableModalProyek,
  setIsEnbaleModalCuti,
  setIsOpenCuti,
  selectedKaryawanPresent,
  handleSelectedKaryawan,
  handleRemoveSelect,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { uuid: idProyek } = useParams();
  const { data } = useFetchDailyPresentProyek(idProyek);

  const [dataKaryawan, setDataKaryawan] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const result = data.data;
      // console.log(data)
      setDataKaryawan(result.karyawan);
    }
  }, [data]);

  const handleCloseModalAbsen = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModalProyek(false);
    }, 150);
  };

  const handleIzinModal = (karyawan: any) => {
    setSelectedKaryawanIzin(karyawan);
    setIsEnbaleModalCuti(true);
    setTimeout(() => {
      setIsOpenCuti(true);
    }, 150);
  };

  const handleSubmit = () => {
    if (selectedKaryawanPresent.length < 1) {
      return toast.error("Error", "Silahkan Pilih Karyawan...", {
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    const dataToSend = {
      idProyek: idProyek,
      data: JSON.stringify(selectedKaryawanPresent),
    };
    API_CLIENT.post("/proyek/absensi-daily", dataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        const result = response.data;
        if (result.error) {
          toast.error("Error", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        } else {
          toast.success("Success", result.message, {
            autoClose: 3000,
            closeOnClick: true,
          });
        }
        queryClient.invalidateQueries({
          queryKey: ["management-absensi-daily"],
        });
        queryClient.invalidateQueries({
          queryKey: ["proyek-daily-present-picture"],
        });
        queryClient.invalidateQueries({
          queryKey: ["proyek-daily-present"],
        });
        queryClient.invalidateQueries({
          queryKey: ["proyek-present"],
        });
        queryClient.invalidateQueries({
          queryKey: ["setting-proyek"],
        });
        handleCloseModalAbsen();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Offcanvas
        show={isOpen}
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "90dvh",
        }}
        onHide={() => handleCloseModalAbsen()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h6 className="font-700 mb-n1 color-highlight">Absensi</h6>
          <h4 className="pb-2">Form Absensi</h4>
          {dataKaryawan.length < 1 ? (
            <div
              className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3"
              style={{
                borderRadius: 15,
                animationDelay: Math.min(1 * 200, 1000) + "ms",
              }}
            >
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                Tidak ada data karyawan.
              </h5>
            </div>
          ) : (
            dataKaryawan.map((karyawan, karyawanKey) => {
              const isKaryawanAlreadySelected = selectedKaryawanPresent.some(
                (item) => item.value === karyawan.uuid
              );
              const karyawanSelected = selectedKaryawanPresent.find(
                (item) => item.value === karyawan.uuid
              );
              return (
                <div
                  className={`slide-right ${
                    (isKaryawanAlreadySelected &&
                      karyawanSelected.status === "MASUK") ||
                    (isKaryawanAlreadySelected &&
                      karyawanSelected.status === "PULANG")
                      ? "bg-blue-dark"
                      : isKaryawanAlreadySelected &&
                        karyawanSelected.status !== "MASUK"
                      ? "bg-red-dark"
                      : "bg-theme"
                  } rounded-m shadow-bg shadow-bg-s ${
                    karyawanKey !== 0 && "mt-3"
                  } mb-3`}
                  key={karyawanKey}
                  style={{
                    height: 70,
                    animationDelay: Math.min(karyawanKey * 200, 1000) + "ms",
                  }}
                >
                  <div className="d-flex px-2 pb-2 pt-2">
                    <div>
                      <img
                        src={karyawan.profile}
                        alt="profile"
                        width={55}
                        className="rounded-s"
                      />
                    </div>
                    <div className={`ps-2 align-self-center`}>
                      <h5
                        style={{ textTransform: "capitalize" }}
                        className={`ps-1 mb-0 line-height-xs pt-1 ${
                          isKaryawanAlreadySelected && "color-white"
                        }`}
                      >
                        {karyawan.name}{" "}
                      </h5>
                      <h6
                        className={`ps-1 mb-0 mt-1 font-400 opacity-80 ${
                          isKaryawanAlreadySelected && "color-white"
                        }`}
                      >
                        +{karyawan.no_hp}{" "}
                        <strong>({karyawan.jabatan.name})</strong>
                      </h6>
                    </div>
                    <div
                      className="ms-auto d-flex"
                      style={{ alignItems: "center" }}
                    >
                      {isStatusPresent === 1 ? (
                        <>
                          <button
                            className="btn btn-xs gradient-green shadow-bg shadow-bg-s text-start me-2"
                            onClick={() =>
                              handleSelectedKaryawan(karyawan, "PULANG")
                            }
                          >
                            <div className="bi bi-check-lg font-20"></div>
                          </button>
                        </>
                      ) : !isKaryawanAlreadySelected ? (
                        <>
                          <button
                            className="btn btn-xs gradient-green shadow-bg shadow-bg-s text-start me-2"
                            onClick={() =>
                              handleSelectedKaryawan(karyawan, "MASUK")
                            }
                          >
                            <div className="bi bi-check-lg font-20"></div>
                          </button>
                          <button
                            className="btn btn-xs gradient-red shadow-bg shadow-bg-s text-start"
                            onClick={() => handleIzinModal(karyawan)}
                          >
                            <div className="bi bi-x-circle-fill font-20"></div>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-xs gradient-red shadow-bg shadow-bg-s text-start"
                            onClick={() => handleRemoveSelect(karyawan)}
                          >
                            <div className="bi bi-x-circle-fill font-20"></div>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          <div className="d-flex" style={{ gap: 10 }}>
            <button
              className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
              style={{ width: `${dataKaryawan.length > 0 ? "30%" : "100%"}` }}
              type="button"
              onClick={handleCloseModalAbsen}
            >
              Batal
            </button>
            {dataKaryawan.length > 0 && (
              <button
                className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                style={{ width: "70%" }}
                onClick={handleSubmit}
              >
                Simpan Data
              </button>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalAbsenProyek;
