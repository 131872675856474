/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import ModalInventarisProyek from "./modal";
import { useFetchInventarisProyek } from "./interface";
import NoData from "../../../../../../assets/components/noData";
import ButtonDelete from "../../../../../../assets/components/ButtonDelete";
import { BiEdit, BiRecycle } from "react-icons/bi";
import { useProfile } from "../../../../../../const";
import { useParams } from "react-router-dom";

const PageInventarisProyek = () => {
  const {uuid: idProyek} = useParams();
  const { data } = useFetchInventarisProyek(idProyek);
  const { data: dataProfile } = useProfile();

  const [profile, setProfile] = useState<any | null>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);

  const [listInventaris, setListInventaris] = useState<any[]>([]);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  useEffect(() => {
    if (dataProfile) {
      setProfile(dataProfile.user);
    }
  }, [dataProfile]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  useEffect(() => {
    if (data) {
      const result = data.data;
      setListInventaris(result);
    }
  }, [data]);

  return (
    <>
      <div className="d-flex px-3 slide-top" style={{ alignItems: "center" }}>
        <div>
          <h3 className="mb-0">Data Invetaris</h3>
        </div>
      </div>
      <div className="row px-3 mt-2">
        {listInventaris.length < 1 ? (
          <div className="card card-style" style={{ width: "92%" }}>
            <div className="content">
              <NoData />
            </div>
          </div>
        ) : (
          listInventaris.map((inventaris, inventarisKey) => {
            return (
              <div
                className="col-6 slide-right"
                key={inventarisKey}
                style={{
                  animationDelay: Math.min(inventarisKey * 200, 1000) + "ms",
                }}
              >
                <div
                  className="card card-style mx-0 mb-4"
                  style={{ borderRadius: 10 }}
                >
                  <div className="content">
                    <div
                      className="d-flex flex-column mb-1"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <img
                        src="/images/package.svg"
                        alt="package icon"
                        width={75}
                      />
                    </div>
                    <table className="w-100 mb-1">
                      <tbody>
                        <tr>
                          <td colSpan={3}>
                            <strong className="text-hidden-1">
                              {inventaris.name}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td width={40}>Stock</td>
                          <td width={10}>:</td>
                          <td>{inventaris.stock}</td>
                        </tr>
                        <tr>
                          <td width={40}>Sisa</td>
                          <td width={10}>:</td>
                          <td>{inventaris.stock - inventaris.stock_pinjam}</td>
                        </tr>
                      </tbody>
                    </table>
                    {profile.roles === "super-admin" && (
                      <div className="d-flex" style={{ gap: 5 }}>
                        <button
                          className="btn-xs btn gradient-green shadow-bg shadow-bg-xs"
                          onClick={() => {
                            setIsDataEdit(inventaris), handleOpen();
                          }}
                        >
                          <BiEdit />
                        </button>
                        <ButtonDelete
                          className="btn-xs btn gradient-red shadow-bg shadow-bg-xs"
                          url="proyek/inventaris-proyek"
                          KEY="inventaris-proyek"
                          values={inventaris.uuid}
                        />
                        <a
                          href={`/inventaris/${inventaris.uuid}`}
                          className="btn-xs btn gradient-blue shadow-bg shadow-bg-xs"
                        >
                          <BiRecycle />
                        </a>
                      </div>
                    )}
                    {profile.roles === "penaggung-jawab-proyek" && (
                      <div className="d-flex" style={{ gap: 5 }}>
                        <a
                          href={`/inventaris/${inventaris.uuid}`}
                          className="btn-full btn gradient-blue shadow-bg shadow-bg-xs w-100"
                        >
                          <BiRecycle />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      {profile.roles === "super-admin" && (
        <button
          className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
          onClick={() => {
            setIsDataEdit(null), handleOpen();
          }}
          style={{ zIndex: 999 }}
        >
          <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
        </button>
      )}

      {isEnableModal && (
        <ModalInventarisProyek
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isDataEdit={isDataEdit}
        />
      )}
    </>
  );
};

export default PageInventarisProyek;
