/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useField } from "formik";
import { useEffect, useState } from "react";
import { MenuPlacement } from "react-select";

import AsyncSelect from "react-select/async";
import {
  Option,
  fetchOptionsFromServer,
  generateUniqueId,
  promiseOptions,
} from "./interface";

const FormSelectWithFormik: React.FC<any> = (props) => {
  const { urlSelect, name, className, error, touched, menuPlacement } = props;
  const [field, meta, helpers] = useField(name);

  const fieldErrors: { [key: string]: string } = error;
  const fieldTouched: { [key: string]: boolean } = touched;

  const [value, setValue] = useState<
    { value: string; label: string } | { value: string; label: string }[] | null
  >(null);
  const [isPlacement] = useState<MenuPlacement>(
    (menuPlacement as MenuPlacement) || "bottom"
  );

  useEffect(() => {
    if (Array.isArray(field.value)) {
      setValue(field.value);
    } else {
      const getDefaultValue = async () => {
        await fetchOptionsFromServer("", urlSelect).then((result) => {
          const selectedOption = result.find(
            (option: { value: string; label: string }) =>
              option.value === field.value
          );
          setValue(selectedOption);
        });
      };
      getDefaultValue();
    }
  }, []);

  return (
    <>
      <AsyncSelect
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={value}
        name={field.name}
        menuPlacement={isPlacement}
        onBlur={() => {
          helpers.setTouched(true), meta.touched;
        }}
        onChange={(selectedOption) => {
          const selectValue =
            (selectedOption as Option)?.value ||
            (selectedOption as unknown as string);

          setValue(selectedOption as Option);
          helpers.setValue(selectValue);
        }}
        className={`${className ? className : ""} ${
          fieldErrors &&
          fieldErrors[field.name] &&
          fieldTouched &&
          fieldTouched[field.name]
            ? "form-select-error"
            : ""
        }`}
        id={generateUniqueId()}
        loadOptions={(values) => promiseOptions(values, urlSelect)}
        defaultOptions
        {...props}
      />
      {fieldErrors &&
      fieldErrors[field.name] &&
      fieldTouched &&
      fieldTouched[field.name] ? (
        <div style={{ display: "block" }} className="invalid-feedback">
          {fieldErrors[field.name]}
        </div>
      ) : null}
    </>
  );
};

export { FormSelectWithFormik };
