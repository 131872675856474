import { FC, useState } from "react";
// import ModalCashFlow from "./modal";
import CashFlowOut from "./cashOut";
import ModalCashFlowOut from "./modalCashFlowOut";
import CashIn from "./cashIn";
import ModalCashFlow from "./modal";

interface cashFlowPageProps {
  isCashIn?: boolean;
}

const CashFlowPage: FC<cashFlowPageProps> = ({ isCashIn = true }) => {
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  return (
    <>
      {!isCashIn ? (
        <>
          <button
            className="btn btn-fab slide-top gradient-blue shadow-bg shadow-bg-xs"
            onClick={handleOpen}
          >
            <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
          </button>
          <CashFlowOut />
          {isEnableModal && !isCashIn && (
            <ModalCashFlowOut
              isOpen={isOpen}
              setIsEnableModal={setIsEnableModal}
              setIsOpen={setIsOpen}
            />
          )}
        </>
      ) : (
        <>
          <button
            className="btn btn-fab slide-top gradient-blue shadow-bg shadow-bg-xs"
            onClick={handleOpen}
          >
            <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
          </button>
          <CashIn />
          {isEnableModal && (
            <ModalCashFlow
              isOpen={isOpen}
              setIsEnableModal={setIsEnableModal}
              setIsOpen={setIsOpen}
            />
          )}
        </>
      )}
    </>
  );
};

export default CashFlowPage;
