/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from "react";
import Layout from "../../assets/components/layout";
import { getLocation, greet } from "../../const";
import { useFetchDashboardKaryawan } from "./interface";
import moment from "moment";
import { handleLogout } from "../../assets/components/header/interface";
import { yearRange } from "../proyek/laporan/interface";

interface DashboardKaryawanSoberProps {
  profile: any;
}

const DashboardKaryawanSober: FC<DashboardKaryawanSoberProps> = ({
  profile,
}) => {
  const { user } = profile;
  const [address, setAddress] = useState<any | null>({});
  const [selectYear, setSelectYear] = useState<number>(
    new Date().getFullYear()
  );
  const { data, refetch } = useFetchDashboardKaryawan(selectYear);

  const [listAbsensi, setListAbsensi] = useState<any[]>([]);
  const [listCuti, setListCuti] = useState<any[]>([]);
  const [listDashboardTop, setListDashboardTop] = useState<any>(null);

  const getCityFromCoordinates = async (
    lat: string | null | undefined,
    lng: string | null | undefined
  ) => {
    const url =
      "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" +
      lat +
      "&lon=" +
      lng;

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setAddress(data.address);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      const { latitude, longitude } = (await getLocation()) as {
        latitude: string;
        longitude: string;
      };

      getCityFromCoordinates(latitude, longitude);
    };
    getData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        const { absensi, submission, dashboardTop } = result;
        setListAbsensi(absensi);
        setListCuti(submission);
        setListDashboardTop(dashboardTop);
      }
    };

    fetchData();
  }, [selectYear, refetch]);

  useEffect(() => {
    document.body.style.background = "rgb(232 232 232 / 19%)";
    document.body.classList.add('dashboard-sober');
    if (data) {
      const result = data.data;
      const { absensi, submission, dashboardTop } = result;
      setListAbsensi(absensi);
      setListCuti(submission);
      setListDashboardTop(dashboardTop);
    }
  }, [data]);

  return (
    <Layout noShadow noPaddingTop noHeader>
      <div className="background-1">
        <div className="d-flex px-3 py-4">
          <a href="/profile">
            <img
              src={user.profile}
              alt="profile"
              width={45}
              height={45}
              style={{ borderRadius: "50%" }}
            />
          </a>
          <a href="/profile" className="ps-2 align-self-center">
            <h5 className="ps-1 mb-0 line-height-xs pt-1 color-white font-18">
              {user.name}
            </h5>
            <h6 className="ps-1 mb-0 font-400 color-white">
              {address.village}, {address.city} {address.state}
            </h6>
          </a>
          <div className="ms-auto">
            <button
              className="btn btn-notification"
              style={{ borderRadius: "50%" }}
              onClick={() => handleLogout()}
            >
              <i className="bi bi-power"></i>
            </button>
          </div>
        </div>
        <div
          className="card card-style position-absolute mb-0"
          style={{
            bottom: -85,
            zIndex: 3,
            width: "calc(100dvw - 35px)",
            borderRadius: 15,
          }}
        >
          <div className="content">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div>
                <p className="ps-1 pb-0 mb-0 font-600 font-13">{greet()}</p>
                <h3 className="ps-2 mt-1 line-height-xs font-20 font-600">
                  {user.name}
                </h3>
              </div>
              <div className="ms-auto">
                <div className="form-custom form-label" style={{ margin: 0 }}>
                  <select
                    className="form-select rounded-xs font-17 no-padding font-600 color-blue-dark"
                    value={selectYear}
                    onChange={(event) =>
                      setSelectYear(parseInt(event.target.value))
                    }
                    style={{ border: "none" }}
                  >
                    {yearRange.map((year, yearKey) => {
                      return (
                        <option key={yearKey} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-3 flex-column d-flex align-items-center gap-2">
                <button
                  className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                  style={{ padding: "12px 18px", borderRadius: "13px" }}
                >
                  <i className="bi bi-person-raised-hand font-18"></i>
                  {listDashboardTop && listDashboardTop.countPresent > 0 && (
                    <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                      {listDashboardTop.countPresent > 99
                        ? "99+"
                        : listDashboardTop.countPresent}
                    </span>
                  )}
                </button>
                <span className="color-blue-dark font-600 font-12">
                  Kehadiran
                </span>
              </div>
              <div className="col-3 flex-column d-flex align-items-center gap-2">
                <button
                  className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                  style={{ padding: "12px 18px", borderRadius: "13px" }}
                >
                  <i className="bi bi-geo font-18"></i>
                  {listDashboardTop && listDashboardTop.countIzin > 0 && (
                    <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                      {listDashboardTop.countIzin}
                    </span>
                  )}
                </button>
                <span className="color-blue-dark font-600 font-12">Izin</span>
              </div>
              <div className="col-3 flex-column d-flex align-items-center gap-2">
                <button
                  className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                  style={{ padding: "12px 18px", borderRadius: "13px" }}
                >
                  <i className="bi bi-emoji-tear font-18"></i>
                  {listDashboardTop && listDashboardTop.countSakit > 0 && (
                    <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                      {listDashboardTop.countSakit}
                    </span>
                  )}
                </button>
                <span className="color-blue-dark font-600 font-12">Sakit</span>
              </div>
              <div className="col-3 flex-column d-flex align-items-center gap-2">
                <button
                  className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                  style={{ padding: "12px 18px", borderRadius: "13px" }}
                >
                  <i className="bi bi-clock font-18"></i>
                  {listDashboardTop &&
                    listDashboardTop.countPresentLate > 0 && (
                      <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                        {listDashboardTop.countPresentLate}
                      </span>
                    )}
                </button>
                <span className="color-blue-dark font-600 font-12">
                  Terlambat
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex py-2 px-4 slide-top"
        style={{ alignItems: "center", marginTop: 95 }}
      >
        <div>
          <h3 className="mb-0">Riwayat Cuti</h3>
        </div>
      </div>
      {listCuti.length < 1 ? (
        <div
          className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
          style={{
            borderRadius: 15,
            animationDelay: Math.min(1 * 200, 1000) + "ms",
          }}
        >
          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
            Belum ada riwayat cuti
          </h5>
        </div>
      ) : (
        listCuti.map((cuti, cutiKey) => {
          return (
            <div
              className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                cutiKey !== 0 && "mt-3"
              } `}
              key={cutiKey}
              style={{
                animationDelay: Math.min(cutiKey * 200, 1000) + "ms",
                borderRadius: 15,
              }}
            >
              <div
                className="ps-2 align-self-center mt-1 mb-1"
                style={{ width: "100%" }}
              >
                <h5 className="ps-1 mb-1 line-height-xs pt-1 font-14">
                  <span>
                    {cuti.formatted_start_date}
                    {cuti.formatted_end_date !== cuti.formatted_start_date &&
                      " s/d " + cuti.formatted_start_date}
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    ({cuti.status})
                  </span>
                </h5>
                <span className="ps-1 mb-1 line-height-xs mt-n1 font-14">
                  <strong>Keterangan :</strong> {cuti.keterangan}
                </span>
                <br />
                {cuti.url_file && (
                  <>
                    <a href={cuti.url_file} className="ps-1">
                      Bukti Pengajuan
                    </a>
                  </>
                )}
              </div>
            </div>
          );
        })
      )}
      <div
        className="d-flex py-2 px-4 slide-top"
        style={{ alignItems: "center" }}
      >
        <div>
          <h3 className="mb-0">Riwayat Absensi</h3>
        </div>
      </div>
      <div>
        {listAbsensi.length < 1 ? (
          <div
            className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
            style={{
              borderRadius: 15,
              animationDelay: Math.min(1 * 200, 1000) + "ms",
            }}
          >
            <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
              Belum ada riwayat absen
            </h5>
          </div>
        ) : (
          listAbsensi.map((absensi, absensiKey) => {
            return (
              <div
                className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                  absensiKey !== 0 && "mt-3"
                } `}
                key={absensiKey}
                style={{
                  animationDelay: Math.min(absensiKey * 200, 1000) + "ms",
                  borderRadius: 15,
                }}
              >
                <div className="d-flex">
                  <a href={absensi.url_photo}>
                    <img
                      src={absensi.url_photo}
                      style={{
                        objectFit: "fill",
                        backgroundRepeat: "no-repeat",
                        borderRadius: 10,
                      }}
                      width={45}
                      height={45}
                      alt="photo absensi"
                    />
                  </a>
                  <div className="ps-2 align-self-center">
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14 color-blue-dark">
                      {moment(new Date(absensi.tanggal)).format("ll")}{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        ({absensi.status})
                      </span>
                    </h5>
                    <span className="ps-1 mb-0 font-400 font-12">
                      {absensi.jam}
                      <span
                        className={`ms-2 ${
                          absensi.is_valid === 1
                            ? "color-green-dark"
                            : "color-red-dark"
                        }`}
                      >
                        {absensi.is_valid === 1 ? "Tepat Waktu" : "Terlambat"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div style={{ paddingBottom: "2rem" }} />
    </Layout>
  );
};

export default DashboardKaryawanSober;
