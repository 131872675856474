import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_CLIENT } from "../../const";

export interface settingAbsensiProps {
  jam_masuk: string;
  jam_keluar: string;
  radius: number;
  toleransi_masuk: number;
  latitude: string;
  longitude: string;
  uuid: string;
}

interface settingAbsensiRequest { 
    data: settingAbsensiProps,
    error: boolean,
    message: string,
}

export interface iInitialValuesSettingAbsensi {
  jam_masuk: string;
  jam_keluar: string;
  radius: number;
  toleransi_masuk: number;
  latitude: string;
  longitude: string;
}

export const initialValuesSettingAbsensi: iInitialValuesSettingAbsensi = {
  jam_masuk: "",
  jam_keluar: "",
  radius: 0,
  toleransi_masuk: 0,
  latitude: "",
  longitude: "",
};

const fetchSettingAbsensi = async () => {
  const response = await API_CLIENT.get<settingAbsensiRequest>("/setting-absensi");
  return response.data;
};

export const useFetchSettingAbsensi = () => {
  return useQuery({
    queryKey: ["setting-absensi"],
    queryFn: fetchSettingAbsensi,
  });
};

export const useStoreUpdateSettingAbsensi = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: async (variables: {
        newData: iInitialValuesSettingAbsensi;
        id: string;
      }) => {
        const { newData, id } = variables;
        return API_CLIENT.patch(`/setting-absensi/${id}`, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["setting-absensi"] });
      },
    });
  };