/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useFetchDataManagementGlobal } from "../../interface";
import { caesarEncrypt } from "../../../../../../const";
import { useParams } from "react-router-dom";

import ModalAbsensiIzin from "./modal";
import ListAbsensi from "./listAbsensi";
import ModalLembur from "./modalLembur";
import ListLembur from "./listLembur";
import AbsenPhotoProyek from "./absenPhoto";
import { useFetchDailyPresentProyek } from "./interface";

const AbsensiManagementProyek = () => {
  const { uuid } = useParams();

  const { data } = useFetchDataManagementGlobal(
    "management-absensi-daily",
    `proyek/management-absensi-daily?prefix=${caesarEncrypt(uuid || "", 20)}`
  );
  const { data: dailyKaryawan } = useFetchDailyPresentProyek(uuid);

  const [dataAllKaryawan, setDataAllKaryawan] = useState<any[]>([]);

  const [selectedKaryawan, setSelectedKaryawan] = useState<any | null>(null);
  const [isEditLemburKaryawan, setIsEditLemburKaryawan] = useState<any | null>(
    null
  );

  const [hashAbsenMasuk, setHashAbsenMasuk] = useState<number>(0);

  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isLembur, setIsLembur] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<string>("absensi-photo");

  const [selectedKaryawanPresent, setSelectedKaryawanPresent] = useState<any[]>(
    []
  );

  const [isAbsenPicture, setIsAbsenPicture] = useState<number>(0);

  useEffect(() => {
    document.body.style.background = "#fafafa";
    if (data) {
      const {
        data: { checkPresent, karyawan },
      } = data;
      setDataAllKaryawan(karyawan);
      setIsAbsenPicture(checkPresent);
    }
  }, [data]);

  useEffect(() => {
    if (dailyKaryawan) {
      const {
        data: { hashAbsenMasuk },
      } = dailyKaryawan;
      setHashAbsenMasuk(hashAbsenMasuk);
    }
  }, [dailyKaryawan]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  const handleSelectedKaryawan = (
    karyawan: any,
    status: string,
    dataCuti?: any
  ) => {
    const isKaryawanAlreadySelected = selectedKaryawanPresent.some(
      (item) => item.value === karyawan.uuid
    );

    if (!isKaryawanAlreadySelected) {
      setSelectedKaryawanPresent((prev) => [
        ...prev,
        {
          value: karyawan.uuid,
          label: karyawan.name,
          status: status,
          dataCuti: dataCuti,
        },
      ]);
    } else {
      handleRemoveSelect(karyawan);
    }
  };

  const handleRemoveSelect = (karyawan: any) => {
    const updatedSelectedKaryawan = selectedKaryawanPresent.filter(
      (item) => item.value !== karyawan.uuid
    );
    setSelectedKaryawanPresent(updatedSelectedKaryawan);
  };

  return (
    <>
      <div className="card card-style">
        <div className="content mb-0 pb-3">
          <div className="tabs tabs-pill" id="tab-group-2">
            <div className="tab-controls rounded-m p-1">
              <a
                className="font-12 rounded-m"
                data-bs-toggle="collapse"
                href="#tab-absensi-photo"
                onClick={() => setTabActive("absensi-photo")}
                aria-expanded={tabActive === "absensi-photo" && true}
              >
                Photo
              </a>
              <a
                className="font-12 rounded-m"
                data-bs-toggle="collapse"
                href="#tab-absensi"
                onClick={() => setTabActive("absensi")}
                aria-expanded={tabActive === "absensi" && true}
              >
                Absensi
              </a>
              <a
                className="font-12 rounded-m"
                data-bs-toggle="collapse"
                href="#tab-lembur"
                onClick={() => setTabActive("lembur")}
                aria-expanded={tabActive === "lembur" && true}
              >
                Lembur
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        {tabActive === "lembur" && (
          <ListLembur
            handleOpen={handleOpen}
            setIsLembur={setIsLembur}
            setIsEditLemburKaryawan={setIsEditLemburKaryawan}
          />
        )}
      </div>
      {tabActive === "absensi-photo" && <AbsenPhotoProyek />}
      {tabActive === "lembur" && hashAbsenMasuk === 1 && (
        <button
          className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
          onClick={() => {
            setIsLembur(true), handleOpen(), setIsEditLemburKaryawan(null);
          }}
          style={{ zIndex: 999 }}
        >
          <i className="bi bi-clock" style={{ fontSize: 18 }}></i>
        </button>
      )}
      {tabActive === "absensi" && (
        <>
          {/* <div
              className="d-flex py-2 slide-top"
              style={{ alignItems: "center" }}
            >
              <div>
                <h3 className="mb-0">Data Karyawan</h3>
              </div>
            </div> */}
          <ListAbsensi
            setSelectedKaryawan={setSelectedKaryawan}
            setIsEnbaleModalCuti={setIsEnableModal}
            setIsOpenCuti={setIsOpen}
            isAbsenPicture={isAbsenPicture}
            setSelectedKaryawanPresent={setSelectedKaryawanPresent}
            handleSelectedKaryawan={handleSelectedKaryawan}
            handleRemoveSelect={handleRemoveSelect}
            selectedKaryawanPresent={selectedKaryawanPresent}
          />
        </>
      )}
      {isEnableModal && !isLembur && (
        <ModalAbsensiIzin
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          selectedKaryawan={selectedKaryawan}
          handleSelectedKaryawan={handleSelectedKaryawan}
        />
      )}
      {isEnableModal && isLembur && (
        <ModalLembur
          isEditLemburKaryawan={isEditLemburKaryawan}
          LIST_KARYAWAN_PROYEK={dataAllKaryawan}
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default AbsensiManagementProyek;
