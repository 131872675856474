/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, HTMLAttributes, SetStateAction, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import {
  handleSubmitLemburKaryawan,
  initialValueLemburKaryawan,
  lemburKaryawanSchema,
} from "./interface";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface ModalLemburProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  isEditLemburKaryawan: any | null;
  LIST_KARYAWAN_PROYEK: any[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

const ModalLembur: FC<ModalLemburProps> = ({
  isOpen,
  setIsOpen,
  isEditLemburKaryawan,
  setIsEnableModal,
  LIST_KARYAWAN_PROYEK,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { uuid: idProyek } = useParams();
  const [modalDataKaryawan, setModalDataKaryawan] = useState<boolean>(false);
  const [selectedKaryawan, setSelectedKaryawan] = useState<any[]>([]);

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      queryClient.invalidateQueries({
        queryKey: ["management-lembur-karyawan"],
      });
    }, 150);
  };

  const handleSelectedKaryawan = (karyawan: any) => {
    const isKaryawanAlreadySelected = selectedKaryawan.some(
      (item) => item.value === karyawan.uuid
    );

    if (!isKaryawanAlreadySelected) {
      setSelectedKaryawan((prev) => [
        ...prev,
        {
          value: karyawan.uuid,
          label: karyawan.name,
        },
      ]);
    } else {
      handleRemoveSelect(karyawan);
    }
  };

  const handleRemoveSelect = (karyawan: any) => {
    const updatedSelectedKaryawan = selectedKaryawan.filter(
      (item) => item.value !== karyawan.uuid
    );
    setSelectedKaryawan(updatedSelectedKaryawan);
  };

  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body>
          <h4 className="pb-2">Form Lembur Karyawan</h4>
          <Formik
            initialValues={
              isEditLemburKaryawan
                ? {
                    karyawan: [],
                    jumlah_jam: parseInt(isEditLemburKaryawan.jumlah_jam),
                  }
                : initialValueLemburKaryawan
            }
            onSubmit={(values) => {
              values.karyawan = isEditLemburKaryawan
                ? [isEditLemburKaryawan.karyawan]
                : selectedKaryawan;
              handleSubmitLemburKaryawan(
                values,
                idProyek || "",
                handleCloseModal,
                isEditLemburKaryawan ? false : true
              );
            }}
            validationSchema={lemburKaryawanSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="text"
                    name="karyawan"
                    id="c1"
                    className="form-select rounded-xs"
                    readOnly
                    onClick={() =>
                      !isEditLemburKaryawan && setModalDataKaryawan(true)
                    }
                    onChange={() => {}}
                    value={
                      isEditLemburKaryawan
                        ? isEditLemburKaryawan.karyawan.name
                        : selectedKaryawan.length < 1
                        ? "Silahkan Pilih"
                        : selectedKaryawan
                            .map((karyawan) => karyawan.label)
                            .join(", ")
                    }
                  />
                  <label htmlFor="c1" className="color-theme">
                    Nama Karyawan
                  </label>
                  <span>(required)</span>
                </div>
                <div className="form-custom mb-3 form-floating mt-3">
                  <Field
                    type="number"
                    name="jumlah_jam"
                    id="c2"
                    className="form-control rounded-xs"
                  />
                  <label htmlFor="c2" className="color-theme">
                    Jumlah Jam Lembur
                  </label>
                  <span>(required)</span>
                  {errors.jumlah_jam && touched.jumlah_jam && (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      {errors.jumlah_jam}
                    </div>
                  )}
                </div>
                <div className="d-flex" style={{ gap: 10 }}>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-yellow shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Batal
                  </button>
                  <button
                    className="btn btn-m btn-full rounded-sm gradient-green shadow-bg shadow-bg-xs mt-3"
                    style={{ width: "70%" }}
                    type="submit"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={modalDataKaryawan}
        onHide={() => setModalDataKaryawan(false)}
        dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
      >
        <div
          className="content"
          style={{ maxHeight: "75dvh", overflow: "auto" }}
        >
          <div className="d-flex pb-2">
            <div className="align-self-center">
              <h5 className="mb-n1 font-12 color-highlight font-700 text-uppercase pt-1">
                Data
              </h5>
              <h4 className="font-22 font-800 mb-3">Karyawan</h4>
            </div>
            <div className="align-self-center ms-auto">
              <span
                className="icon icon-m"
                onClick={() => setModalDataKaryawan(false)}
              >
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </span>
            </div>
          </div>
          {LIST_KARYAWAN_PROYEK.map((karyawan, karyawanKey) => {
            const isKaryawanAlreadySelected = selectedKaryawan.some(
              (item) => item.value === karyawan.uuid
            );
            return (
              <div
                className={`slide-right ${
                  isKaryawanAlreadySelected ? "bg-blue-dark" : "bg-theme"
                } rounded-m shadow-bg shadow-bg-s ${
                  karyawanKey !== 0 && "mt-3"
                } mb-3`}
                key={karyawanKey}
                style={{
                  height: 70,
                  animationDelay: Math.min(karyawanKey * 200, 1000) + "ms",
                  boxShadow:
                    "rgba(0,0,0,.03) 0 20px 25px -5px, rgba(0,0,0,.02) 0 10px 10px -5px",
                }}
                onClick={() => handleSelectedKaryawan(karyawan)}
              >
                <div
                  className="d-flex px-2 pb-2 pt-2"
                  style={{ height: "100%", alignItems: "center" }}
                >
                  <div className={`ps-2 align-self-center`}>
                    <h5
                      style={{ textTransform: "capitalize" }}
                      className={`ps-1 mb-0 line-height-xs pt-1 ${
                        isKaryawanAlreadySelected && "color-white"
                      }`}
                    >
                      {karyawan.name}{" "}
                    </h5>
                    <h6
                      className={`ps-1 mb-0 mt-1 font-400 opacity-80 ${
                        isKaryawanAlreadySelected && "color-white"
                      }`}
                    >
                      +{karyawan.no_hp}{" "}
                      <strong>({karyawan.jabatan.name})</strong>
                    </h6>
                  </div>
                  {isKaryawanAlreadySelected && (
                    <div
                      className="ms-auto d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <button
                        className="btn btn-xs gradient-red shadow-bg shadow-bg-s text-start me-2"
                        onClick={() => handleRemoveSelect(karyawan)}
                      >
                        <div className="bi bi-x-circle-fill font-20"></div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default ModalLembur;
