/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../../const";
import { toast } from "../../../../assets/components/toast";
import { Dispatch, SetStateAction } from "react";
import { QueryClient, useQuery } from "@tanstack/react-query";

export interface iInitialValuesItems {
  name: string;
  unit_id: string;
  // alert_qty: string;
  // alert_qty_value: string;
  sku: string;
  keterangan: string;
}

export const initialValuesItems: iInitialValuesItems = {
  name: "",
  unit_id: "",
  // alert_qty: "",
  // alert_qty_value: "",
  sku: "",
  keterangan: "",
};

export const itemsSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  unit_id: Yup.string().required("Please insert a value."),
  // alert_qty_value: Yup.number().when("alert_qty", {
  //   is: (alert_qty: string) =>
  //     alert_qty,
  //   then: () => Yup.number().min(1).required("Please insert a value."),
  //   otherwise: () => Yup.string().notRequired(),
  // }),
});

export const handleSubmitItem = (
  values: iInitialValuesItems,
  selectedFile: File | null,
  setIsEnableModal: Dispatch<SetStateAction<boolean>>,
  queryClient: QueryClient,
  idProyek: string | undefined,
  uuid: string
) => {
  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("name", values.name);
  dataToSend.append("unit_id", values.unit_id);
  // dataToSend.append("alert_qty", values.alert_qty);
  // dataToSend.append("alert_qty_value", values.alert_qty_value);
  dataToSend.append("proyek_id", idProyek as string);
  dataToSend.append("sku", values.sku);
  dataToSend.append("keterangan", values.keterangan);
  dataToSend.append("uuid", uuid);

  API_CLIENT.post("/warehouse/items", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        setIsEnableModal(false);
        queryClient.invalidateQueries({ queryKey: ["items-warehouse"] });
      }
    })
    .catch((err) => console.log(err));
};

const fetchItemsWarehouse = async (idProyek: string | undefined) => {
  const response = await API_CLIENT.get<any>(
    `/warehouse/items?idProyek=${idProyek}`
  );
  return response.data;
};

export const useFetchItemsWarehouse = (idProyek: string | undefined) => {
  return useQuery({
    queryKey: ["items-warehouse"],
    queryFn: () => fetchItemsWarehouse(idProyek),
  });
};
