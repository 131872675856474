/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import FormAdjustmentItems from "./form";
import { useFetchItemsAdjustment } from "./interface";
import { useParams } from "react-router-dom";
import { cashFlowFilter } from "../../management/details/pages/cashFlow/interface";
import ButtonFilterRange from "../../../../assets/components/filterRange";

import moment from "moment-timezone";
import { BiEdit } from "react-icons/bi";
import ButtonDelete from "../../../../assets/components/ButtonDelete";
import ModalDetailAdjustment from "./modalDetails";

const PageAdjustmentItems = () => {
  const { uuid: idProyek } = useParams();

  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);

  const { data, refetch } = useFetchItemsAdjustment(
    selectedFilter,
    idProyek,
    dateRange
  );

  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  const [isDataDetails, setIsDataDetails] = useState<any | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataHistory, setDataHistory] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const result = data.data;
      setDataHistory(result);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataHistory(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <>
      {!isEnableModal && (
        <>
          <div className="px-3">
            <div
              className="d-flex py-3"
              style={{
                gap: 10,
                marginTop: -25,
                overflow: "auto",
                maxWidth: "100%",
                whiteSpace: "nowrap",
              }}
            >
              {cashFlowFilter.map((filter, filterKey) => {
                return filter.value === "range" ? (
                  <ButtonFilterRange
                    label={filter.label}
                    value={filter.value}
                    callBack={setSelectedFilter}
                    isActive={selectedFilter === filter.value}
                    callBackValue={(value) => setDateRange(value)}
                    key={filterKey}
                  />
                ) : (
                  <button
                    onClick={() => setSelectedFilter(filter.value)}
                    key={filterKey}
                    className={`btn btn-xs slide-right ${
                      selectedFilter === filter.value
                        ? "gradient-blue shadow-bg-m"
                        : "border-blue-dark color-blue-dark"
                    }`}
                  >
                    {filter.label}
                  </button>
                );
              })}
            </div>
            <div
              className="d-flex py-2 slide-top"
              style={{ alignItems: "center" }}
            >
              <div>
                <h3 className="mb-0">Riwayat Penyesuaian Items</h3>
              </div>
            </div>
          </div>
          {dataHistory.length < 1 ? (
            <div
              className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
              style={{
                borderRadius: 15,
                animationDelay: Math.min(1 * 200, 1000) + "ms",
              }}
            >
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                Belum ada riwayat penyesuaian items
              </h5>
            </div>
          ) : (
            dataHistory.map((history, historyKey) => {
              return (
                <div
                  className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                    historyKey !== 0 && "mt-3"
                  } `}
                  key={historyKey}
                  style={{
                    animationDelay:
                      Math.min(historyKey * 200, 3000) + 10 + "ms",
                    borderRadius: 15,
                    minHeight: 55,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="d-flex w-100">
                    <div className="ps-2 align-self-center">
                      <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                        {moment(history.created_at)
                          .tz("Asia/Jakarta")
                          .format("LL")}
                      </h5>
                      <h4 className="ps-1 mb-0 font-400 font-12 mt-1">
                        Reference : {history.reference}
                      </h4>
                      <h4
                        className="ps-1 mb-0 font-400 font-12 mt-n1 color-highlight"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsOpen(true), setIsDataDetails(history);
                        }}
                      >
                        Lihat details
                      </h4>
                    </div>
                    {history.is_edit && (
                      <div className="ms-auto d-flex">
                        <button
                          className="btn-s me-2 btn gradient-green shadow-bg shadow-bg-xs"
                          onClick={() => {
                            setIsDataEdit(history), setIsEnableModal(true);
                          }}
                        >
                          <BiEdit />
                        </button>
                        <ButtonDelete
                          className="btn-s btn gradient-red shadow-bg shadow-bg-xs"
                          url="warehouse/items-adjustment"
                          KEY="items-adjustment"
                          values={history.uuid}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
          <button
            className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
            onClick={() => {
              setIsDataEdit(null), setIsEnableModal(true);
            }}
            style={{ zIndex: 999 }}
          >
            <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
          </button>
        </>
      )}
      {isEnableModal && (
        <FormAdjustmentItems
          isDataEdit={isDataEdit}
          setIsEnableModal={setIsEnableModal}
          setIsDataEdit={setIsDataEdit}
        />
      )}
      {isOpen && isDataDetails && (
        <ModalDetailAdjustment
          setIsEnableModal={setIsEnableModal}
          isDataDetails={isDataDetails}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default PageAdjustmentItems;
