/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  createRef,
  useEffect,
  useState,
} from "react";
import Layout from "../../assets/components/layout";
import SubmissionCRUD from "./modal";
import { handleDeleteSubmission, useFetchSubmission } from "./interface";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import allLocales from "@fullcalendar/core/locales-all";
import interactionPlugin from "@fullcalendar/interaction";
import { capitalize } from "../../const";
import { Modal } from "react-bootstrap";

import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";

function renderEventContent(eventInfo: {
  timeText:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  event: {
    title:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | Iterable<ReactNode>
      | ReactPortal
      | null
      | undefined;
  };
}) {
  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      <i>{eventInfo.event.title}</i>
    </>
  );
}

const PageSubmission = () => {
  const queryClient = useQueryClient();
  const { data } = useFetchSubmission();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);

  const [isMobileApps, setIsMobileApps] = useState<boolean>(false);

  const [dataSubmissions, setDataSubmissions] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<any | null>(null);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const calendarRef = createRef<any | null>();

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  useEffect(() => {
    const isMobile = document.querySelector(".mobile-apps");
    if (isMobile) {
      setIsMobileApps(true);
    }
    if (data) {
      const result = data.data.map((item: any) => {
        if (item.karyawan) {
          const start = new Date(item.start_date);
          const end = new Date(item.end_date + 1);

          const status =
            item.status === "izin"
              ? "gradient-orange color-white"
              : "gradient-red";

          const eventObject: {
            id: any;
            title: any;
            start: Date;
            end?: Date;
            classNames: string;
            data: any;
          } = {
            id: item.uuid,
            title: item.karyawan.name + " (" + capitalize(item.status) + ")",
            start: start,
            classNames: "slide-top badge-submission " + status,
            data: item,
          };

          if (start.toDateString() !== end.toDateString()) {
            eventObject.end = end;
          }

          return eventObject;
        }

        return null;
      });

      const filteredResult = result.filter((item: null) => item !== null);
      setDataSubmissions(filteredResult);
    }
  }, [data]);

  const handleDayClick = (info: any) => {
    info.view.calendar.changeView("listDay", info.dateStr);
  };

  const handleCloseDayGrid = () => {
    calendarRef.current.getApi().changeView("dayGridMonth");
  };

  const handleViewChange = (view: any) => {
    setCurrentView(view.view.type);
  };

  const handleEventClick = (eventInfo: any) => {
    const props = eventInfo.event._def.extendedProps.data;
    setIsEdit(props);
    setIsOpenDetails(true);
  };

  return (
    <Layout noPadding>
      <div className="card card-style card-calendar">
        <div className="content">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
            initialView="dayGridMonth"
            viewDidMount={handleViewChange}
            hiddenDays={[0]}
            contentHeight={`calc(100dvh - ${isMobileApps ? "240px" : "215px"})`}
            expandRows={true}
            events={dataSubmissions}
            locales={allLocales}
            locale="id"
            displayEventTime={false}
            eventContent={renderEventContent}
            dateClick={handleDayClick}
            eventClick={handleEventClick}
          />
        </div>
      </div>
      <button
        className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
        onClick={() => {
          currentView === "listDay" ? handleCloseDayGrid() : handleOpen();
          setIsEdit(null);
        }}
        style={{ zIndex: 999, bottom: "4.2rem" }}
      >
        {currentView === "listDay" ? (
          <i className="bi bi-x-lg" style={{ fontSize: 14 }}></i>
        ) : (
          <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
        )}
      </button>
      <Modal
        show={isOpenDetails}
        onHide={() => setIsOpenDetails(false)}
        dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
      >
        <div className="content" style={{ margin: 0 }}>
          <div
            className="d-flex align-items-center px-3 pt-2"
            style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.07)" }}
          >
            <div className="align-self-center">
              <h4 className="font-18 font-800 mb-0">Detail</h4>
            </div>
            <div className="align-self-center ms-auto">
              <span
                className="icon icon-m"
                onClick={() => setIsOpenDetails(false)}
              >
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </span>
            </div>
          </div>
          <div className="d-flex mt-3 px-3 flex-column">
            <table>
              <tbody>
                <tr>
                  <td width={95}>
                    <strong>Nama</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>{isEdit && isEdit.karyawan.name}</td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Status</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>{isEdit && capitalize(isEdit.status)}</td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Tanggal</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>
                    {isEdit &&
                      moment(new Date(isEdit.start_date)).format("D, MMM Y")}
                    {isEdit &&
                      isEdit.start_date !== isEdit.end_date &&
                      " - " +
                        moment(new Date(isEdit.end_date)).format("D, MMM Y")}
                  </td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Keterangan</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>{isEdit && capitalize(isEdit.keterangan)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {isEdit && isEdit.isEditable ? (
            <div className="d-flex mx-2 my-3" style={{ gap: 10 }}>
              <button
                className="btn-full btn gradient-red shadow-bg shadow-bg-m"
                style={{ width: "30%" }}
                onClick={() =>
                  handleDeleteSubmission(
                    isEdit && isEdit.uuid,
                    setIsOpenDetails,
                    queryClient
                  )
                }
              >
                Delete
              </button>
              <button
                className="btn-full btn gradient-blue shadow-bg shadow-bg-m"
                style={{ width: "70%" }}
                onClick={() => {
                  setIsOpenDetails(false), handleOpen();
                }}
              >
                Edit
              </button>
            </div>
          ) : (
            <button
              className="btn-full btn gradient-red shadow-bg shadow-bg-m mx-2 my-3"
              style={{ width: "96.5%" }}
              onClick={() => setIsOpenDetails(false)}
            >
              Close
            </button>
          )}
        </div>
      </Modal>
      {isEnableModal && (
        <SubmissionCRUD
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isEdit={isEdit}
        />
      )}
    </Layout>
  );
};

export default PageSubmission;
