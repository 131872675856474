/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import {
  ChangeEvent,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useState,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { toThousand } from "../../../../../../../const";
import {
  handleSubmitKehilanganBarang,
  // handleUpdateKehilanganBarang,
  kehilanganInventarisProyekSchema,
} from "./interface";
import { useParams } from "react-router-dom";

interface ModalKehilanganInventarisProps extends HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  isEdit?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  setIsRefresh?: Dispatch<SetStateAction<number>>;
  setSelectedInventaris: Dispatch<SetStateAction<any>>;
  selectedInventaris: any | null;
}

const ModalKehilanganInventaris: FC<ModalKehilanganInventarisProps> = ({
  isOpen,
  isEdit = false,
  setIsOpen,
  selectedInventaris,
  setIsEnableModal,
  setIsRefresh,
  setSelectedInventaris,
  ...props
}) => {
  const { uuid: idProyek } = useParams();

  const [totalNominal, setTotalNominal] = useState<number>(
    selectedInventaris &&
      selectedInventaris.inventaris.nilai * selectedInventaris.totalKurang
  );

  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
      setSelectedInventaris(null);
    }, 150);
  };

  return (
    <Offcanvas
      show={isOpen}
      className="bg-theme"
      style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
      onHide={() => handleCloseModal()}
      {...props}
      placement="bottom"
    >
      <Offcanvas.Body>
        <h6 className="font-700 mb-n1 color-highlight">Form</h6>
        <h4 className="pb-2">Pengembalian</h4>
        <Formik
          initialValues={{
            inventaris_id: selectedInventaris.uuid,
            karyawan_id: selectedInventaris.karyawan_id,
            ref_id: isEdit
              ? selectedInventaris.ref_id
              : selectedInventaris.uuid,
            jumlah: selectedInventaris.totalKurang,
            type: isEdit ? selectedInventaris.type : "",
          }}
          onSubmit={(values) => {
            // isEdit
            //   ? handleUpdateKehilanganBarang(
            //       values,
            //       idProyek,
            //       handleCloseModal,
            //       selectedInventaris.uuid
            //     )
            //   : 
              handleSubmitKehilanganBarang(
                  values,
                  idProyek,
                  handleCloseModal,
                  setIsRefresh
                );
          }}
          validationSchema={kehilanganInventarisProyekSchema}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <div className="form-field form-name mt-2">
                <label htmlFor="c1" className="contactNameField color-theme">
                  Nama Karyawan:
                  <span>(required)</span>
                </label>
                <Field
                  type="text"
                  name="karyawan_id"
                  className="round-small mb-0"
                  value={selectedInventaris.karyawan.name}
                  readOnly
                />
              </div>
              <div className="form-field form-name mt-2">
                <label htmlFor="c1" className="contactNameField color-theme">
                  Nama Barang:
                  <span>(required)</span>
                </label>
                <Field
                  type="text"
                  name="inventaris_id"
                  className="round-small mb-0"
                  value={selectedInventaris.inventaris.name}
                  readOnly
                />
              </div>
              <div className="form-field form-name mt-2">
                <label htmlFor="c1" className="contactNameField color-theme">
                  Type Pengembalian:
                  <span>(required)</span>
                </label>
                <Field
                  as="select"
                  type="text"
                  name="type"
                  className="round-small mb-0"
                >
                  <option disabled value="">
                    -- Silahkan PIlih --
                  </option>
                  <option value="pengembalian_barang">
                    Pengembalian Barang
                  </option>
                  <option value="pengembalian_uang">Pengembalian Uang</option>
                </Field>
              </div>
              {errors.type && touched.type && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.type as string}
                </div>
              )}
              <div className="form-field form-name mt-2">
                <label htmlFor="c1" className="contactNameField color-theme">
                  Jumlah:
                  <span>(required)</span>
                </label>
                <Field
                  min={1}
                  max={
                    isEdit
                      ? selectedInventaris.referensi.total_kurang
                      : selectedInventaris.totalKurang
                  }
                  type="number"
                  name="jumlah"
                  className="round-small mb-0"
                  value={values.jumlah}
                  onChange={(value: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("jumlah", value.target.value);
                    setTotalNominal(
                      selectedInventaris.inventaris.nilai *
                        parseInt(value.target.value)
                    );
                  }}
                />
              </div>
              {errors.jumlah && touched.jumlah && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.jumlah as string}
                </div>
              )}
              {values && values.type === "pengembalian_uang" && (
                <>
                  <div className="form-field form-name mt-2 slide-top">
                    <label
                      htmlFor="c1"
                      className="contactNameField color-theme"
                    >
                      Total Nominal:
                      <span>(required)</span>
                    </label>
                    <Field
                      type="text"
                      name="jumlah"
                      className="round-small mb-0"
                      value={toThousand(totalNominal)}
                      readOnly
                    />
                  </div>
                </>
              )}
              <div
                style={{
                  width: "100%",
                  gap: 10,
                  position: "relative",
                  zIndex: 1,
                }}
                className="d-flex mt-3"
              >
                <button
                  style={{ width: "30%" }}
                  type="button"
                  className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                  onClick={handleCloseModal}
                >
                  Batal
                </button>
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                >
                  Simpan Data
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ModalKehilanganInventaris;
