/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import {
  Dispatch,
  FC,
  SetStateAction,
  isValidElement,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { iConfig, iToastShow } from "../../../../window";

const generateUniqueId = (): string => {
  const randomString = Math.random().toString(36).substring(7);
  return `toast-slt-${randomString}`;
};

export const toast = {
  error: (title: string, message: string, config: iConfig) => {
    const newConfig: iConfig = {
      ...config,
      icons: config?.icons ? config.icons : "bi bi-exclamation-triangle-fill",
      closeOnClick: config.closeOnClick ? config.closeOnClick : true,
    };
    const newToast = {
      id: generateUniqueId(),
      title: title,
      message: message,
      type: "error",
      config: newConfig,
    };

    window.TOAST_GLOBAL = newToast;
    const event = new Event("TOAST_GLOBAL_CHANGE_EVENT");
    window.dispatchEvent(event);
  },
  success: (title: string, message: string, config: iConfig) => {
    const newConfig: iConfig = {
      ...config,
      icons: config?.icons ? config.icons : "bi bi-check",
      closeOnClick: config.closeOnClick ? config.closeOnClick : true,
    };
    const newToast = {
      id: generateUniqueId(),
      title: title,
      message: message,
      type: "success",
      config: newConfig,
    };

    window.TOAST_GLOBAL = newToast;
    const event = new Event("TOAST_GLOBAL_CHANGE_EVENT");
    window.dispatchEvent(event);
  },
  information: (title: string, message: string, config: iConfig) => {
    const newConfig: iConfig = {
      ...config,
      icons: config?.icons ? config.icons : "bi bi-info-circle-fill",
      closeOnClick: config.closeOnClick ? config.closeOnClick : true,
    };
    const newToast = {
      id: generateUniqueId(),
      title: title,
      message: message,
      type: "information",
      config: newConfig,
    };

    window.TOAST_GLOBAL = newToast;
    const event = new Event("TOAST_GLOBAL_CHANGE_EVENT");
    window.dispatchEvent(event);
  },
  warning: (title: string, message: string, config: iConfig) => {
    const newConfig: iConfig = {
      ...config,
      icons: config?.icons ? config.icons : "bi bi-exclamation-triangle",
      closeOnClick: config.closeOnClick ? config.closeOnClick : true,
    };
    const newToast = {
      id: generateUniqueId(),
      title: title,
      message: message,
      type: "warning",
      config: newConfig,
    };

    window.TOAST_GLOBAL = newToast;
    const event = new Event("TOAST_GLOBAL_CHANGE_EVENT");
    window.dispatchEvent(event);
  },
};

const ToastContainer = () => {
  const [toastGlobal, setToastGlobal] = useState<iToastShow[]>([]);
  const [removeToast, setRemoveToast] = useState<string>("");

  const handleToastGlobalChange = () => {
    setToastGlobal((prev) => [...prev, window.TOAST_GLOBAL]);
  };

  useEffect(() => {
    window.addEventListener(
      "TOAST_GLOBAL_CHANGE_EVENT",
      handleToastGlobalChange
    );

    return () => {
      window.removeEventListener(
        "TOAST_GLOBAL_CHANGE_EVENT",
        handleToastGlobalChange
      );
    };
  }, [toastGlobal]);

  useEffect(() => {
    toastGlobal.filter((object) => object.id !== removeToast);
  }, [removeToast]);

  return (
    <>
      {toastGlobal.map((toast, toastKey) => {
        return createPortal(
          <ToastElement
            setRemoveToast={setRemoveToast}
            key={toastKey}
            config={toast}
          />,
          document.body
        );
      })}
    </>
  );
};

interface ToastElementProps {
  config: iToastShow;
  setRemoveToast: Dispatch<SetStateAction<string>>;
}

export const ToastElement: FC<ToastElementProps> = ({
  config,
  setRemoveToast,
}) => {
  const [toastType, setToastType] = useState<string>("");
  const [isShowToast, setIsShowToast] = useState(false);
  const [isEnableToast, setIsEnableToast] = useState(true);

  const removeToast = () => {
    setIsShowToast(false);
    setTimeout(() => {
      setRemoveToast(config.id);
      setIsEnableToast(false);
    }, 250);
  };

  useEffect(() => {
    switch (config.type) {
      case "error":
        setToastType("bg-red-dark");
        break;
      case "success":
        setToastType("bg-green-dark");
        break;
      case "information":
        setToastType("bg-blue-dark");
        break;
      case "warning":
        setToastType("bg-yellow-dark");
        break;
      default:
        setToastType("bg-red-dark");
        break;
    }

    setTimeout(() => {
      setIsShowToast(true);

      if (config.config.autoClose > 0) {
        setTimeout(() => {
          removeToast();
        }, config.config.autoClose);
      }
    }, 150);
  }, [config]);

  return (
    <>
      {isEnableToast && (
        <div
          className={`toast toast-bar toast-top rounded-l ${toastType} ${
            config.config.fullWidth ? "toast-full" : ""
          } shadow-bg shadow-bg-s fade ${isShowToast ? "show" : "hide"}`}
          data-bs-delay={config.config.autoClose}
        >
          {config.config?.icons && (
            <div className="align-self-center">
              {config.config?.icons &&
                typeof config.config?.icons === "string" && (
                  <i
                    className={`icon icon-s rounded-l shadow-s ${config.config.icons} font-22 me-3`}
                  ></i>
                )}
              {config.config?.icons && isValidElement(config.config.icons) && (
                <>{config.config.icons}</>
              )}
            </div>
          )}
          <div className="align-self-center">
            <strong
              className={`${
                config.config.fullWidth ? "font-14" : "font-13"
              } mb-n2`}
            >
              {config.title}
            </strong>
            <span
              className={`${
                config.config.fullWidth ? "font-12" : "font-10"
              } mt-1 mb-1 opacity-80 line-height-s`}
              style={{ maxWidth: config.config.fullWidth ? "80%" : "" }}
            >
              {config.message}
            </span>
          </div>
          {config.config.closeOnClick && (
            <div className="align-self-center ms-auto">
              <button
                className="btn-close btn-close-white me-2 m-auto font-9"
                onClick={removeToast}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ToastContainer;
