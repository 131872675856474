/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from "react-router-dom";
import ChartProyek from "../../chart";
import { useEffect, useState } from "react";
import { useFetchDashboardMangement } from "./interface";
import Preloader from "../../../../../../assets/components/preloader";
import { toThousand } from "../../../../../../const";

const DashboardManagementProyek = () => {
  const { uuid: idProyek } = useParams();
  const { data: dataDashboard } = useFetchDashboardMangement(idProyek || null);

  const [dashboardConfig, setDashboardConfig] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (dataDashboard) {
      const result = dataDashboard.data;
      setDashboardConfig(result);
      setTimeout(() => {
        document.body.style.background = "#fafafa";
        setIsLoading(false);
      }, 250);
    }
  }, [dataDashboard]);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="card card-style">
            <div className="content">
              <ChartProyek
                xaxis={{
                  categories:
                    dashboardConfig && dashboardConfig.labelChartCashFlow,
                }}
                series={
                  dashboardConfig ? dashboardConfig.chartCashflow.series : []
                }
              />
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-column">
                    <h5 className="mb-0 font-13">Saldo</h5>
                    <h5 className="mb-0 font-24 mt-1 text-hidden-1">
                      Rp.{" "}
                      {toThousand((dashboardConfig.totalPemasukan - dashboardConfig.totalPengeluaran).toString())}
                    </h5>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-column">
                    <h5 className="mb-0 font-13">Pemasukan</h5>
                    <h5 className="mb-0 font-18 color-highlight mt-1 text-hidden-1">
                      Rp.{" "}
                      {toThousand(dashboardConfig.totalPemasukan.toString())}
                    </h5>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-column">
                    <h5 className="mb-0 font-13">Pengeluaran</h5>
                    <h5 className="mb-0 font-18 text-danger mt-1 text-hidden-1">
                      Rp.{" "}
                      {toThousand(dashboardConfig.totalPengeluaran.toString())}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Transaksi</h3>
            </div>
          </div>
          <div className="px-2">
            {dashboardConfig.lastTransaction.length < 1 ? (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada transaksi
                </h5>
              </div>
            ) : (
              dashboardConfig.lastTransaction
                .sort(
                  (a: { created_at: Date }, b: { created_at: Date }) =>
                    new Date(b.created_at).valueOf() -
                    new Date(a.created_at).valueOf()
                )
                .map((lastTransaction: any, lastTransactionKey: number) => {
                  return (
                    <div
                      key={lastTransactionKey}
                      className={`bg-theme slide-right mx-3 shadow-m mt-3 mb-3 py-1`}
                      style={{
                        borderRadius: 10,
                        animationDelay:
                          Math.min(lastTransactionKey * 200, 1000) + "ms",
                      }}
                    >
                      <div
                        className="d-flex px-2 pb-2 pt-2 pe-3"
                        style={{ alignItems: "center", height: "100%" }}
                      >
                        <div
                          className="ps-2 align-self-center"
                          style={{ width: "50%" }}
                        >
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14 text-hidden-1">
                            {lastTransaction.name}
                          </h5>
                          <div className="d-flex flex-column">
                            <span className="ps-1 mb-0 font-400 opacity-70 font-12">
                              {lastTransaction.formatted_created_at}
                            </span>
                            <span className="ps-1 mb-0 font-400 opacity-70 font-12 mt-n1">
                              {lastTransaction.penerima}
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto">
                          <h6
                            className={`ps-1 mb-0 line-height-xs pt-1 font-14 text-hidden-2 ${
                              lastTransaction.type === "pengeluaran" &&
                              "text-danger"
                            }`}
                          >
                            {lastTransaction.type === "pengeluaran" && "-"} Rp.{" "}
                            {toThousand(lastTransaction.jumlah.toString())}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Absensi</h3>
            </div>
          </div>
          <div className="px-4">
            {dashboardConfig.lastAbsensi.length < 1 ? (
              <div
                className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada riwayat absen
                </h5>
              </div>
            ) : (
              dashboardConfig.lastAbsensi.map(
                (karyawan: any, karyawanKey: any) => {
                  return (
                    <div
                      className={`slide-right bg-theme shadow-bg shadow-m py-2 ${
                        karyawanKey !== 0 && "mt-3"
                      } `}
                      key={karyawanKey}
                      style={{
                        animationDelay:
                          Math.min(karyawanKey * 200, 1000) + "ms",
                        borderRadius: 10,
                      }}
                    >
                      <div className="d-flex px-2 pb-2 pt-2">
                        {/* <div>
                          <img
                            src={karyawan.profile}
                            alt="profile"
                            width={55}
                            style={{
                              borderRadius: 10,
                            }}
                          />
                        </div> */}
                        <div className={`ps-2 align-self-center w-100`}>
                          <h5
                            style={{ textTransform: "capitalize" }}
                            className={`ps-1 mb-0 line-height-xs pt-1 d-flex`}
                          >
                            <span
                              className="text-hidden-1 me-2"
                              style={{ maxWidth: 250 }}
                            >
                              {karyawan.karyawan.name}
                            </span>
                            <span style={{ textTransform: "capitalize" }}>
                              ({karyawan.status})
                            </span>
                          </h5>
                          <h6 className={`ps-1 mb-0 mt-1 font-400 opacity-80`}>
                            +{karyawan.karyawan.no_hp}{" "}
                            <strong>({karyawan.karyawan.jabatan.name})</strong>
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DashboardManagementProyek;
