/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { toast } from "../../../../../../assets/components/toast";
import { API_CLIENT } from "../../../../../../const";
import { useQuery } from "@tanstack/react-query";
interface iInitialValueLemburKaryawan {
  karyawan: any[];
  jumlah_jam: number;
}

export const initialValueLemburKaryawan: iInitialValueLemburKaryawan = {
  karyawan: [],
  jumlah_jam: 0,
};

export const lemburKaryawanSchema = Yup.object().shape({
  jumlah_jam: Yup.number()
    .min(1, "Jumlah jam harus lebih besar dari atau sama dengan 1")
    .required("Please insert a value."),
});

export const handleSubmitLemburKaryawan = (
  values: any,
  idProyek: string,
  handleCloseModal: () => void,
  isCreated: boolean = true
) => {
  let STORE_BACKEND;

  const dataToSend = new FormData();
  dataToSend.append("jumlah_jam", values.jumlah_jam);
  dataToSend.append("idProyek", idProyek);

  if (isCreated) {
    if (values.karyawan.length < 1) {
      return toast.error("Error", "Silahkan Pilih Karyawan...", {
        autoClose: 3000,
        closeOnClick: true,
      });
    }

    dataToSend.append("karyawan", JSON.stringify(values.karyawan));
    STORE_BACKEND = API_CLIENT.post(
      "/proyek/management-lembur-karyawan",
      dataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } else {
    dataToSend.append("karyawan", JSON.stringify(values.karyawan[0]));
    STORE_BACKEND = API_CLIENT.post(
      "/proyek/management-lembur-karyawan-patch",
      dataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  STORE_BACKEND.then((response) => {
    const result = response.data;
    if (result.error) {
      toast.error("Error", result.message, {
        autoClose: 3000,
        closeOnClick: true,
      });
    } else {
      toast.success("Success", result.message, {
        autoClose: 3000,
        closeOnClick: true,
      });
    }
    handleCloseModal();
  }).catch((err) => console.log(err));
};

const fetchLemburKaryawanProyek = async (
  filter: string,
  proyek_id: string,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/management-lembur-karyawan?filter=${filter}&proyek_id=${proyek_id}&dateRange=${range}`
  );
  return response.data;
};

export const useFetchLemburKaryawanProyek = (
  filter: string,
  proyek_id: string,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["management-lembur-karyawan"],
    queryFn: () => fetchLemburKaryawanProyek(filter, proyek_id, dateRange),
  });
};

const fetchDailyPresentPictureProyek = async (
  uuid: string | undefined,
  filter: string,
  range?: any
) => {
  const dataSend = {
    uuid: uuid,
    filter: filter,
    range: range,
  };
  const response = await API_CLIENT.post<any>(
    "/proyek/daily-present-picture",
    dataSend
  );
  return response.data;
};

export const useFetchDailyPresentPictureProyek = (
  uuid: string | undefined,
  filter: string,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["proyek-daily-present-picture"],
    queryFn: () => fetchDailyPresentPictureProyek(uuid, filter, dateRange),
  });
};

const fetchDailyPresentProyek = async (
  uuid: string | undefined,
  isSubmission: boolean
) => {
  const dataSend = {
    uuid: uuid,
    isSubmission: isSubmission,
  };
  const response = await API_CLIENT.post<any>(
    "/proyek/daily-present",
    dataSend
  );
  return response.data;
};

export const useFetchDailyPresentProyek = (
  uuid: string | undefined,
  isSubmission?: boolean
) => {
  return useQuery({
    queryKey: ["proyek-daily-present"],
    queryFn: () =>
      fetchDailyPresentProyek(uuid, isSubmission ? isSubmission : false),
  });
};

const fetchPresentProyek = async (
  uuid: string | undefined,
  filter: string,
  range?: any
) => {
  const dataSend = {
    uuid: uuid,
    filter: filter,
    range: range,
  };
  const response = await API_CLIENT.post<any>("/proyek/present", dataSend);
  return response.data;
};

export const useFetchPresentProyek = (
  uuid: string | undefined,
  filter: string,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["proyek-present"],
    queryFn: () => fetchPresentProyek(uuid, filter, dateRange),
  });
};
