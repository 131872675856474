/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Layout from "../../assets/components/layout";
import { useFetchDailyPresentList } from "./interface";
import { cashFlowFilter } from "../proyek/management/details/pages/cashFlow/interface";
import ButtonFilterRange from "../../assets/components/filterRange";
import moment from "moment";

const ListAbsensiPage = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data: dailyPresent, refetch } = useFetchDailyPresentList(
    selectedFilter,
    dateRange
  );

  const [listAbsensi, setListAbsensi] = useState<any[]>([]);

  useEffect(() => {
    if (dailyPresent) {
      const result = dailyPresent.data;
      setListAbsensi(result);
    }
  }, [dailyPresent]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setListAbsensi(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <Layout>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "95%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div
        className="d-flex py-2 px-3 slide-top"
        style={{ alignItems: "center" }}
      >
        <div>
          <h3 className="mb-0">Riwayat Absensi</h3>
        </div>
      </div>
      <div
        style={{
          maxHeight: "calc(100dvh - 330px)",
          overflow: "auto",
          paddingBottom: 30,
          paddingTop: 10,
        }}
      >
        {listAbsensi.length < 1 ? (
          <div
            className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
            style={{
              borderRadius: 15,
              animationDelay: Math.min(1 * 200, 1000) + "ms",
            }}
          >
            <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
              Belum ada riwayat absen
            </h5>
          </div>
        ) : (
          listAbsensi.map((absensi, absensiKey) => {
            return (
              <div
                className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                  absensiKey !== 0 && "mt-3"
                } `}
                key={absensiKey}
                style={{
                  animationDelay: Math.min(absensiKey * 200, 1000) + "ms",
                  borderRadius: 15,
                }}
              >
                <div className="d-flex">
                  <div>
                    <a href={absensi.url_photo}>
                      <img src={absensi.url_photo} width={60} height={60} alt="photo absen" style={{ borderRadius:8 }} />
                    </a>
                  </div>
                  <div className="ps-2 align-self-center">
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                      {absensi.karyawan.name}{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        ({absensi.karyawan.jabatan.name})
                      </span>
                    </h5>
                    <span className="ps-1 mb-0 font-400 font-12">
                      {moment(new Date(absensi.created_at)).format("ll")}{" "}
                      {absensi.jam}
                      <span
                        className={`ms-2 ${
                          absensi.status === "MASUK"
                            ? "color-green-dark"
                            : "color-red-dark"
                        }`}
                      >
                        {absensi.status}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Layout>
  );
};

export default ListAbsensiPage;
