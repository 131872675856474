/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Form, Formik } from "formik";
import { Dispatch, FC, SetStateAction, useState } from "react";
import FormSelect from "../../../../assets/components/form/formSelect";
import {
  handleSubmitItem,
  iInitialValuesItems,
  initialValuesItems,
  itemsSchema,
} from "./interface";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

interface FormProductWarehouseProps {
  isDataEdit: any | null;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
  setIsDataEdit: Dispatch<SetStateAction<any | null>>;
}

const FormProductWarehouse: FC<FormProductWarehouseProps> = ({
  setIsEnableModal,
  setIsDataEdit,
  isDataEdit,
}) => {
  const { uuid: idProyek } = useParams();
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  return (
    <>
      <div className="card card-style slide-top" style={{ borderRadius: 10 }}>
        <div className="content">
          <h6 className="font-700 mb-n1 color-highlight">Form</h6>
          <h4 className="pb-2">Items Gudang</h4>
          <Formik
            initialValues={
              isDataEdit
                ? (isDataEdit as iInitialValuesItems)
                : initialValuesItems
            }
            onSubmit={(values) => {
              handleSubmitItem(
                values,
                selectedFile,
                setIsEnableModal,
                queryClient,
                idProyek,
                isDataEdit ? isDataEdit.uuid : ""
              );
            }}
            validationSchema={itemsSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div
                  style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                  className="form-field form-name slide-top mt-2"
                >
                  <label htmlFor="c1" className="contactNameField color-theme">
                    Name:
                    <span>(required)</span>
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="round-small mb-0"
                    placeholder="Semen..."
                  />
                </div>
                {errors.name && touched.name && (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                )}
                <div
                  style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                  className="form-field form-name slide-top mb-3 mt-3"
                >
                  <label className="contactNameField color-theme">
                    Satuan
                    <span>(required)</span>
                  </label>
                  <FormSelect
                    urlSelect={`warehouse/units-list/none`}
                    name="unit_id"
                    error={errors}
                    touched={touched}
                    className="round-small z-9"
                    menuPlacement="top"
                    isClearable
                    isDisabled={
                      isDataEdit && isDataEdit.stock.length > 1 ? true : false
                    }
                  />
                </div>
                <div
                  style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                  className="form-field form-name slide-top mt-2"
                >
                  <label htmlFor="c1" className="contactNameField color-theme">
                    SKU:
                    <span>(optional)</span>
                  </label>
                  <Field
                    type="text"
                    name="sku"
                    className="round-small mb-0"
                    placeholder="SK-0019218812"
                  />
                </div>
                {/* <div
                  className="form-field form-name slide-top mt-3"
                  style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                >
                  <div className="form-check form-switch">
                    <Field
                      name="alert_qty"
                      className="form-check-input mb-0 form-control-md me-2"
                      type="checkbox"
                      role="switch"
                      id="alert_qty"
                      style={{ height: 23, width: 43 }}
                    />
                    <label htmlFor="alert_qty" className="form-check-label">
                      Peringantan Qty
                    </label>
                  </div>
                </div>
                {values.alert_qty !== undefined && values.alert_qty !== "false" && values.alert_qty !== "" && (
                  <>
                    <div
                      style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                      className="form-field form-name slide-top mt-3"
                    >
                      <label
                        htmlFor="c1"
                        className="contactNameField color-theme"
                      >
                        Peringatan Qty Value:
                        <span>(optional)</span>
                      </label>
                      <Field
                        type="text"
                        name="alert_qty_value"
                        className="round-small mb-0"
                        placeholder="12"
                      />
                    </div>
                    {errors.alert_qty_value && touched.alert_qty_value && (
                      <div
                        style={{ display: "block" }}
                        className="invalid-feedback slide-top"
                      >
                        {errors.alert_qty_value}
                      </div>
                    )}
                  </>
                )} */}
                <div
                  className="form-field form-name mt-3 slide-top"
                  style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                >
                  <label
                    htmlFor="c2"
                    className="contactMessageTextarea color-theme"
                  >
                    Photo
                    <span>(optional)</span>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="c2"
                    className="form-control mb-0"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
                <div
                  className="form-field form-text mt-3 slide-top"
                  style={{ animationDelay: Math.min(2 * 200, 1000) + "ms" }}
                >
                  <label
                    htmlFor="c1"
                    className="contactMessageTextarea color-theme"
                  >
                    Keterangan:
                    <span>(optional)</span>
                  </label>
                  <Field
                    type="text"
                    name="keterangan"
                    id="c1"
                    as="textarea"
                    row={1}
                    className="round-small mb-0"
                    placeholder="Keterangan"
                    style={{ height: 80 }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    gap: 10,
                    position: "relative",
                    zIndex: 1,
                    animationDelay: Math.min(2 * 200, 1000) + "ms",
                  }}
                  className="d-flex mt-3 slide-top"
                >
                  <button
                    style={{ width: "30%" }}
                    type="button"
                    className="btn-full btn gradient-yellow shadow-bg shadow-bg-m"
                    onClick={() => {
                      setIsDataEdit(null), setIsEnableModal(false);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                  >
                    Simpan Data
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default FormProductWarehouse;
