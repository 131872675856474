/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { API_CLIENT } from "../../../../../../const";

const fetchDashboardMangement = async (idProyek: string | null) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/dashboard?idProyek=${idProyek}`
  );
  return response.data;
};

export const useFetchDashboardMangement = (idProyek: string | null) => {
  return useQuery({
    queryKey: ["dashboard-proyek"],
    queryFn: () => fetchDashboardMangement(idProyek),
  });
};
