/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient, useQuery } from "@tanstack/react-query";
import { toast } from "../../../../assets/components/toast";
import { API_CLIENT } from "../../../../const";
import { Dispatch, SetStateAction } from "react";

export interface iSelectItem {
  uuid: string;
  label: string;
  code: string;
  photo: string;
  select_unit: string;
  qty: number | string;
  stock: number;
  unit: {
    title: string;
    uuid_unit: string;
  }[];
}

export interface iInitialValuesItemsKeluar {
  reference: string;
  keterangan: string;
}

export const initialValuesItemsKeluar: iInitialValuesItemsKeluar = {
  reference: "",
  keterangan: "",
};

export const handleSubmitItemKeluar = (
  form: iInitialValuesItemsKeluar,
  idProyek: string,
  selectedFile: File | null,
  selectItem: iSelectItem[],
  setIsEnableModal: Dispatch<SetStateAction<boolean>>,
  queryClient: QueryClient,
  uuid: string
) => {
  if (selectItem.length < 1) {
    return toast.error("Error", "Silahkan pilih items terlebih daulu!!", {
      autoClose: 3000,
      closeOnClick: true,
    });
  }

  const dataToSend = new FormData();
  dataToSend.append("file", selectedFile as Blob);
  dataToSend.append("proyek_id", idProyek);
  dataToSend.append("uuid", uuid);
  dataToSend.append("reference", form.reference);
  dataToSend.append("keterangan", form.keterangan);
  dataToSend.append("items", JSON.stringify(selectItem) as string);

  API_CLIENT.post("/warehouse/items-keluar", dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
          fullWidth: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        setIsEnableModal(false);
        queryClient.invalidateQueries({ queryKey: ["items-keluar"] });
      }
    })
    .catch((err) => console.log(err));
};

const fetchItemsKeluar = async (
  filter: string,
  proyek_id: string | undefined,
  range?: string
) => {
  const response = await API_CLIENT.get<any>(
    `/warehouse/items-keluar?filter=${filter}&proyek_id=${proyek_id}&range=${range}`
  );
  return response.data;
};

export const useFetchItemsKeluar = (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["items-keluar"],
    queryFn: () => fetchItemsKeluar(filter, proyek_id, dateRange),
  });
};
