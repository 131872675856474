/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";

interface ButtonFilterRangeProps {
  label: string;
  value: string;
  isActive: boolean;
  callBack: Dispatch<SetStateAction<string>>;
  callBackValue?: (value: any) => void;
}

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
  startDateInJakarta: new Date().toLocaleDateString("id-ID", options),
  endDateInJakarta: new Date().toLocaleDateString("id-ID", options),
};

const ButtonFilterRange: FC<ButtonFilterRangeProps> = ({
  label,
  isActive = false,
  value,
  callBack,
  callBackValue,
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectDateRange, setSelectDateRange] = useState<any>(selectionRange);

  const handleSelect = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    const startDateInJakarta = new Date(startDate).toLocaleDateString(
      "id-ID",
      options
    );
    const endDateInJakarta = new Date(endDate).toLocaleDateString(
      "id-ID",
      options
    );

    setSelectDateRange({
      startDate,
      endDate,
      key: "selection",
      startDateInJakarta: startDateInJakarta,
      endDateInJakarta: endDateInJakarta,
    });
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleSubmitFilter = () => {
    setIsOpenModal(false);
    if (callBackValue) {
      callBackValue(selectDateRange);
    }
    setTimeout(() => {
      callBack(value);
    }, 150);
  };

  return (
    <>
      <button
        onClick={handleOpenModal}
        className={`btn btn-xs slide-right ${
          isActive
            ? "gradient-blue shadow-bg-m"
            : "border-blue-dark color-blue-dark"
        }`}
      >
        {label}
      </button>
      <Modal
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
        dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
      >
        <div className="content" style={{ margin: 0 }}>
          <div
            className="d-flex align-items-center px-3 pt-2"
            style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.07)" }}
          >
            <div className="align-self-center">
              <h4 className="font-18 font-800 mb-0">Silahkan pilih tanggal</h4>
            </div>
            <div className="align-self-center ms-auto">
              <span
                className="icon icon-m"
                onClick={() => setIsOpenModal(false)}
              >
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </span>
            </div>
          </div>
          <div className="d-flex mt-3 px-3 flex-column">
            <DateRange
              ranges={[selectDateRange]}
              preventSnapRefocus={true}
              showDateDisplay={false}
              calendarFocus="backwards"
              onChange={(range) => handleSelect(range)}
            />
          </div>
          <div className="d-flex">
            <button
              className="btn-full btn gradient-red shadow-bg shadow-bg-m mx-2 my-3"
              style={{ width: "30%" }}
              onClick={() => setIsOpenModal(false)}
            >
              Close
            </button>
            <button
              className="btn-full btn gradient-blue shadow-bg shadow-bg-m mx-2 my-3"
              style={{ width: "70%" }}
              onClick={() => handleSubmitFilter()}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ButtonFilterRange;
