/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import { BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";
import {
  API_CLIENT,
  caesarEncrypt,
  generateKeyRandom,
  textToNumber,
} from "../../../const";

interface ButtonDeleteProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  KEY: string | string[];
  values: string;
  url: string;
  props?: any;
  removeIcon?: boolean;
  children?: ReactNode;
}

const ButtonDelete: FC<ButtonDeleteProps> = ({
  KEY,
  url,
  values,
  removeIcon = false,
  children,
  ...props
}) => {
  const queryClient = useQueryClient();

  const deleteQuery = useMutation({
    mutationFn: (request: any) => {
      return API_CLIENT.delete(`/${url}/${request}`);
    },
    onSuccess: () => {
      if(typeof KEY === "string"){
        queryClient.invalidateQueries({
          queryKey: [KEY],
        });
      }else{
        KEY.map((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
          });
        })
      }
    },
  });

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    Swal.fire({
      title: "Anda Yakin?",
      text: "Anda tidak dapat mengembalikan data yang sudah di hapus!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Menghapus data",
          html: "Silahkan tunggu...",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const dataSend = caesarEncrypt(values, 20);
          const keyLength = values.length;
          const keyShuffle = generateKeyRandom(keyLength);
          const data = btoa(
            textToNumber(dataSend, keyShuffle) + ";" + keyShuffle
          );

          const response = await deleteQuery.mutateAsync(data);
          const result = response.data;
          if (result.error) {
            Swal.fire("Error", result.message, "error");
          } else {
            Swal.fire("Success", result.message, "success");
          }
        } catch (error) {
          Swal.fire("Error", "Terjadi kesalahan saat proses.", "error");
        }
      } else {
        Swal.fire("Dibatalkan", "Data anda tidak di hapus :)", "error");
      }
    });
  };

  return (
    <>
      <button
        className="btn gradient-red shadow-bg shadow-bg-m text-start ms-3"
        onClick={(event) => handleDelete(event)}
        {...props}
      >
        {!removeIcon && <BiTrash />}
        {children && children}
      </button>
    </>
  );
};

export default ButtonDelete;
