/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from "react-router-dom";
import Layout from "../../../../../../../assets/components/layout";
import {
  useFetchDetailsInventaris,
  useFetchStockInventaris,
} from "./interface";
import { useEffect, useState } from "react";
import Preloader from "../../../../../../../assets/components/preloader";
import ModalStockInventaris from "./modal";
import { cashFlowFilter } from "../../cashFlow/interface";
import ButtonFilterRange from "../../../../../../../assets/components/filterRange";
import moment from "moment";
import ButtonDelete from "../../../../../../../assets/components/ButtonDelete";

const PageDetailInventaris = () => {
  const { uuid } = useParams();
  const { data } = useFetchDetailsInventaris(uuid);

  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data: stockList, refetch } = useFetchStockInventaris(
    selectedFilter,
    uuid,
    dateRange
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailsInventaris, setDetailsInventaris] = useState<any | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  const [dataHistory, setDataHistory] = useState<any[]>([]);

  useEffect(() => {
    if (stockList) {
      const result = stockList.data;
      setDataHistory(result);
    }
  }, [stockList]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataHistory(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  const handleOpen = () => {
    setIsEnableModal(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };

  useEffect(() => {
    if (data) {
      const result = data.data;
      setDetailsInventaris(result);
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    }
  }, [data]);

  return (
    <Layout urlBack>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div
            className="card card-style slide-top"
            style={{ borderRadius: 10 }}
          >
            <div className="content">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td width={70}>Nama</td>
                    <td width={10}>:</td>
                    <td className="font-600 color-black">
                      {detailsInventaris.name}
                    </td>
                  </tr>
                  <tr>
                    <td width={70}>Stock</td>
                    <td width={10}>:</td>
                    <td className="font-600 color-black">
                      {detailsInventaris.stock}
                    </td>
                  </tr>
                  <tr>
                    <td width={70}>Sisa Stock</td>
                    <td width={10}>:</td>
                    <td className="font-600 color-black">
                      {detailsInventaris.stock - detailsInventaris.stock_pinjam}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="d-flex p-3"
            style={{
              gap: 10,
              marginTop: -25,
              overflow: "auto",
              maxWidth: "100%",
              whiteSpace: "nowrap",
            }}
          >
            {cashFlowFilter.map((filter, filterKey) => {
              return filter.value === "range" ? (
                <ButtonFilterRange
                  label={filter.label}
                  value={filter.value}
                  callBack={setSelectedFilter}
                  isActive={selectedFilter === filter.value}
                  callBackValue={(value) => setDateRange(value)}
                  key={filterKey}
                />
              ) : (
                <button
                  onClick={() => setSelectedFilter(filter.value)}
                  key={filterKey}
                  className={`btn btn-xs slide-right ${
                    selectedFilter === filter.value
                      ? "gradient-blue shadow-bg-m"
                      : "border-blue-dark color-blue-dark"
                  }`}
                >
                  {filter.label}
                </button>
              );
            })}
          </div>
          <div
            className="d-flex px-4 slide-top mb-2"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Penambahan Stock</h3>
            </div>
          </div>
          {dataHistory.length < 1 ? (
            <div
              className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
              style={{
                borderRadius: 15,
                animationDelay: Math.min(1 * 200, 1000) + "ms",
              }}
            >
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                Belum ada riwayat peminjaman
              </h5>
            </div>
          ) : (
            dataHistory.map((history, historyKey) => {
              return (
                <div
                  className={`bg-theme slide-right shadow-m mt-2 mb-2 py-3 px-2 mx-3 ${
                    historyKey !== 0 && "mt-3"
                  } `}
                  key={historyKey}
                  style={{
                    animationDelay: Math.min(historyKey * 200, 1000) + "ms",
                    borderRadius: 15,
                  }}
                >
                  <div className="d-flex">
                    <div className="ps-2 align-self-center">
                      <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                        {detailsInventaris.name} x {history.jumlah}
                      </h5>
                      <span className="ps-1 mb-0 font-400 font-12 mt-n1">
                        {moment(new Date(history.created_at)).format("ll")}
                      </span>
                    </div>
                    <div className="ms-auto d-flex gap-1">
                      {history.is_edit && (
                        <ButtonDelete
                          className="btn-s btn gradient-red shadow-bg shadow-bg-xs"
                          url="proyek/stock-inventaris"
                          KEY={["stock-inventaris", "details-inventaris"]}
                          values={history.uuid}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          <button
            className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
            onClick={() => {
              setIsDataEdit(null), handleOpen();
            }}
            style={{ zIndex: 999 }}
          >
            <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
          </button>
        </>
      )}
      {isEnableModal && (
        <ModalStockInventaris
          isOpen={isOpen}
          setIsEnableModal={setIsEnableModal}
          setIsOpen={setIsOpen}
          isDataEdit={isDataEdit}
        />
      )}
    </Layout>
  );
};

export default PageDetailInventaris;
