/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { API_CLIENT } from "../../../../const";
import { toast } from "../../../../assets/components/toast";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";

export interface iInitialValuesUnits {
  name: string;
  code: string;
  operator: string;
  operator_value: number;
  base_unit: string | null;
}

export const initialValuesUnits: iInitialValuesUnits = {
  name: "",
  code: "",
  operator: "",
  operator_value: 0,
  base_unit: null,
};

export const unitsSchema = Yup.object().shape({
  name: Yup.string().required("Please insert a value."),
  code: Yup.string().required("Please insert a value."),
  operator: Yup.string().when("base_unit", {
    is: (base_unit: string) => base_unit !== null && base_unit !== "",
    then: () => Yup.string().required("Please insert a value."),
    otherwise: () => Yup.string().notRequired(),
  }),
  operator_value: Yup.number().when("base_unit", {
    is: (base_unit: string) => base_unit !== null && base_unit !== "",
    then: () =>
      Yup.number()
        .min(1, "value must be greater than or equal to 1")
        .required("Please insert a value."),
    otherwise: () => Yup.string().notRequired(),
  }),
});

export const handleSubmitUnits = (
  values: iInitialValuesUnits,
  setIsEnableModal: Dispatch<SetStateAction<boolean>>,
  queryClient: QueryClient,
  uuid: string | null
) => {
  const dataTosend = {
    ...values,
    uuid: uuid,
  };
  API_CLIENT.post("/warehouse/units", dataTosend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        setIsEnableModal(false);
        queryClient.invalidateQueries({ queryKey: ["unit-warehouse"] });
      }
    })
    .catch((err) => console.log(err));
};

const fetchUnitWarehouse = async () => {
  const response = await API_CLIENT.get<any>("/warehouse/units");
  return response.data;
};

export const useFetchUnitWarehouse = () => {
  return useQuery({
    queryKey: ["unit-warehouse"],
    queryFn: fetchUnitWarehouse,
  });
};
