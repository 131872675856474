/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { API_CLIENT } from "../../const";

const fetchDashboardKaryawan = async (year: number) => {
  const response = await API_CLIENT.get<any>(
    `/dashboard/karyawan?year=${year}`
  );
  return response.data;
};

export const useFetchDashboardKaryawan = (year: number) => {
  return useQuery({
    queryKey: ["dashboard-karyawan"],
    queryFn: () => fetchDashboardKaryawan(year),
  });
};

const fetchDashboardKaryawanProyek = async () => {
  const response = await API_CLIENT.get<any>(
    `/dashboard/karyawan-proyek`
  );
  return response.data;
};

export const useFetchDashboardKaryawanProyek = () => {
  return useQuery({
    queryKey: ["dashboard-karyawan"],
    queryFn: () => fetchDashboardKaryawanProyek(),
  });
};

const fetchDashboardSuperAdmin = async (year: number) => {
  const response = await API_CLIENT.get<any>(
    `/dashboard/super-admin?year=${year}`
  );
  return response.data;
};

export const useFetchDashboardSuperAdmin = (year: number) => {
  return useQuery({
    queryKey: ["dashboard-super-admin"],
    queryFn: () => fetchDashboardSuperAdmin(year),
  });
};
