/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Layout from "../../../../assets/components/layout";
import FormUnitProyeks from "./form";
import { useFetchUnitWarehouse } from "./interface";
import ButtonDelete from "../../../../assets/components/ButtonDelete";
import { BiEdit } from "react-icons/bi";

const PageUnitsWarehouse = () => {
  const { data } = useFetchUnitWarehouse();

  const [isEnableModal, setIsEnableModal] = useState<boolean>(false);
  const [isDataEdit, setIsDataEdit] = useState<any | null>(null);

  const [listUnits, setListUnits] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const result = data.data;
      setListUnits(result);
    }
  }, [data]);

  return (
    <Layout>
      {!isEnableModal && (
        <>
          <div
            className="d-flex px-3 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Data Units</h3>
            </div>
          </div>
          {listUnits.length < 1 ? (
            <div
              className="bg-theme slide-right shadow-m mt-2 mb-2 py-4 px-3 mx-3"
              style={{
                borderRadius: 15,
                animationDelay: Math.min(1 * 200, 1000) + "ms",
              }}
            >
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                Belum ada data units
              </h5>
            </div>
          ) : (
            listUnits
              .sort((a: any, b: any) => {
                return a.can_delete === b.can_delete
                  ? 0
                  : a.can_delete
                  ? 1
                  : -1;
              })
              .map((list, listKey) => {
                return (
                  <div
                    className={`bg-theme slide-right shadow-m mt-2 mb-2 py-2 px-2 mx-3 ${
                      listKey !== 0 && "mt-3"
                    } `}
                    key={listKey}
                    style={{
                      animationDelay: Math.min(listKey * 200, 2000) + 10 + "ms",
                      borderRadius: 15,
                      minHeight: 55,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex w-100">
                      <div className="ps-2 align-self-center">
                        <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14">
                          {list.name}{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            ({list.code})
                          </span>
                        </h5>
                        {list.unit && (
                          <div className="d-flex flex-column">
                            <h4 className="ps-1 mb-0 font-400 font-12 mt-1">
                              {list.unit.name} ({list.unit.code}){" "}
                              {list.operator} {list.operator_value} ={" "}
                              {list.name} ({list.code})
                            </h4>
                          </div>
                        )}
                      </div>
                      <div className="ms-auto">
                        <button
                          className="btn-s btn gradient-green shadow-bg shadow-bg-xs me-2"
                          onClick={() => {
                            setIsDataEdit(list), setIsEnableModal(true);
                          }}
                        >
                          <BiEdit />
                        </button>
                        {list.can_delete && (
                          <ButtonDelete
                            className="btn-s btn gradient-red shadow-bg shadow-bg-xs"
                            url="warehouse/units"
                            KEY="unit-warehouse"
                            values={list.uuid}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
          )}
          <button
            className="btn btn-fab slide-top gradient-orange shadow-bg shadow-bg-xs"
            onClick={() => {
              setIsDataEdit(null), setIsEnableModal(true);
            }}
            style={{ zIndex: 999 }}
          >
            <i className="bi bi-plus-lg" style={{ fontSize: 18 }}></i>
          </button>
        </>
      )}
      {isEnableModal && (
        <FormUnitProyeks
          isDataEdit={isDataEdit}
          setIsEnableModal={setIsEnableModal}
          setIsDataEdit={setIsDataEdit}
        />
      )}
    </Layout>
  );
};

export default PageUnitsWarehouse;
