/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, FC, SetStateAction } from "react";
import { Offcanvas } from "react-bootstrap";

import moment from "moment-timezone";

interface ModalDetailPengeluaranProps {
  isDataDetails: any;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsEnableModal: Dispatch<SetStateAction<boolean>>;
}

const ModalDetailPengeluaran: FC<ModalDetailPengeluaranProps> = ({
  isOpen,
  setIsOpen,
  isDataDetails,
  setIsEnableModal,
  ...props
}) => {
  const handleCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsEnableModal(false);
    }, 150);
  };
  return (
    <>
      <Offcanvas
        show={isOpen}
        className="bg-theme"
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          maxHeight: "calc(100dvh - 60px)",
        }}
        onHide={() => handleCloseModal()}
        {...props}
        placement="bottom"
      >
        <Offcanvas.Body className="px-0">
          <div className="d-flex pb-2 px-4">
            <div className="align-self-center">
              <h5 className="mb-n1 font-11 color-highlight font-700 text-uppercase pt-1">
                Pengeluaran Items
              </h5>
              <h3 className="font-700 font-18">{isDataDetails.reference}</h3>
            </div>
            <div className="align-self-center ms-auto">
              <span className="icon icon-m" onClick={() => handleCloseModal()}>
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </span>
            </div>
          </div>
          <div className="p-3" style={{ background: "rgb(243 244 246 / 1)" }}>
            <div className="bg-white py-4" style={{ borderRadius: 10 }}>
              <div className="px-4">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td width={90}>Tanggal</td>
                      <td width={10}>:</td>
                      <td>
                        {moment(new Date(isDataDetails.created_at))
                          .tz("Asia/Jakarta")
                          .format("ll")}
                      </td>
                    </tr>
                    <tr>
                      <td width={90}>Refence</td>
                      <td width={10}>:</td>
                      <td>{isDataDetails.reference}</td>
                    </tr>
                    <tr>
                      <td width={90}>Di Buat</td>
                      <td width={10}>:</td>
                      <td>
                        {moment(new Date(isDataDetails.created_at))
                          .tz("Asia/Jakarta")
                          .format("LLL")}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <strong>Untuk</strong>
                      </td>
                    </tr>
                    <tr>
                      <td width={90}>Proyek</td>
                      <td width={10}>:</td>
                      <td>{isDataDetails.proyek.name}</td>
                    </tr>
                    <tr>
                      <td width={90}>Di Buat Oleh</td>
                      <td width={10}>:</td>
                      <td>{isDataDetails.user.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table className="mt-2 w-100 table color-theme">
                <thead>
                  <tr>
                    <th scope="col" className="ps-4">
                      <strong>No.</strong>
                    </th>
                    <th scope="col">
                      <strong>Item</strong>
                    </th>
                    <th scope="col" className="pe-4">
                      <strong>Qty</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isDataDetails.details.map(
                    (details: any, detailsKey: number) => {
                      return (
                        <tr key={detailsKey}>
                          <td width={10} className="ps-4">
                            {detailsKey + 1}
                          </td>
                          <td>{details.items.name}</td>
                          <td width={110} className="pe-4">
                            <strong>{details.qty}</strong> {details.unit.code}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              <div className="px-4">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td width={90}>Catatan</td>
                      <td width={10} colSpan={2}>
                        :
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>{isDataDetails.keterangan}</td>
                    </tr>
                    {isDataDetails.file && (
                      <tr>
                        <td colSpan={3}>
                          <a href={isDataDetails.file}>Attachment</a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ModalDetailPengeluaran;
