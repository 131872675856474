/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from "react";
import { API_CLIENT } from "../../../../../../../const";
import * as Yup from "yup";
import { toast } from "../../../../../../../assets/components/toast";
import { useQuery } from "@tanstack/react-query";

export const getListPeminjamanInventarisProyek = (
  idKaryawan: string,
  idProyek: string | undefined,
  setListPeminjaman: Dispatch<SetStateAction<any[]>>
) => {
  const dataToSend = {
    idKaryawan: idKaryawan,
    idProyek: idProyek,
  };
  API_CLIENT.post("/proyek/kehilangan-list-inventaris", dataToSend)
    .then((response) => {
      const result = response.data;
      setListPeminjaman(result.data);
    })
    .catch((err) => console.log(err));
};

export const kehilanganInventarisProyekSchema = Yup.object().shape({
  type: Yup.string().required("Please insert a value"),
  jumlah: Yup.number().min(1).required("Please insert a value"),
});

export const handleSubmitKehilanganBarang = (
  selectedInventaris: any,
  idProyek: string | undefined,
  handleCloseModal: () => void,
  setIsRefresh?: Dispatch<SetStateAction<number>>,
) => {
  const dataToSend = {
    ...selectedInventaris,
    idProyek: idProyek,
  };

  API_CLIENT.post("/proyek/kehilangan-inventaris", dataToSend)
    .then((response) => {
      const result = response.data;
      if (result.error) {
        toast.error("Error", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.success("Success", result.message, {
          autoClose: 3000,
          closeOnClick: true,
        });
        handleCloseModal();
        setTimeout(() => {
          setIsRefresh && setIsRefresh((prev) => prev + 1);
        }, 150);
      }
    })
    .catch((err) => console.log(err));
};

export const handleUpdateKehilanganBarang = (
  selectedInventaris: any,
  idProyek: string | undefined,
  // handleCloseModal: () => void,
  uuid: string,
) => {
  const dataToSend = {
    ...selectedInventaris,
    idProyek: idProyek,
    uuid: uuid,
  };

  API_CLIENT.post("/proyek/update-kehilangan-inventaris", dataToSend)
    .then((response) => {
      const result = response.data;
      return result
      // if (result.error) {
      //   toast.error("Error", result.message, {
      //     autoClose: 3000,
      //     closeOnClick: true,
      //   });
      // } else {
      //   toast.success("Success", result.message, {
      //     autoClose: 3000,
      //     closeOnClick: true,
      //   });
      //   handleCloseModal();
      // }
    })
    .catch((err) => console.log(err));
};

const fetchKehilanganInvetarisProyek = async (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const response = await API_CLIENT.get<any>(
    `/proyek/kehilangan-inventaris?filter=${filter}&proyek_id=${proyek_id}&range=${range}`
  );
  return response.data;
};

export const useFetchKehilanganInvetarisProyek = (
  filter: string,
  proyek_id: string | undefined,
  range?: any
) => {
  const dateRange = btoa(JSON.stringify(range));
  return useQuery({
    queryKey: ["kehilangan-inventaris"],
    queryFn: () => fetchKehilanganInvetarisProyek(filter, proyek_id, dateRange),
  });
};
