/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  settingProyekProps,
  useFetchSettingProyek,
} from "../../../../../settingProyek/interface";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { useFetchDailyPresentPictureProyek } from "./interface";
import { cashFlowFilter } from "../cashFlow/interface";
import ButtonFilterRange from "../../../../../../assets/components/filterRange";

const AbsenPhotoProyek = () => {
  const { uuid: idProyek } = useParams();
  const { data: setting } = useFetchSettingProyek();
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);

  const { data: dailyPresent, refetch } = useFetchDailyPresentPictureProyek(
    idProyek,
    selectedFilter,
    dateRange
  );

  const [settingAbsensi, setSettingAbsensi] =
    useState<settingProyekProps | null>(null);

  const [isAbsen, setIsAbsen] = useState<boolean>(true);
  const [isStatusPresent] = useState<number>(0);

  const [historyPresent, setHistoryPresent] = useState<any[]>([]);
  const [keyGlobal, setKeyGlobal] = useState<number>(1);

  useEffect(() => {
    if (setting) {
      const result = setting.data;
      setSettingAbsensi(result);
    }
  }, [setting]);

  useEffect(() => {
    if (dailyPresent) {
      const result = dailyPresent.data;
      setHistoryPresent(result);
    }
  }, [dailyPresent]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setKeyGlobal((prev) => prev + 1);
        setHistoryPresent(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  useEffect(() => {
    function updateEveryMinute() {
      if (settingAbsensi) {
        moment().tz("Asia/Jakarta");

        const currentTime = moment();
        const waktuAbsenMasuk = moment(settingAbsensi?.jam_masuk, "HH:mm");
        const waktuAbsenKeluar = moment(settingAbsensi?.jam_keluar, "HH:mm");

        if (isStatusPresent === 0) {
          if (
            currentTime
              .clone()
              .isBefore(
                waktuAbsenMasuk
                  .clone()
                  .subtract(settingAbsensi.toleransi_masuk, "minutes")
              )
          ) {
            setIsAbsen(false);
          } else if (
            currentTime
              .clone()
              .isAfter(
                waktuAbsenMasuk
                  .clone()
                  .subtract(settingAbsensi.toleransi_masuk, "minutes")
              ) &&
            currentTime
              .clone()
              .isBefore(
                waktuAbsenMasuk
                  .clone()
                  .add(settingAbsensi.toleransi_masuk, "minutes")
              )
          ) {
            setIsAbsen(true);
          } else {
            setIsAbsen(false);
          }
        } else {
          if (currentTime.clone().isBefore(waktuAbsenKeluar.clone())) {
            setIsAbsen(false);
          }
        }
      }
    }

    updateEveryMinute();
    const intervalId = setInterval(updateEveryMinute, 60000);
    return () => clearInterval(intervalId);
  }, [settingAbsensi, isStatusPresent]);

  return (
    <>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "95%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      {isAbsen && (
        <div className="card card-style" style={{ borderRadius: 10 }}>
          <div className="content">
            <a href={`/absen-photo/${idProyek}`} className="card-photo">
              <i className="bi bi-camera"></i>
              <span>Klik untuk photo absen</span>
            </a>
          </div>
        </div>
      )}
      <div className="px-3">
        <div className="d-flex py-2 slide-top" style={{ alignItems: "center" }}>
          <div>
            <h3 className="mb-0">Riwayat Absen</h3>
          </div>
        </div>
      </div>
      {historyPresent.length < 1 ? (
        <div
          className="d-flex justify-content-center py-5 mt-3"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <img
            src="/images/no-data.svg"
            alt="no transaction"
            style={{ width: "50%" }}
            className="slide-top"
          />
          <span
            className="ps-1 mb-0 font-400 mt-4 font-18 slide-top"
            style={{ animationDelay: "200ms" }}
          >
            Belum ada Data.
          </span>
        </div>
      ) : (
        historyPresent.map((present, presentKey) => {
          return (
            <div
              className="bg-theme slide-right mx-3 shadow-m mt-3 mb-3"
              key={keyGlobal + presentKey}
              style={{
                borderRadius: 10,
                // minHeight: 83,
                animationDelay: Math.min(presentKey * 200, 1000) + "ms",
              }}
            >
              <div className="d-flex px-2 pb-2 pt-2">
                <a href={present.url_photo}>
                  <img
                    src={present.url_photo}
                    alt="photo"
                    width={70}
                    height={70}
                    className="rounded-s"
                  />
                </a>
                <div className={`ps-2 align-self-center flex-column d-flex`}>
                  <span className="ps-1 mb-0 line-height-xs pt-1">
                    Keterangan : {present.keterangan}
                  </span>
                  <span className="ps-1 mb-0 line-height-xs pt-1">
                    {present.jam}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      )}
      <div style={{ paddingBottom: "2rem" }} />
    </>
  );
};

export default AbsenPhotoProyek;
