/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Layout from "../../assets/components/layout";
import { useFetchSubmissionAdmin } from "./interface";
import NoData from "../../assets/components/noData";
import { Modal } from "react-bootstrap";
import { capitalize } from "../../const";
import { cashFlowFilter } from "../proyek/management/details/pages/cashFlow/interface";
import ButtonFilterRange from "../../assets/components/filterRange";

const PageSubmissionAdmin = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>("today");
  const [dateRange, setDateRange] = useState<any | null>(null);
  const { data, refetch } = useFetchSubmissionAdmin(selectedFilter, dateRange);

  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<any | null>(null);
  const [dataSubmissions, setDataSubmissions] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      setDataSubmissions(data.data);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        setDataSubmissions(result);
      }
    };

    fetchData();
  }, [selectedFilter, refetch, dateRange]);

  return (
    <Layout>
      <div
        className="d-flex p-3"
        style={{
          gap: 10,
          marginTop: -25,
          overflow: "auto",
          maxWidth: "95%",
          whiteSpace: "nowrap",
        }}
      >
        {cashFlowFilter.map((filter, filterKey) => {
          return filter.value === "range" ? (
            <ButtonFilterRange
              label={filter.label}
              value={filter.value}
              callBack={setSelectedFilter}
              isActive={selectedFilter === filter.value}
              callBackValue={(value) => setDateRange(value)}
              key={filterKey}
            />
          ) : (
            <button
              onClick={() => setSelectedFilter(filter.value)}
              key={filterKey}
              className={`btn btn-xs slide-right ${
                selectedFilter === filter.value
                  ? "gradient-blue shadow-bg-m"
                  : "border-blue-dark color-blue-dark"
              }`}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div className="d-flex px-4 slide-top" style={{ alignItems: "center" }}>
        <div>
          <h3 className="mb-0">Riwayat Izin</h3>
        </div>
      </div>
      {dataSubmissions.length < 1 ? (
        <NoData />
      ) : (
        dataSubmissions.map((submission, submissionKey) => {
          return (
            <div
              className="bg-theme slide-right mx-3 shadow-m mt-3 mb-3"
              key={submissionKey}
              style={{
                borderRadius: 10,
                //   minHeight: 83,
                animationDelay: Math.min(submissionKey * 200, 1000) + "ms",
              }}
              onClick={() => {
                setIsEdit(submission), setIsOpenDetails(true);
              }}
            >
              <div
                className="d-flex px-2 pb-2 pt-3 pe-3"
                style={{ alignItems: "center", height: "100%" }}
              >
                <div
                  className="ps-2 align-self-center"
                  style={{ width: "100%" }}
                >
                  <h5 className="ps-1 mb-0 line-height-xs pt-1 font-18 text-hidden-1">
                    {submission.karyawan.name}{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      ({submission.status})
                    </span>
                  </h5>
                  <span className="ps-1 mb-0 font-400 opacity-70 font-12">
                    {submission.formatted_start_date}
                    {submission.formatted_end_date !==
                      submission.formatted_start_date &&
                      " s/d " + submission.formatted_start_date}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      )}

      <Modal
        show={isOpenDetails}
        onHide={() => setIsOpenDetails(false)}
        dialogClassName="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme show modal-offcanvas-show"
      >
        <div className="content" style={{ margin: 0 }}>
          <div
            className="d-flex align-items-center px-3 pt-2"
            style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.07)" }}
          >
            <div className="align-self-center">
              <h4 className="font-18 font-800 mb-0">Detail</h4>
            </div>
            <div className="align-self-center ms-auto">
              <span
                className="icon icon-m"
                onClick={() => setIsOpenDetails(false)}
              >
                <i className="bi bi-x-circle-fill color-red-dark font-18 me-n4"></i>
              </span>
            </div>
          </div>
          <div className="d-flex mt-3 px-3 flex-column">
            <table>
              <tbody>
                <tr>
                  <td width={95}>
                    <strong>Nama</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>{isEdit && isEdit.karyawan.name}</td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Status</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>{isEdit && capitalize(isEdit.status)}</td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Tanggal</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>
                    {isEdit && isEdit.formatted_start_date}
                    {isEdit &&
                      isEdit.start_date !== isEdit.end_date &&
                      " s/d " + isEdit.formatted_end_date}
                  </td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Keterangan</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>{isEdit && capitalize(isEdit.keterangan)}</td>
                </tr>
                <tr>
                  <td width={95}>
                    <strong>Bukti</strong>
                  </td>
                  <td width={10}>:</td>
                  <td>
                    {isEdit && isEdit.url_file && (
                      <>
                        <a href={isEdit.url_file} className="ps-1">
                          Bukti Pengajuan
                        </a>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            className="btn-full btn gradient-red shadow-bg shadow-bg-m mx-2 my-3"
            style={{ width: "96.5%" }}
            onClick={() => setIsOpenDetails(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default PageSubmissionAdmin;
