/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Layout from "../../assets/components/layout";
import { useFetchDashboardSuperAdmin } from "./interface";
import { greet, toThousand } from "../../const";
import { yearRange } from "../proyek/laporan/interface";
import Preloader from "../../assets/components/preloader";
import ChartProyek from "../proyek/management/details/chart";

import moment from "moment-timezone";

const DashboardSuperAdmin = () => {
  const [selectYear, setSelectYear] = useState<number>(
    new Date().getFullYear()
  );
  const { data, refetch } = useFetchDashboardSuperAdmin(selectYear);

  const [isLoading, setIsLoading] = useState(true);
  const [listDashboardTop, setListDashboardTop] = useState<any>(null);
  const [listDashboardProyek, setListDashboardProyek] = useState<any>(null);
  const [listDashboardCashFlow, setListDashboardCashFlow] = useState<any>(null);
  const [listDashboardHistory, setListDashboardHistory] = useState<any>(null);

  useEffect(() => {
    // document.body.style.background = "rgb(232 232 232 / 19%)";
    if (data) {
      const result = data.data;
      const { dashboardTop, proyek, chartCashflow, history } = result;
      setListDashboardTop(dashboardTop);
      setListDashboardProyek(proyek);
      setListDashboardCashFlow(chartCashflow);
      setListDashboardHistory(history);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await refetch();

      if (newData) {
        const result = newData.data.data;
        const { dashboardTop, proyek, chartCashflow, history } = result;
        setListDashboardTop(dashboardTop);
        setListDashboardProyek(proyek);
        setListDashboardCashFlow(chartCashflow);
        setListDashboardHistory(history);
      }
    };

    fetchData();
  }, [selectYear, refetch]);

  return (
    <Layout noPaddingBottom>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="card card-style" style={{ borderRadius: 10 }}>
            <div className="content">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>
                  <p className="ps-1 pb-0 mb-0 font-600 font-16">{greet()}</p>
                </div>
                <div className="ms-auto">
                  <div className="form-custom form-label" style={{ margin: 0 }}>
                    <select
                      className="form-select rounded-xs font-17 no-padding font-600 color-blue-dark"
                      value={selectYear}
                      onChange={(event) =>
                        setSelectYear(parseInt(event.target.value))
                      }
                      style={{ border: "none" }}
                    >
                      {yearRange.map((year, yearKey) => {
                        return (
                          <option key={yearKey} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-3 flex-column d-flex align-items-center gap-2">
                  <button
                    className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                    style={{ padding: "12px 18px", borderRadius: "13px" }}
                  >
                    <i className="bi bi-person-raised-hand font-18"></i>
                    {listDashboardTop && listDashboardTop.countPresent > 0 && (
                      <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                        {listDashboardTop.countPresent > 99
                          ? "99+"
                          : listDashboardTop.countPresent}
                      </span>
                    )}
                  </button>
                  <span className="color-blue-dark font-600 font-12">
                    Kehadiran
                  </span>
                </div>
                <div className="col-3 flex-column d-flex align-items-center gap-2">
                  <button
                    className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                    style={{ padding: "12px 18px", borderRadius: "13px" }}
                  >
                    <i className="bi bi-geo font-18"></i>
                    {listDashboardTop && listDashboardTop.countIzin > 0 && (
                      <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                        {listDashboardTop.countIzin}
                      </span>
                    )}
                  </button>
                  <span className="color-blue-dark font-600 font-12">Izin</span>
                </div>
                <div className="col-3 flex-column d-flex align-items-center gap-2">
                  <button
                    className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                    style={{ padding: "12px 18px", borderRadius: "13px" }}
                  >
                    <i className="bi bi-emoji-tear font-18"></i>
                    {listDashboardTop && listDashboardTop.countSakit > 0 && (
                      <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                        {listDashboardTop.countSakit}
                      </span>
                    )}
                  </button>
                  <span className="color-blue-dark font-600 font-12">
                    Sakit
                  </span>
                </div>
                <div className="col-3 flex-column d-flex align-items-center gap-2">
                  <button
                    className="btn bg-fade1-blue no-shadow color-blue-dark text-start position-relative"
                    style={{ padding: "12px 18px", borderRadius: "13px" }}
                  >
                    <i className="bi bi-clock font-18"></i>
                    {listDashboardTop &&
                      listDashboardTop.countPresentLate > 0 && (
                        <span className="position-absolute top-30 start-90 translate-middle badge rounded-pill bg-danger badge-custom">
                          {listDashboardTop.countPresentLate}
                        </span>
                      )}
                  </button>
                  <span className="color-blue-dark font-600 font-12">
                    Terlambat
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-style mt-n2" style={{ borderRadius: 10 }}>
            <div className="content">
              <h5 className="ps-1 mb-0 line-height-xs pt-1 font-18">
                Cash Flow
              </h5>
              <ChartProyek
                xaxis={{
                  categories:
                    listDashboardCashFlow &&
                    listDashboardCashFlow.labelChartCashFlow,
                }}
                series={
                  listDashboardCashFlow ? listDashboardCashFlow.series : []
                }
              />
            </div>
          </div>
          <div
            className="bg-theme slide-right shadow-m mt-n2 mb-2 py-2 px-2 mx-3"
            style={{
              animationDelay: Math.min(2 * 200, 2000) + 10 + "ms",
              borderRadius: 15,
              minHeight: 55,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="d-flex w-100">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    src="/images/items-place-holder.png"
                    width={60}
                    alt="photo items"
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <div className="align-self-center">
                  <h5 className="mb-0 line-height-xs pt-1 font-16">Total</h5>
                  <h4 className="mb-0 font-600 font-18 mt-1">
                    Rp.{" "}
                    {toThousand(
                      listDashboardProyek.totalPemasukan -
                        listDashboardProyek.totalPengeluaran
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-3 mx-3 gap-2">
            <div
              className="bg-theme slide-right shadow-m mb-2 py-2 px-2"
              style={{
                animationDelay: Math.min(2 * 200, 2000) + 10 + "ms",
                borderRadius: 15,
                minHeight: 55,
                display: "flex",
                alignItems: "center",
                width: "50%",
              }}
            >
              <div className="d-flex w-100">
                <div className="d-flex">
                  <div className="me-1">
                    <img
                      src="/images/items-place-holder.png"
                      width={50}
                      alt="photo items"
                      style={{ borderRadius: 10 }}
                    />
                  </div>
                  <div className="align-self-center">
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-12">
                      Pemasukan
                    </h5>
                    <h4 className="ps-1 mb-0 font-600 font-12 mt-1 text-hidden-1">
                      Rp. {toThousand(listDashboardProyek.totalPemasukan)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-theme slide-right shadow-m mb-2 py-2 px-2"
              style={{
                animationDelay: Math.min(2 * 200, 2000) + 10 + "ms",
                borderRadius: 15,
                minHeight: 55,
                display: "flex",
                alignItems: "center",
                width: "50%",
              }}
            >
              <div className="d-flex w-100">
                <div className="d-flex">
                  <div className="me-1">
                    <img
                      src="/images/items-place-holder.png"
                      width={50}
                      alt="photo items"
                      style={{ borderRadius: 10 }}
                    />
                  </div>
                  <div className="align-self-center">
                    <h5 className="ps-1 mb-0 line-height-xs pt-1 font-12">
                      Pengeluaran
                    </h5>
                    <h4 className="ps-1 mb-0 font-600 font-12 mt-1 text-hidden-1">
                      Rp. {toThousand(listDashboardProyek.totalPengeluaran)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Transaksi</h3>
            </div>
          </div>
          <div className="px-1">
            {listDashboardHistory.lastTransaction.length < 1 ? (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada transaksi
                </h5>
              </div>
            ) : (
              listDashboardHistory.lastTransaction
                .sort(
                  (a: { created_at: Date }, b: { created_at: Date }) =>
                    new Date(b.created_at).valueOf() -
                    new Date(a.created_at).valueOf()
                )
                .map((lastTransaction: any, lastTransactionKey: number) => {
                  return (
                    <div
                      key={lastTransactionKey}
                      className={`bg-theme slide-right mx-3 shadow-m mt-3 mb-3 py-1`}
                      style={{
                        borderRadius: 10,
                        animationDelay:
                          Math.min(lastTransactionKey * 200, 1000) + "ms",
                      }}
                    >
                      <div
                        className="d-flex px-2 pb-2 pt-2 pe-3"
                        style={{ alignItems: "center", height: "100%" }}
                      >
                        <div
                          className="ps-2 align-self-center"
                          style={{ width: "50%" }}
                        >
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-14 text-hidden-1">
                            {lastTransaction.name}
                          </h5>
                          <div className="d-flex flex-column">
                            <span className="ps-1 mb-0 font-400 opacity-70 font-12">
                              {lastTransaction.formatted_created_at}
                            </span>
                            <span className="ps-1 mb-0 font-400 opacity-70 font-12 mt-n1">
                              {lastTransaction.proyek}
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto">
                          <h6
                            className={`ps-1 mb-0 line-height-xs pt-1 font-14 text-hidden-2 ${
                              lastTransaction.type === "pengeluaran" &&
                              "text-danger"
                            }`}
                          >
                            {lastTransaction.type === "pengeluaran" && "-"} Rp.{" "}
                            {toThousand(lastTransaction.jumlah.toString())}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
          <div
            className="d-flex py-2 px-4 slide-top"
            style={{ alignItems: "center" }}
          >
            <div>
              <h3 className="mb-0">Riwayat Gudang</h3>
            </div>
          </div>
          <div className="px-1 mb-4">
            {listDashboardHistory.warehouse.length < 1 ? (
              <div
                className="bg-theme slide-right mx-3 shadow-m mt-2 mb-2 py-4 px-3"
                style={{
                  borderRadius: 10,
                  animationDelay: Math.min(1 * 200, 1000) + "ms",
                }}
              >
                <h5 className="ps-1 mb-0 line-height-xs pt-1 font-16 text-hidden-1">
                  Belum ada data
                </h5>
              </div>
            ) : (
              listDashboardHistory.warehouse
                .sort(
                  (a: { created_at: string }, b: { created_at: string }) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((warehouse: any, warehouseKey: number) => {
                  return (
                    <div
                      className={`bg-theme slide-right mx-3 shadow-m mt-3 mb-3 py-1 ${
                        warehouseKey !== 0 && "mt-3"
                      } `}
                      key={warehouseKey}
                      style={{
                        animationDelay:
                          Math.min(warehouseKey * 200, 3000) + 10 + "ms",
                        borderRadius: 10,
                        minHeight: 55,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="d-flex w-100">
                        <div className="ps-2 align-self-center">
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15">
                            {moment(warehouse.created_at)
                              .tz("Asia/Jakarta")
                              .format("LL")}
                          </h5>
                          <h4 className="ps-1 mb-0 font-400 font-12 mt-1">
                            Reference : {warehouse.reference}
                          </h4>
                          <h4 className="ps-1 mb-0 font-400 font-12 mt-n1">
                            Proyek : {warehouse.proyek}
                          </h4>
                          <h4
                            className="ps-1 mb-0 font-400 font-12 mt-n1"
                            style={{ textTransform: "capitalize" }}
                          >
                            Keterangan : {warehouse.name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </>
      )}
    </Layout>
  );
};

export default DashboardSuperAdmin;
