/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Layout from "../../assets/components/layout";
import { getLocation, useProfile } from "../../const";
import { Field, Form, Formik } from "formik";
import Preloader from "../../assets/components/preloader";
import moment from "moment";
import {
  handleUpdatePassword,
  updateProfile,
  updateProfileImage,
  updateProfileSchema,
} from "./interface";
import { useQueryClient } from "@tanstack/react-query";
import { createPortal } from "react-dom";

const ProfilePage = () => {
  const queryClient = useQueryClient();
  const { data } = useProfile();
  const [profile, setProfile] = useState<any | null>({});
  const [address, setAddress] = useState<any | null>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isChangeProfile, setIsChangeProfile] = useState<boolean>(false);
  const [logActivity, setLogActivity] = useState<any[]>([]);
  const [dataPassword, setDataPassword] = useState({
    oldPassword: "",
    newPassword: "",
    retypeNewPassword: "",
  });

  const fileManager = useRef<HTMLInputElement>(null);

  const getCityFromCoordinates = async (
    lat: string | null | undefined,
    lng: string | null | undefined
  ) => {
    const url =
      "https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" +
      lat +
      "&lon=" +
      lng;

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setAddress(data.address);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsLoading(false);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      updateProfileImage(file, profile.uuid, isChangeProfile, queryClient);
    }
  };

  const handleOpenImage = (isProfileImage: boolean = false) => {
    if (fileManager.current) {
      setIsChangeProfile(isProfileImage);
      fileManager.current.value = "";
      fileManager.current.click();
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { latitude, longitude } = (await getLocation()) as {
        latitude: string;
        longitude: string;
      };

      if (data) {
        setProfile(data.user);
        setLogActivity(data.log_login);
        getCityFromCoordinates(latitude, longitude);
      }
    };
    getData();
  }, [data]);
  return (
    <Layout
      headerTitle="Profile"
      urlBack={
        profile.roles === "super-admin" ||
        profile.roles === "penaggung-jawab-proyek"
          ? true
          : false
      }
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="card card-style">
            <div className="position-relative d-flex w-100">
              <img
                src={`${
                  profile.profile_background
                    ? profile.profile_background
                    : "/images/cover-default.jpg"
                }`}
                alt="cover image"
                className="cover-img"
                width="100%"
              />
              <button
                type="button"
                className="btn btn-cover-image"
                onClick={() => handleOpenImage()}
              >
                <i className="bi bi-camera font-14"></i>
              </button>
            </div>
            <div className="position-relative d-flex w-100">
              <div className="user-profile-image mx-auto">
                <img
                  src={profile.profile}
                  width={120}
                  height={120}
                  alt="profile"
                  className="preload-img rounded-circle shadow-xl entered loaded"
                />
              </div>
              <button
                type="button"
                className="btn btn-profile-image"
                onClick={() => handleOpenImage(true)}
              >
                <i className="bi bi-camera font-14"></i>
              </button>
            </div>
            <div className="content text-center mt-2">
              <h3 className="font-700">{profile.jabatan_name}</h3>
              <h1 className="font-700">{profile.name}</h1>
              <div
                className="d-flex w-100"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <h5 className="font-700 opacity-30 color-theme mb-0">
                  {address.village}
                </h5>
                <div
                  style={{
                    margin: "0 10px",
                    borderRight: "2px solid rgb(15 17 23 / 23%)",
                    height: 16,
                  }}
                />
                <h5 className="font-700 opacity-30 color-theme mb-0">
                  {address.city}, {address.state}
                </h5>
              </div>
            </div>
          </div>
          <div className="card card-style">
            <div className="content">
              <h5 className="font-700 mb-4">Information</h5>
              <Formik
                initialValues={profile}
                validationSchema={updateProfileSchema}
                onSubmit={(values) => {
                  updateProfile(values, queryClient);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-custom form-label mb-3">
                      <Field
                        type="text"
                        name="name"
                        className="form-control rounded-xs"
                        placeholder="Nama"
                      />
                      <label className="color-theme form-label-always-active font-10 opacity-50">
                        Nama
                      </label>
                      <span>(required)</span>
                      {errors.name && touched.name && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.name as string}
                        </div>
                      )}
                    </div>
                    <div className="form-custom form-label mb-3">
                      <Field
                        type="text"
                        name="username"
                        className="form-control rounded-xs"
                        placeholder="Username"
                      />
                      <label className="color-theme form-label-always-active font-10 opacity-50">
                        Username
                      </label>
                      <span>(required)</span>
                      {errors.username && touched.username && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.username as string}
                        </div>
                      )}
                    </div>
                    <div className="form-custom form-label mb-3">
                      <Field
                        type="email"
                        name="email"
                        className="form-control rounded-xs"
                        placeholder="Email"
                      />
                      <label className="color-theme form-label-always-active font-10 opacity-50">
                        Email
                      </label>
                      <span>(required)</span>
                      {errors.email && touched.email && (
                        <div
                          style={{ display: "block" }}
                          className="invalid-feedback"
                        >
                          {errors.email as string}
                        </div>
                      )}
                    </div>
                    <button
                      style={{ width: "100%" }}
                      type="submit"
                      className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                    >
                      Simpan Data
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="card card-style">
            <div className="content">
              <h5 className="font-700 mb-2">Log Activity</h5>
              {logActivity.map((log, logKey) => {
                return (
                  <div
                    className="alert border-blue-dark alert-dismissible color-blue-dark rounded-s fade show slide-top"
                    key={logKey}
                    style={{
                      borderRadius: 10,
                      //   minHeight: 83,
                      animationDelay: Math.min(logKey * 200, 1000) + "ms",
                    }}
                  >
                    <div className="d-flex">
                      <div className="align-self-center d-flex">
                        <i className="bi bi-device-ssd font-34"></i>
                      </div>
                      <div
                        className="align-self-center d-flex"
                        style={{ alignItems: "center", height: "100%" }}
                      >
                        <div
                          className="ps-2 align-self-center"
                          style={{ width: "100%" }}
                        >
                          <h5 className="ps-1 mb-0 line-height-xs pt-1 font-15 text-hidden-1">
                            {log.agents} ({log.device})
                          </h5>
                          <span className="ps-1 mb-0 line-height-xs pt-1 font-13 text-hidden-1 color-black">
                            {log.api}
                          </span>
                          <span className="ps-1 mb-0 line-height-xs pt-1 font-13 text-hidden-1 color-black">
                            {moment().locale("id") &&
                              moment(new Date(log.updated_at)).format("llll")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="card card-style">
            <div className="content">
              <h5 className="font-700 mb-4">Update Password</h5>
              <div className="form-custom form-label mb-4">
                <input
                  type="password"
                  name="oldPassword"
                  className="form-control rounded-xs"
                  placeholder="Password Lama"
                  value={dataPassword.oldPassword}
                  onChange={(event) => {
                    setDataPassword((prev) => ({
                      ...prev,
                      oldPassword: event.target.value,
                    }));
                  }}
                />
                <label className="color-theme form-label-always-active font-10 opacity-50">
                  Password Lama
                </label>
                <span>(required)</span>
              </div>
              <div className="form-custom form-label mb-4">
                <input
                  type="password"
                  name="newPassword"
                  className="form-control rounded-xs"
                  placeholder="Password Baru"
                  value={dataPassword.newPassword}
                  onChange={(event) => {
                    setDataPassword((prev) => ({
                      ...prev,
                      newPassword: event.target.value,
                    }));
                  }}
                />
                <label className="color-theme form-label-always-active font-10 opacity-50">
                  Password Baru
                </label>
                <span>(required)</span>
              </div>
              <div className="form-custom form-label mb-4">
                <input
                  type="password"
                  name="retypeNewPassword"
                  className="form-control rounded-xs"
                  placeholder="Ulangi Password Baru"
                  value={dataPassword.retypeNewPassword}
                  onChange={(event) => {
                    setDataPassword((prev) => ({
                      ...prev,
                      retypeNewPassword: event.target.value,
                    }));
                  }}
                />
                <label className="color-theme form-label-always-active font-10 opacity-50">
                  Ulangi Password Baru
                </label>
                <span>(required)</span>
              </div>
              <button
                disabled={
                  dataPassword.oldPassword === "" ||
                  dataPassword.newPassword === "" ||
                  dataPassword.retypeNewPassword === ""
                    ? true
                    : false
                }
                style={{ width: "100%" }}
                type="button"
                className="btn-full btn gradient-green shadow-bg shadow-bg-m"
                onClick={() => handleUpdatePassword(dataPassword, profile.uuid)}
              >
                Simpan Data
              </button>
            </div>
          </div>
        </>
      )}
      {createPortal(
        <input
          ref={fileManager}
          type="file"
          onChange={handleFileChange}
          style={{
            width: 1,
            height: 1,
            position: "fixed",
            bottom: "calc(100dvh + 100px)",
          }}
          accept="image/*"
        />,
        document.body
      )}
    </Layout>
  );
};

export default ProfilePage;
