import { createBrowserRouter } from "react-router-dom";
import DashboardPage from "./pages/dashboard";
import PageUser from "./pages/users";
import PagePosition from "./pages/position";
import AbsensiPage from "./pages/absen";
import SettingAbsensiPage from "./pages/settingAbsensi";
import PageSubmission from "./pages/submission";
import ManagementProyek from "./pages/proyek/management";
import KaryawanProyekPage from "./pages/proyek/karyawan";
import ManagementProyekDetail from "./pages/proyek/management/details";
import LaporanProyek from "./pages/proyek/laporan";
import ViewReport from "./pages/viewReport";
import ProfilePage from "./pages/profile";
import LoginPage from "./pages/login";
import PageSubmissionAdmin from "./pages/submission/admin";
import PageInfo from "./pages/info/info";
import PageHistoryUser from "./pages/historyUser";
import PageSettingProyek from "./pages/settingProyek";
import AbsenPhoto from "./pages/absenPhoto";
import ListAbsensiPage from "./pages/absen/listAbsensi";
import PageDetailInventaris from "./pages/proyek/management/details/pages/inventaris/details";
import PageUnitsWarehouse from "./pages/proyek/warehouse/units";
import PageLaporanSober from "./pages/report";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardPage />,
  },
  {
    path: "/users",
    element: <PageUser />,
  },
  {
    path: "/position",
    element: <PagePosition />,
  },
  {
    path: "/absen",
    element: <AbsensiPage />,
  },
  {
    path: "/list-absen",
    element: <ListAbsensiPage />,
  },
  {
    path: "/submission",
    element: <PageSubmission />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/submission-report",
    element: <PageSubmissionAdmin />,
  },
  {
    path: "/setting-absen",
    element: <SettingAbsensiPage />,
  },
  {
    path: "/setting-proyek",
    element: <PageSettingProyek />,
  },
  {
    path: "/proyek-management",
    element: <ManagementProyek />,
  },
  {
    path: "/proyek-management-details/:uuid",
    element: <ManagementProyekDetail />,
  },
  {
    path: "/proyek-karyawan",
    element: <KaryawanProyekPage />,
  },
  {
    path: "/proyek-jabatan",
    element: <PagePosition isProyek />,
  },
  {
    path: "/proyek-laporan",
    element: <LaporanProyek />,
  },
  {
    path: "/laporan/view",
    element: <ViewReport />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/history-user",
    element: <PageHistoryUser />,
  },
  {
    path: "/info",
    element: <PageInfo />,
  },
  {
    path: "/absen-photo/:uuid",
    element: <AbsenPhoto />,
  },
  {
    path: "/inventaris/:uuid",
    element: <PageDetailInventaris />,
  },
  {
    path: "/units",
    element: <PageUnitsWarehouse />,
  },
  {
    path: "/laporan",
    element: <PageLaporanSober />,
  },
]);

export default router;
